import React, { Component } from 'react';
// import '../assets/css/bootstrap.min.css';
import ScriptTag from 'react-script-tag';
import Script from 'react-load-script'
import { BASE_URLs } from '../Constants/BaseURL';

export default class Howitworks extends Component {
  constructor(props) {

    super(props);

    this.state = {

      withyourMobilePhoneoremail: "",
      password: "",
      l_name: "",
      formErrors: {},
      foo: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      privacypolicy: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      Register: [],
      usernames: [],
      withyourcredentials: [],
      forgotpassword: [],
      signin: [],
      donthaveacc: [],
      signup: [],
      entercaptchvalue: [],
      enter: [],
      langID: [],
      lID: [],
      dlangID: [],
      EnterUsername: [],
      EnterPassword: [],
      UsernameandPasswordisincorrectTryAgain: [],
      Captchisnotmatch: [],
      UpdateProfile: [],
      YourImage: [],
      DateofBirth: [],
      DateofAnniversary: [],
      Place: [],
      CompleteProfile: [],
      CreateYourCause: [],
      or: [],
      FundraisingNeed: [],
      foreg: [],
      Education: [],
      SchoolHighSchoolorCollege: [],
      NoteBooksTextBooksMedicaletc: [],

      Describe: [],
      Title: [],
      Description: [],
      Purpose: [],
      Amount: [],
      UploadSupportingDocuments: [],
      DateRequiredBy: [],
      WhoIsItRequiredFor: [],
      TagFriends: [],
      ByName: [],


      TagYourGroups: [],
      TaxBenefits: [],
      Mentionifyourcausecan: [],


      ShareYourCause: [],
      FromHomePage: [],
      FromMyJourneyPage: [],

      DonatetoaCause: [],
      HomePage: [],
      SelectaCausetoSupport: [],

      SelectDonateaSmile: [],
      EntertheofYourSupport: [],
      WriteMessagetotheCreator: [],
      SubmitYourSupport: [],
      YouandtheCreatorofthecausewillreceive: [],


      Edit: [],
      DeleteYourCause: [],
      Youcanonlyedit: [],
      deletecausesthatdonothave: [],
      MyJourneyPage: [],


      SelectEditinYourCause: [],
      EditTaxBenefits: [],
      SelectDelete: [],
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
    };
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);


  }
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }


  Footer() {




  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

 
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
       
      });
  }



  DefaultMenu() {
   
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
   
    let MID;
    //         if(sessionStorage.getItem('transliteration') !== null){
    //           MID= sessionStorage.getItem('transliteration');
    //         }
    // else
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
   


    let datatrans = MID;

  

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem('translate');
   

    let GIDS = JSON.parse(localStorage.getItem('this.state.googtrans'));

    let datatransID = MID;
    if (datatrans == "kn") {

      datatransID = "Kannada";
    }
    else
      if (datatrans == "ta") {
        datatransID = "Tamil";
      }
      else
        if (datatrans == "te") {
          datatransID = "Telugu";
        }
        else
          if (datatrans == "hi") {
            datatransID = "Hindi";
          }
          else
            if (datatrans == MID) {
              datatransID = MID;
            }

    let LnID = MID;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
          if (LnID == "Spanish") {
            LnID = "es";
          }
          else
            if (LnID == MID) {
              LnID = MID;
            }

    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            lID: LID

          });


        });

    }
    if (MID != null) {
   

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Register~with your Mobile Phone or email~with your credentials~Update Profile~Your Image~Date of Birth&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Register = typeof items[4] !== "undefined" ? items[4] : null;
          var withyourMobilePhoneoremail = typeof items[5] !== "undefined" ? items[5] : null;
          var withyourcredentials = typeof items[6] !== "undefined" ? items[6] : null;
          var UpdateProfile = typeof items[7] !== "undefined" ? items[7] : null;
          var YourImage = typeof items[8] !== "undefined" ? items[8] : null;
          var DateofBirth = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,
            Register: Register,
            withyourMobilePhoneoremail: withyourMobilePhoneoremail,
            withyourcredentials: withyourcredentials,
            UpdateProfile: UpdateProfile,
            YourImage: YourImage,
            DateofBirth: DateofBirth,

            langID: LangID,
            lID: LID

          });

        });

    }
    if (MID != null) {
     

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Date of Anniversary~Place~Complete Profile~Create Your Cause~or~Fundraising Need~for eg~Education~School, High School or College~Note Books, Text Books, Medical, etc&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
         
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var DateofAnniversary = typeof items[0] !== "undefined" ? items[0] : null;
          var Place = typeof items[1] !== "undefined" ? items[1] : null;
          var CompleteProfile = typeof items[2] !== "undefined" ? items[2] : null;


          var CreateYourCause = typeof items[3] !== "undefined" ? items[3] : null;
          var or = typeof items[4] !== "undefined" ? items[4] : null;
          var FundraisingNeed = typeof items[5] !== "undefined" ? items[5] : null;

          var foreg = typeof items[6] !== "undefined" ? items[6] : null;
          var Education = typeof items[7] !== "undefined" ? items[7] : null;
          var SchoolHighSchoolorCollege = typeof items[8] !== "undefined" ? items[8] : null;
          var NoteBooksTextBooksMedicaletc = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            DateofAnniversary: DateofAnniversary,
            Place: Place,
            CompleteProfile: CompleteProfile,
            CreateYourCause: CreateYourCause,
            or: or,
            FundraisingNeed: FundraisingNeed,
            foreg: foreg,
            Education: Education,
            SchoolHighSchoolorCollege: SchoolHighSchoolorCollege,
            NoteBooksTextBooksMedicaletc: NoteBooksTextBooksMedicaletc
          });


        });

    }

    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Describe~Title~Description~Purpose~Amount~Upload Supporting Documents~Date Required By~Who Is It Required For~Tag Friends~By Name&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
         
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;





          var Describe = typeof items[0] !== "undefined" ? items[0] : null;
          var Title = typeof items[1] !== "undefined" ? items[1] : null;
          var Description = typeof items[2] !== "undefined" ? items[2] : null;


          var Purpose = typeof items[3] !== "undefined" ? items[3] : null;
          var Amount = typeof items[4] !== "undefined" ? items[4] : null;
          var UploadSupportingDocuments = typeof items[5] !== "undefined" ? items[5] : null;

          var DateRequiredBy = typeof items[6] !== "undefined" ? items[6] : null;
          var WhoIsItRequiredFor = typeof items[7] !== "undefined" ? items[7] : null;
          var TagFriends = typeof items[8] !== "undefined" ? items[8] : null;
          var ByName = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            Describe: Describe,
            Title: Title,
            Description: Description,
            Purpose: Purpose,
            Amount: Amount,
            UploadSupportingDocuments: UploadSupportingDocuments,
            DateRequiredBy: DateRequiredBy,
            WhoIsItRequiredFor: WhoIsItRequiredFor,
            TagFriends: TagFriends,
            ByName: ByName,

          });


        });

    }

    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Place~Tag Your Groups~Tax Benefits~Mention if your cause can provide Income tax benefits (80G) to the supporters~Share Your Cause~From Home Page~From My Journey Page~Donate to a Cause~Home Page~Select a Cause to Support&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
         
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;



          var Place = typeof items[0] !== "undefined" ? items[0] : null;
          var TagYourGroups = typeof items[1] !== "undefined" ? items[1] : null;
          var TaxBenefits = typeof items[2] !== "undefined" ? items[2] : null;


          var Mentionifyourcausecan = typeof items[3] !== "undefined" ? items[3] : null;
          var ShareYourCause = typeof items[4] !== "undefined" ? items[4] : null;
          var FromHomePage = typeof items[5] !== "undefined" ? items[5] : null;

          var FromMyJourneyPage = typeof items[6] !== "undefined" ? items[6] : null;
          var DonatetoaCause = typeof items[7] !== "undefined" ? items[7] : null;
          var HomePage = typeof items[8] !== "undefined" ? items[8] : null;
          var SelectaCausetoSupport = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            Place: Place,
            TagYourGroups: TagYourGroups,
            TaxBenefits: TaxBenefits,
            Mentionifyourcausecan: Mentionifyourcausecan,


            ShareYourCause: ShareYourCause,
            FromHomePage: FromHomePage,
            FromMyJourneyPage: FromMyJourneyPage,
            DonatetoaCause: DonatetoaCause,
            HomePage: HomePage,
            SelectaCausetoSupport: SelectaCausetoSupport,
          });


        });

    }
    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Select Donate a Smile~Enter the Percentage of Your Support to the Cause~Write Message to the Creator of the Cause~Submit Your Support~You and the Creator of the cause will receive a text/email notification~Edit~Delete Your Cause~You can only edit~delete causes that do not have any pledged support, likes or comments~My Journey Page&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
         
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;





          var SelectDonateaSmile = typeof items[0] !== "undefined" ? items[0] : null;
          var EntertheofYourSupport = typeof items[1] !== "undefined" ? items[1] : null;
          var WriteMessagetotheCreator = typeof items[2] !== "undefined" ? items[2] : null;
          var SubmitYourSupport = typeof items[3] !== "undefined" ? items[3] : null;
          var YouandtheCreatorofthecausewillreceive = typeof items[4] !== "undefined" ? items[4] : null;

          var Edit = typeof items[5] !== "undefined" ? items[5] : null;
          var DeleteYourCause = typeof items[6] !== "undefined" ? items[6] : null;
          var Youcanonlyedit = typeof items[7] !== "undefined" ? items[7] : null;
          var deletecausesthatdonothave = typeof items[8] !== "undefined" ? items[8] : null;
          var MyJourneyPage = typeof items[9] !== "undefined" ? items[9] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            SelectDonateaSmile: SelectDonateaSmile,
            EntertheofYourSupport: EntertheofYourSupport,
            WriteMessagetotheCreator: WriteMessagetotheCreator,
            SubmitYourSupport: SubmitYourSupport,
            YouandtheCreatorofthecausewillreceive: YouandtheCreatorofthecausewillreceive,
            Edit: Edit,
            DeleteYourCause: DeleteYourCause,
            Youcanonlyedit: Youcanonlyedit,
            deletecausesthatdonothave: deletecausesthatdonothave,
            MyJourneyPage: MyJourneyPage

          });


        });

    }

    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Select Edit in Your Cause~Edit Tax Benefits~Select Delete&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
       
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;





          var SelectEditinYourCause = typeof items[0] !== "undefined" ? items[0] : null;
          var EditTaxBenefits = typeof items[1] !== "undefined" ? items[1] : null;
          var SelectDelete = typeof items[2] !== "undefined" ? items[2] : null;





          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            SelectEditinYourCause: SelectEditinYourCause,
            EditTaxBenefits: EditTaxBenefits,
            SelectDelete: SelectDelete,

          });


        });

    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
  
    let MID = event.target.value;
    let LnID = event.target.value;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
          if (LnID == "Spanish") {
            LnID = "es";
          }
          else
            if (LnID == event.target.value) {
              LnID = event.target.value;
            }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
      
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            lID: LID

          });


        });

    }

    if (MID != null) {
  

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Register~with your Mobile Phone or email~with your credentials~Update Profile~Your Image~Date of Birth&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
        
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Register = typeof items[4] !== "undefined" ? items[4] : null;
          var withyourMobilePhoneoremail = typeof items[5] !== "undefined" ? items[5] : null;
          var withyourcredentials = typeof items[6] !== "undefined" ? items[6] : null;
          var UpdateProfile = typeof items[7] !== "undefined" ? items[7] : null;
          var YourImage = typeof items[8] !== "undefined" ? items[8] : null;
          var DateofBirth = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,
            Register: Register,
            withyourMobilePhoneoremail: withyourMobilePhoneoremail,
            withyourcredentials: withyourcredentials,
            UpdateProfile: UpdateProfile,
            YourImage: YourImage,
            DateofBirth: DateofBirth,

            langID: LangID,
            lID: LID

          });

        });

    }
    if (MID != null) {
   

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Date of Anniversary~Place~Complete Profile~Create Your Cause~or~Fundraising Need~for eg~Education~School, High School or College~Note Books, Text Books, Medical, etc&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
  
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var DateofAnniversary = typeof items[0] !== "undefined" ? items[0] : null;
          var Place = typeof items[1] !== "undefined" ? items[1] : null;
          var CompleteProfile = typeof items[2] !== "undefined" ? items[2] : null;


          var CreateYourCause = typeof items[3] !== "undefined" ? items[3] : null;
          var or = typeof items[4] !== "undefined" ? items[4] : null;
          var FundraisingNeed = typeof items[5] !== "undefined" ? items[5] : null;

          var foreg = typeof items[6] !== "undefined" ? items[6] : null;
          var Education = typeof items[7] !== "undefined" ? items[7] : null;
          var SchoolHighSchoolorCollege = typeof items[8] !== "undefined" ? items[8] : null;
          var NoteBooksTextBooksMedicaletc = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            DateofAnniversary: DateofAnniversary,
            Place: Place,
            CompleteProfile: CompleteProfile,
            CreateYourCause: CreateYourCause,
            or: or,
            FundraisingNeed: FundraisingNeed,
            foreg: foreg,
            Education: Education,
            SchoolHighSchoolorCollege: SchoolHighSchoolorCollege,
            NoteBooksTextBooksMedicaletc: NoteBooksTextBooksMedicaletc
          });


        });

    }


    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Describe~Title~Description~Purpose~Amount~Upload Supporting Documents~Date Required By~Who Is It Required For~Tag Friends~By Name&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
         
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;





          var Describe = typeof items[0] !== "undefined" ? items[0] : null;
          var Title = typeof items[1] !== "undefined" ? items[1] : null;
          var Description = typeof items[2] !== "undefined" ? items[2] : null;


          var Purpose = typeof items[3] !== "undefined" ? items[3] : null;
          var Amount = typeof items[4] !== "undefined" ? items[4] : null;
          var UploadSupportingDocuments = typeof items[5] !== "undefined" ? items[5] : null;

          var DateRequiredBy = typeof items[6] !== "undefined" ? items[6] : null;
          var WhoIsItRequiredFor = typeof items[7] !== "undefined" ? items[7] : null;
          var TagFriends = typeof items[8] !== "undefined" ? items[8] : null;
          var ByName = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            Describe: Describe,
            Title: Title,
            Description: Description,
            Purpose: Purpose,
            Amount: Amount,
            UploadSupportingDocuments: UploadSupportingDocuments,
            DateRequiredBy: DateRequiredBy,
            WhoIsItRequiredFor: WhoIsItRequiredFor,
            TagFriends: TagFriends,
            ByName: ByName,

          });


        });

    }

    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Place~Tag Your Groups~Tax Benefits~Mention if your cause can provide Income tax benefits (80G) to the supporters~Share Your Cause~From Home Page~From My Journey Page~Donate to a Cause~Home Page~Select a Cause to Support&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
         
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;



          var Place = typeof items[0] !== "undefined" ? items[0] : null;
          var TagYourGroups = typeof items[1] !== "undefined" ? items[1] : null;
          var TaxBenefits = typeof items[2] !== "undefined" ? items[2] : null;


          var Mentionifyourcausecan = typeof items[3] !== "undefined" ? items[3] : null;
          var ShareYourCause = typeof items[4] !== "undefined" ? items[4] : null;
          var FromHomePage = typeof items[5] !== "undefined" ? items[5] : null;

          var FromMyJourneyPage = typeof items[6] !== "undefined" ? items[6] : null;
          var DonatetoaCause = typeof items[7] !== "undefined" ? items[7] : null;
          var HomePage = typeof items[8] !== "undefined" ? items[8] : null;
          var SelectaCausetoSupport = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            Place: Place,
            TagYourGroups: TagYourGroups,
            TaxBenefits: TaxBenefits,
            Mentionifyourcausecan: Mentionifyourcausecan,


            ShareYourCause: ShareYourCause,
            FromHomePage: FromHomePage,
            FromMyJourneyPage: FromMyJourneyPage,
            DonatetoaCause: DonatetoaCause,
            HomePage: HomePage,
            SelectaCausetoSupport: SelectaCausetoSupport,
          });


        });

    }

    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Select Donate a Smile~Enter the Percentage of Your Support to the Cause~Write Message to the Creator of the Cause~Submit Your Support~You and the Creator of the cause will receive a text/email notification~Edit~Delete Your Cause~You can only edit~delete causes that do not have any pledged support, likes or comments~My Journey Page&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
       
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;





          var SelectDonateaSmile = typeof items[0] !== "undefined" ? items[0] : null;
          var EntertheofYourSupport = typeof items[1] !== "undefined" ? items[1] : null;
          var WriteMessagetotheCreator = typeof items[2] !== "undefined" ? items[2] : null;
          var SubmitYourSupport = typeof items[3] !== "undefined" ? items[3] : null;
          var YouandtheCreatorofthecausewillreceive = typeof items[4] !== "undefined" ? items[4] : null;

          var Edit = typeof items[5] !== "undefined" ? items[5] : null;
          var DeleteYourCause = typeof items[6] !== "undefined" ? items[6] : null;
          var Youcanonlyedit = typeof items[7] !== "undefined" ? items[7] : null;
          var deletecausesthatdonothave = typeof items[8] !== "undefined" ? items[8] : null;
          var MyJourneyPage = typeof items[9] !== "undefined" ? items[9] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            SelectDonateaSmile: SelectDonateaSmile,
            EntertheofYourSupport: EntertheofYourSupport,
            WriteMessagetotheCreator: WriteMessagetotheCreator,
            SubmitYourSupport: SubmitYourSupport,
            YouandtheCreatorofthecausewillreceive: YouandtheCreatorofthecausewillreceive,
            Edit: Edit,
            DeleteYourCause: DeleteYourCause,
            Youcanonlyedit: Youcanonlyedit,
            deletecausesthatdonothave: deletecausesthatdonothave,
            MyJourneyPage: MyJourneyPage

          });


        });

    }

    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Select Edit in Your Cause~Edit Tax Benefits~Select Delete&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
   
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;





          var SelectEditinYourCause = typeof items[0] !== "undefined" ? items[0] : null;
          var EditTaxBenefits = typeof items[1] !== "undefined" ? items[1] : null;
          var SelectDelete = typeof items[2] !== "undefined" ? items[2] : null;





          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            SelectEditinYourCause: SelectEditinYourCause,
            EditTaxBenefits: EditTaxBenefits,
            SelectDelete: SelectDelete
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
       
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


       



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

        
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

        


          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
       

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }


          setCookie('googtrans', datatransIDs, 30);
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });
        });
    }
  }

  componentDidMount() {

    window.addEventListener('load', this.Footer);
    this.Footer();

    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "Step by Step Guide to Paropakari."
  };

  componentWillUnmount() {


    window.removeEventListener('load', this.Footer);
    this.Footer();

    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }


  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
  render() {
    let { foo } = this.state;
    const { username, password, l_name } = this.state;
    const currentYear = new Date().getFullYear();
    const { newArray, howitworks, beaparopakari, whatwedo, blog, login, indiaexpensless, ourstory, oppertunitybringsmile,
      contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, privacypolicy, whatwedodesc,
      langID, Register, withyourMobilePhoneoremail, withyourcredentials, UpdateProfile, signin, donthaveacc, signup, entercaptchvalue,
      CompleteProfile, EnterPassword, dlangID, lID, CreateYourCause, copyright_new, paropakari_new,
      or, ShareYourCause, FromHomePage, FromMyJourneyPage,
      FundraisingNeed, SelectEditinYourCause,
      EditTaxBenefits,
      SelectDelete,
      Edit,
      DeleteYourCause,
      Youcanonlyedit,
      deletecausesthatdonothave,
      MyJourneyPage,
      foreg, DonatetoaCause,
      HomePage, SelectDonateaSmile,
      EntertheofYourSupport,
      WriteMessagetotheCreator,
      SubmitYourSupport,
      YouandtheCreatorofthecausewillreceive,
      SelectaCausetoSupport,
      Education, Describe, Title, Description, Purpose, Amount, UploadSupportingDocuments, DateRequiredBy, WhoIsItRequiredFor, TagFriends, ByName,
      SchoolHighSchoolorCollege,
      NoteBooksTextBooksMedicaletc,
      TagYourGroups,
      TaxBenefits,
      Mentionifyourcausecan,
      EnterUsername, UsernameandPasswordisincorrectTryAgain, Captchisnotmatch, YourImage, DateofBirth, DateofAnniversary, Place } = this.state;
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd notranslate" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          {/* <link href="./assets/css/responsive.css" rel="stylesheet"/> */}

          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />


          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />

          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English" style={{ fontSize: '12px' }}>Languages</option>
            <option value="English">English</option>
            <option value="Hindi">Hindi</option>
            <option value="Kannada">Kannada</option>
            <option value="Telugu">Telugu</option>
            <option value="Tamil">Tamil</option>
             <option value="Spanish">Spanish</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;



          <div className="mag notranslate">
            <div class="pro-nav">
              <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
              <div class="pro-nav-header">
                <div class="pro-nav-title notranslate">
                  <a className="navbar-brand" href="#/Index1">
                    <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                    </h1>
                  </a>
                </div>
              </div>

              <div class="pro-nav-btn">
                <label for="pro-nav-check">
                  <div className="pro-naav">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </label>
              </div>

              {langID == "English" ? <>
                <div class="pro-nav-links pro-side">
                  <p class='pro-center'>
                    <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                  </p>
                  <div className="parop-boxx parop-boxx-top">
                    <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                    <a href="#/Howitworks" className="parop-menu text-parop-menu">{howitworks.result}</a>
                    <a href="#/Blog" className="parop-menu">{blog.result}</a>
                    <a href="#/Login" className="parop-menu">{login.result}</a>
                  </div>
                </div>
                </> : langID == "Spanish" ? <>
                <div class="pro-nav-links pro-side">
                  <p class='pro-center'>
                    <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                  </p>
                  <div className="parop-boxx parop-boxx-top">
                    <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                    <a href="#/Howitworks" className="parop-menu text-parop-menu">{howitworks.result}</a>
                    <a href="#/Blog" className="parop-menu">{blog.result}</a>
                    <a href="#/Login" className="parop-menu">{login.result}</a>
                  </div>
                </div>
              </> : langID == "Hindi" ? <>
                <div class="pro-nav-links pro-side">
                  <p class='pro-center'>
                    <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                  </p>
                  <div className="parop-boxx parop-boxx-top">
                    <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                    <a href="#/Howitworks" className="parop-hindi-menu text-parop-menu">{howitworks.result}</a>
                    <a href="#/Blog" className="parop-hindi-menu">{blog.result}</a>
                    <a href="#/Login" className="parop-hindi-menu">{login.result}</a>
                  </div>
                </div>
              </> : langID == "Kannada" ? <>
                <div class="pro-nav-links pro-side">
                  <p class='pro-center'>
                    <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                  </p>
                  <div className="parop-kannada-boxx parop-boxx-top">
                    <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                    <a href="#/Howitworks" className="parop-kannada-menu text-parop-menu">{howitworks.result}</a>
                    <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                    <a href="#/Login" className="parop-kannada-menu">{login.result}</a>
                  </div>
                </div>
              </> : langID == "Tamil" ? <>
                <div class="pro-nav-links pro-side">
                  <p class='pro-center'>
                    <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                  </p>
                  <div className="parop-tamil-boxx parop-boxx-top">
                    <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                    <a href="#/Howitworks" className="parop-tamil-menu text-parop-menu">{howitworks.result}</a>
                    <a href="#/Blog" className="parop-tamil-menu">{blog.result}</a>
                    <a href="#/Login" className="parop-tamil-menu">{login.result}</a>
                  </div>
                </div>
              </> : <>
                <div class="pro-nav-links pro-side">
                  <p class='pro-center'>
                    <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                  </p>
                  <div className="parop-boxx parop-boxx-top">
                    <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                    <a href="#/Howitworks" className="parop-telugu-menu text-parop-menu">{howitworks.result}</a>
                    <a href="#/Blog" className="parop-telugu-menu">{blog.result}</a>
                    <a href="#/Login" className="parop-telugu-menu">{login.result}</a>
                  </div>
                </div>
              </>}
            </div>
          </div>
          {/* <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <nav class="navbar navbar-expand-xxl bg-light">
            <div className="navbar  navbar-inverse" role="banner">
              <div className="container" id="containerid" >
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="sr-only">Toggle navigassstion</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>

                  <a className="navbar-brand" href="#/Index1">
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                    </h1>
                  </a>

                </div>


                

                <div className="collapse navbar-collapse">

                <ul class="nav navbar-nav navbar-right">

<li id="paro" className="active">

{(() => {if (langID == 'Tamil' ) {return (
<li id="paro" className="active tamilbtn"><a className="btn1 third" id="tamilbtn1"  href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'English' ) {return (
<li id="paro" className="active englishbtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontsize:'2vw', fontWeight: 'bold',padding:'13px',marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'Hindi' ) {return (
<li id="paro" className="active hindibtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>

{beaparopakari.result}
</a></li>
);
}
else {return(

<li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>

);}

})()}

</li>

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 


</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}

</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}

</a>

);}

})()} 
</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{blog.result}</a>

);}

})()} 


</li>         

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{login.result}</a>

);}

})()} 



</li>                    
</ul>

                </div>

              </div>
            </div>
            </nav>
          </header> */}




          <section id="blog" className={this.state.movedown == true ? "padding-bottom" : "padding-bottom home-Tiptop"} style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
            <div className="containerss" id="containerid">
              
                <div className="timeline-blog overflow">
                  <div className="timeline-date text-center">
                    <a className="btn btn-common uppercase">{howitworks.result}</a>
                  </div>

                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline" style={{ width: "100%", marginLeft: "4vh" }}>
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            {/* <img src="./assets/images/Register.png" /> */}

                            <iframe src="https://www.youtube.com/embed/7nLDz7VoT64" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">{login.result}/{Register.result}</a></h2>
                          <p >#{Register.result} <a >({withyourMobilePhoneoremail.result})</a></p>
                          <p >#{login.result} <a>({withyourcredentials.result})</a></p>
                          <p >#{UpdateProfile.result} <a >({YourImage.result}/{DateofBirth.result}/<br />{DateofAnniversary.result}/{Place.result})</a></p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline" style={{ width: "100%", marginLeft: "-4vh" }}>
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            {/* <img src="./assets/images/Profile.png" /> */}
                            <iframe src="https://www.youtube.com/embed/Whd20fsqYCA" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html#">{CompleteProfile.result}</a></h2>
                          <p >#{Register.result} <a >({withyourMobilePhoneoremail.result})</a></p>
                          <p >#{login.result} <a >({withyourcredentials.result})</a></p>
                          <p >#{UpdateProfile.result} <a >({YourImage.result}/{DateofBirth.result}/<br />{DateofAnniversary.result}/{Place.result})</a></p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="timeline-blog overflow">

                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline" style={{ width: "100%", marginLeft: "4vh" }}>
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            {/* <img src="./assets/images/EditCause.png" /> */}
                            <iframe src="https://www.youtube.com/embed/50smAR176Us" />
                          </div>
                        </div>
                        <div className="post-content overflow">

                          <h2 className="post-title bold"><a href="blogdetails.html#">{CreateYourCause.result}</a></h2>
                          <p>#{CreateYourCause.result} {or.result} {FundraisingNeed.result} <a >({foreg.result}: <br />{Education.result} ({SchoolHighSchoolorCollege.result}),<br /> {NoteBooksTextBooksMedicaletc.result})</a></p>
                          <p >#{Describe.result} <a >({Title.result}/{Description.result}/{Purpose.result}/{beneficiaries.result}/<br />{Amount.result}/{Place.result}/{UploadSupportingDocuments.result})</a></p>
                          <p>#{DateRequiredBy.result}/{WhoIsItRequiredFor.result}</p>
                          <p>#{TagFriends.result} <a >({ByName.result})</a></p>
                          <p >#{TagYourGroups.result} <a >({ByName.result})</a></p>
                          <p >#{TaxBenefits.result} <a >({Mentionifyourcausecan.result})</a></p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline" style={{ width: "100%", marginLeft: "-4vh" }}>
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            {/* <img src="./assets/images/HomePage.png" /> */}

                            <iframe src="https://www.youtube.com/embed/B-NIJeuOBxM" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">{ShareYourCause.result}</a></h2>
                          <p >#{FromHomePage.result} <a >(FB/Twitter/LinkedIn/<br />Whatsapp/Email,etc)</a></p>
                          <p >#{FromMyJourneyPage.result} <a >(FB/Twitter/LinkedIn/<br />Whatsapp/Email,etc)</a></p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-blog overflow">

                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline" style={{ width: "100%", marginLeft: "4vh" }}>
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            {/* <img src="./assets/images/MyJourney.png" /> */}
                            <iframe src="https://www.youtube.com/embed/-wx-PVKn1gA" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <div className="post-content overflow">
                            <h2 className="post-title bold"><a href="blogdetails.html">{DonatetoaCause.result}</a></h2>
                            <p >#{HomePage.result} </p>
                            <p>#{SelectaCausetoSupport.result} </p>
                            <p>#{SelectDonateaSmile.result}</p>
                            <p>#{EntertheofYourSupport.result}</p>
                            <p>#{WriteMessagetotheCreator.result}</p>
                            <p>#{SubmitYourSupport.result} <a><br />({YouandtheCreatorofthecausewillreceive.result})</a></p><br />
                            <a href="#" className="read-more"></a>
                            <div className="post-bottom overflow">
                              <span className="post-date pull-left"></span>
                              <span className="comments-number pull-right"><a href="#"></a></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline" style={{ width: "100%", marginLeft: "-4vh" }}>
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            {/* <img src="./assets/images/EditCause.png" /> */}

                            <iframe src="https://www.youtube.com/embed/3I-3E8i8JzI" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">{Edit.result}/{DeleteYourCause.result}</a></h2>

                          <p>#{SelectEditinYourCause.result}</p>
                          <p>#{Edit.result} <a style={{ fontSize: 15 }}>({Title.result}/{Description.result}/{Purpose.result}/{beneficiaries.result}/<br />{Amount.result}/{Place.result}/{UploadSupportingDocuments.result}/{DateRequiredBy.result}/{WhoIsItRequiredFor.result})</a></p>
                          <p>#{EditTaxBenefits.result} </p>
                          <p>#{DeleteYourCause.result} <a>({SelectDelete.result})</a></p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>



              
            </div>
          </section>
          <footer id="footer" style={{ color: 'white' }}>
            <br />
            <div className="container">
              <div className="copyright">

                <div className="row col-lg-12">

                  <div className="col-lg-9 mb-6">
                    {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                    <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                  </div>
                  <div className="col-lg-3 mb-3">

                    <div className="social-icons" style={{ color: 'white' }}>
                      <ul className="nav nav-pills">
                        <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                        {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                        <li><a target="_blank" href="https://www.youtube.com/channel/UCBDfPy1vgZRm5V_Z54P49FQ" ><i className="fa fa-youtube-play" /></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="containerss">

            </div>
          </footer>

          {/* <script type="text/javascript" src="js/jquery.js"></script> */}

          {/* <script type='text/javascript' src='./assets/js/jquery.js'/>
    <script type="text/javascript" src='./assets/js/bootstrap.min.js'></script>
    <script type="text/javascript" src="./assets/js/lightbox.min.js"></script>
    <script type="text/javascript" src='./assets/js/wow.min.js'></script>
    <script type="text/javascript" src='./assets/js/main.js'></script>    */}


        </div>
      </div>

    )
  }
}
