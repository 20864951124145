import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../ButtonComponent/PostButton.css'

class PostButton extends Component {
  render() {
    const { emp, langID, username, viewdetail, donateasmile } = this.props;

    return (
      <div className="button-container" id='butdiv'>
        {(() => {
          if (emp.confirmed >= "100" && emp.causefor_Id === 1) {
            return (
              <button className="flex-button achieved" id='buttsdiv'>
                <b>100% Achieved</b>
              </button>
            );
          } else if (emp.confirmed1 >= "100" && emp.causefor_Id === 0) {
            return (
              <button className="flex-button achieved" id='buttsdiv'>
                <b>100% Achieved</b>
              </button>
            );
          } else if (
            ["Tamil", "Kannada", "Hindi", "English", "Telugu", "Spanish"].includes(langID)
          ) {
            return (
              <>
                {emp.profile_name === username ? (
                  <button className="flex-button donate-btn" id='buttsdiv'>
                    <Link to={"/Confirm/" + emp.upload_id}>
                      <b style={{ color: "white" }}>{viewdetail.result}</b>
                    </Link>
                  </button>
                ) : (
                  <button className="flex-button donate-btn" id='buttsdiv'>
                    <Link to={"/ResponsePage/" + emp.upload_id}>
                      <b style={{ color: "white" }}>{donateasmile.result}</b>
                    </Link>
                  </button>
                )}
              </>
            );
          } else {
            return (
              <button className="flex-button donate-btn" id='buttsdiv'>
                {emp.profile_name === username ? (
                  <Link to={"/Confirm/" + emp.upload_id}>
                    <b style={{ color: "white" }}>{viewdetail.result}</b>
                  </Link>
                ) : (
                  <Link to={"/ResponsePage/" + emp.upload_id}>
                    <b style={{ color: "white" }}>{donateasmile.result}</b>
                  </Link>
                )}
              </button>
            );
          }
        })()}

        {(() => {
          if (emp.confirmed <= "99" && emp.causefor_Id === 1) {
            return (
              <button className="flex-button" id='buttsdiv'>
                <b>{emp.confirmed}% Achieved</b>
              </button>
            );
          } else if (emp.confirmed1 <= "99" && emp.causefor_Id === 0) {
            return (
              <button className="flex-button" id='buttsdiv'>
                <b>{emp.confirmed1}% Achieved</b>
              </button>
            );
          }
        })()}
      </div>
    );
  }
}

export default PostButton;
