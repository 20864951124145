import React, { Component } from "react";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../assets/FirstPage.css";
import SliderTest from "./SliderTest";
import Cookies from "js-cookie";
import { BASE_URLs } from '../Constants/BaseURL';
// const refreshPage = ()=>{

//   setTimeout(() => window.location.reload(), 3000);

// }
// const refreshPage = (e) => {
//   setTimeout(() => window.location.reload(true), 2000);
//   e.preventDefault();
//   var banner = document.getElementById("#google_translate_element");
//   if (banner) {
//     banner.style.display = "none";
//   }
// };

export default class Index1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      emps: [],
      foo: [],
      menu: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      crowdfunding: [],
      humanelement: [],
      nofeecommision: [],
      nomiddlemen: [],
      encourageaultruism: [],
      privacypolicy: [],
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),
      langID: [],
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
      isMobile: window.innerWidth <= 768,
      //cookie
      language: "",
      previousCookieLang: "",
    };
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  handleResize() {
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login/",
    };
    this.props.history.push(page);
  };
  submit = () => {
    let url =
      "https://newtestapi.paropakari.com/api/Authenticate/Get_LandingPosts";
    let data = this.state;
    // let user = JSON.parse(localStorage.getItem('user'));
    // const accessToken = user;
    //
    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        // Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          emps: data,
        });
        //
      });
  };

  ReloadPage = () => {
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    function setCookie(cName, cValue, expDays) {
      let date = new Date();
      date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
      // window.location.reload();
    }

    //      Cookies.remove('googtrans', {domain:'.paropakari.com'});
    // Cookies.remove('googtrans', {domain:'https://paropakari.com/'});
    // Cookies.remove('googtrans', {domain:'https://www.paropakari.com/'});
    //      Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://test.paropakari.com/#/'});
    //         Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'.paropakari.com'});
    //         Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://www.paropakari.com/'});
    //         Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://paropakari.com/'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), 30);
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://test.paropakari.com/#/'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'.paropakari.com'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://www.paropakari.com/'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://paropakari.com/'});
    //         let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
    //
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'.paropakari.com/'});
    if (sessionStorage.getItem("Indexreload") !== null) {
      //
      reloads = sessionStorage.getItem("Indexreload");
      reloads++;
      window.sessionStorage.setItem("Indexreload", reloads);
      //
    } else {
      reloads = 1;
      window.sessionStorage.setItem("Indexreload", reloads);
      //
    }

    if (reloads == 1) {
      window.location.reload();
    }
  };

  Menu = () => {
    let url = "https://newtestapi.paropakari.com/api/Authenticate/Translate";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          menu: data,
        });
        //
      });
  };

  Footer = () => {
    let url =
      "https://newtestapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    //
    //
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });
        //
      });
  };

  DefaultMenu = () => {
    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);
    window.sessionStorage.setItem("reloadcreate", reloads);
    let session = sessionStorage.getItem("GoogleTranslateValues");
    //
    // this.state.googtrans = session;
    // localStorage.getItem("GoogleTranslateValues",datatransIDs);
    let MID = "English";
    //
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }
    //

    //

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == "undefined") {
      GGID = this.state.googtrans.slice(4, 6);
    } else {
      GGID = MID;
    }

    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    let datatrans =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    //

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem("this.state.googtrans");
    //

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));
    //
    //
    let datatransID = MID;
    if (datatrans == "en") {
      datatransID = "English";
    } else if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    //
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~We are India's only expenseless direct crowdfunding and support platform that connects the needy with the more fortunate directly without third party involvement or fees~Opportunity to Bring a Smile~Contact Us~Terms and Conditions&lang=" +
        datatransID;
      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[8] !== "undefined" ? items[8] : null;
          var Termsandcondition =
            typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile =
            typeof items[7] !== "undefined" ? items[7] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var Whatwedodesc = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          window.sessionStorage.setItem("translate", LangID);
          //
          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,

            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,

            whatwedodesc: Whatwedodesc,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~EncourageAltruism~Crowd funding with:~A Human Element~No Fees/Commission~No Middlemen~Privacy Policy&lang=" +
        datatransID;
      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          //
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Crowdfunding = typeof items[5] !== "undefined" ? items[5] : null;
          var HumanElement = typeof items[6] !== "undefined" ? items[6] : null;
          var NoFeeCommision =
            typeof items[7] !== "undefined" ? items[7] : null;
          var NoMiddlemen = typeof items[8] !== "undefined" ? items[8] : null;
          var PrivacyPolicy = typeof items[9] !== "undefined" ? items[9] : null;

          var EncourageAltruism =
            typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            crowdfunding: Crowdfunding,
            humanelement: HumanElement,
            nofeecommision: NoFeeCommision,
            nomiddlemen: NoMiddlemen,
            encourageaultruism: EncourageAltruism,

            privacypolicy: PrivacyPolicy,
            // ,

            // langID: LangID
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright © 2022 Paropakari&lang=" +
        datatransID;
      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          //
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var CopyRight = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            copyright: CopyRight,
            // ,

            // langID: LangID
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
  };

  handleChanges(event) {
    this.setState({ value: event.target.value });
    //
    localStorage.setItem("menuid", event.target.value);

    //
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];
    //
    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~We are India's only expenseless direct crowdfunding and support platform that connects the needy with the more fortunate directly without third party involvement or fees~Opportunity to Bring a Smile~Contact Us~Terms and Conditions&lang=" +
        MID;
      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          //
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[8] !== "undefined" ? items[8] : null;
          var Termsandcondition =
            typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile =
            typeof items[7] !== "undefined" ? items[7] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var Whatwedodesc = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,

            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,

            whatwedodesc: Whatwedodesc,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~EncourageAltruism~Crowd funding with:~A Human Element~No Fees/Commission~No Middlemen~Privacy Policy~Copyright © 2022 Paropakari&lang=" +
        MID;
      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          //
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Crowdfunding = typeof items[5] !== "undefined" ? items[5] : null;
          var HumanElement = typeof items[6] !== "undefined" ? items[6] : null;
          var NoFeeCommision =
            typeof items[7] !== "undefined" ? items[7] : null;
          var NoMiddlemen = typeof items[8] !== "undefined" ? items[8] : null;
          var PrivacyPolicy = typeof items[9] !== "undefined" ? items[9] : null;

          var EncourageAltruism =
            typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            crowdfunding: Crowdfunding,
            humanelement: HumanElement,
            nofeecommision: NoFeeCommision,
            nomiddlemen: NoMiddlemen,
            encourageaultruism: EncourageAltruism,

            privacypolicy: PrivacyPolicy,
            // ,

            // langID: LangID
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright © 2022 Paropakari&lang=" +
        MID;
      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          //
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var CopyRight = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            copyright: CopyRight,
            // ,

            // langID: LangID
          });
        });
    }
  }

  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size))
      .fill("")
      .map(function () {
        return this.splice(0, size);
      }, longArray.slice());

    return <div>{newArray}</div>;
  }

  componentDidMount() {
    window.addEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();

    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();

    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    //     function setCookie(cName, cValue, expDays) {
    //       let date = new Date();
    //        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    //       const expires = "expires=" + date.toUTCString();
    //        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    //       // window.location.reload();
    // }

    //       Cookies.remove('googtrans', '.paropakari.com');
    // Cookies.remove('googtrans', 'https://paropakari.com/');
    // Cookies.remove('googtrans', 'https://www.paropakari.com/');
    //      Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://test.paropakari.com/#/'});
    //         Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'.paropakari.com'});
    //         Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://www.paropakari.com/'});
    //         Cookies.set('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://paropakari.com/'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), 30);
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://test.paropakari.com/#/'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'.paropakari.com'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://www.paropakari.com/'});
    //         setCookie('googtrans', sessionStorage.getItem('GoogleTranslateValues'), {domain:'https://paropakari.com/'});
    //         let cookie = Cookies.get('googtrans', {domain:'https://test.paropakari.com/#/'});
    //
    document.title = "India's only expenseless, direct crowdfunding platform";
    window.addEventListener("resize", this.handleResize);

    this.interval = setInterval(this.checkAndSetLanguage, 500); // Check every second
    this.checkAndSetLanguage(); // Initial check
  }
  checkAndSetLanguage = () => {
    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    const cookieLang =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    if (cookieLang !== this.state.previousCookieLang) {
      let newLanguage = "";
      let MID = "English";
      if (cookieLang === "en") {
        newLanguage = "English";
      } else if (cookieLang === "kn") {
        newLanguage = "Kannada";
      } else if (cookieLang === "ta") {
        newLanguage = "Tamil";
      } else if (cookieLang === "te") {
        newLanguage = "Telugu";
      } else if (cookieLang === "hi") {
        newLanguage = "Hindi";
      } else if (cookieLang === "es") {
        newLanguage = "Spanish";
      } else if (cookieLang === MID) {
        newLanguage = MID;
      }

      sessionStorage.setItem("translate", newLanguage);
      this.setState(
        { language: newLanguage, previousCookieLang: cookieLang },
        this.DefaultMenu()
      );
    }
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("load", this.submit);
    this.submit();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();

    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.removeEventListener("resize", this.handleResize);
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  searchTxt(e) {
    this.setState({ filter: e.target.value });
  }

  render() {
    let { filter, emps, menu, Details } = this.state;
    const currentYear = new Date().getFullYear();
    let { foo } = this.state;
    const {
      newArray,
      howitworks,
      beaparopakari,
      whatwedo,
      blog,
      login,
      indiaexpensless,
      ourstory,
      oppertunitybringsmile,
      privacypolicy,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      whatwedodesc,
      encourageaultruism,
      crowdfunding,
      humanelement,
      nofeecommision,
      nomiddlemen,
      langID,
      weareindias,
      copyright_new,
      paropakari_new,
      isMobile,
    } = this.state;
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    let Datasearch = emps.filter((emp) => {
      return Object.keys(emp).some(
        (key) =>
          typeof emp[key] === "string" &&
          emp[key].toLowerCase().includes(filter.toLowerCase())
      );
    });

    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{
            backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          {/*/head*/}
          <select
            className="form-control"
            style={{
              display: "none",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
            }}
            value={this.state.value}
            onChange={this.handleChanges}
          >
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
            <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          <div
            id="google_translate_element"
            onClick={this.refreshPage}
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
          ></div>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div className="pro-nav">
                <input
                  type="checkbox"
                  id="pro-nav-check"
                  onClick={this.checkfun}
                />
                <div className="pro-nav-header">
                  <div className="pro-nav-title notranslate">
                    <a className="navbar-brand" href="#/Index1">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontsize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div className="pro-nav-btn">
                  <label for="pro-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div className="pro-nav-links pro-side">
                      <p className="pro-center">
                        <a className="new-design" href="#/Login">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-menu">
                          {ourstory.result}
                        </a>
                        <a href="#/Howitworks" className="parop-menu">
                          {howitworks.result}
                        </a>
                        <a href="#/Blog" className="parop-menu">
                          {blog.result}
                        </a>
                        <a href="#/Login" className="parop-menu">
                          {login.result}
                        </a>
                      </div>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div className="pro-nav-links pro-side">
                      <p className="pro-center">
                        <a className="new-hindi-design" href="#/Login">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-hindi-menu">
                          {ourstory.result}
                        </a>
                        <a href="#/Howitworks" className="parop-hindi-menu">
                          {howitworks.result}
                        </a>
                        <a href="#/Blog" className="parop-hindi-menu">
                          {blog.result}
                        </a>
                        <a href="#/Login" className="parop-hindi-menu">
                          {login.result}
                        </a>
                      </div>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div className="pro-nav-links pro-side">
                      <p className="pro-center">
                        <a className="new-kannada-design" href="#/Login">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <div className="parop-kannada-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-kannada-menu">
                          {ourstory.result}
                        </a>
                        <a href="#/Howitworks" className="parop-kannada-menu">
                          {howitworks.result}
                        </a>
                        <a href="#/Blog" className="parop-kannada-menu">
                          {blog.result}
                        </a>
                        <a href="#/Login" className="parop-kannada-menu">
                          {login.result}
                        </a>
                      </div>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div className="pro-nav-links pro-side">
                      <p className="pro-center">
                        <a className="new-kannada-design" href="#/Login">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <div className="parop-kannada-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-kannada-menu">
                          {ourstory.result}
                        </a>
                        <a href="#/Howitworks" className="parop-kannada-menu">
                          {howitworks.result}
                        </a>
                        <a href="#/Blog" className="parop-kannada-menu">
                          {blog.result}
                        </a>
                        <a href="#/Login" className="parop-kannada-menu">
                          {login.result}
                        </a>
                      </div>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div className="pro-nav-links pro-side">
                      <p className="pro-center">
                        <a className="new-tamil-design" href="#/Login">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <div className="parop-tamil-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-tamil-menu">
                          {ourstory.result}
                        </a>
                        <a href="#/Howitworks" className="parop-tamil-menu">
                          {howitworks.result}
                        </a>
                        <a href="#/Blog" className="parop-tamil-menu">
                          {blog.result}
                        </a>
                        <a href="#/Login" className="parop-tamil-menu">
                          {login.result}
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pro-nav-links pro-side">
                      <p className="pro-center">
                        <a className="new-telugu-design" href="#/Login">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-telugu-menu">
                          {ourstory.result}
                        </a>
                        <a href="#/Howitworks" className="parop-telugu-menu">
                          {howitworks.result}
                        </a>
                        <a href="#/Blog" className="parop-telugu-menu">
                          {blog.result}
                        </a>
                        <a href="#/Login" className="parop-telugu-menu">
                          {login.result}
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <nav className="navbar navbar-expand-xxl bg-light">
            <div className="navbar  navbar-inverse" role="banner">
              <div className="container" id="containerid" >
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="sr-only">Toggle navigassstion</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>

                  <a className="navbar-brand" href="#/Index1">
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                    </h1>
                  </a>

                </div>


                

                <div className="collapse navbar-collapse">

                <ul className="nav navbar-nav navbar-right">

<li id="paro" className="active">

{(() => {if (langID == 'Tamil' ) {return (
<li id="paro" className="active tamilbtn"><a className="btn1 third" id="tamilbtn1"  href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'English' ) {return (
<li id="paro" className="active englishbtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontsize:'2vw', fontWeight: 'bold',padding:'13px',marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'Hindi' ) {return (
<li id="paro" className="active hindibtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>

{beaparopakari.result}
</a></li>
);
}
else {return(

<li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>

);}

})()}

</li>

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 


</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}

</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}

</a>

);}

})()} 
</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{blog.result}</a>

);}

})()} 


</li>         

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{login.result}</a>

);}

})()} 



</li>                    
</ul>

                </div>

              </div>
            </div>
            </nav>
          </header> */}

            <section
              style={
                {
                  // backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
                  // backgroundSize: 'cover',
                  // backgroundRepeat: 'no-repeat'
                }
              }
              className={
                this.state.movedown == true
                  ? "mag notranslate"
                  : "mag notranslate home-Tiptop"
              }
            >
              {/* <div className="container" > */}
              <div
                className="front-cont"
                style={
                  {
                    // backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat'
                  }
                }
              >
                <div
                  className="front-cont1 container"
                  style={{ marginTop: "27px" }}
                >
                  <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
                    {whatwedo.result}
                  </h2>
                  <h3>
                    <b>{whatwedodesc.result}</b>{" "}
                  </h3>
                  <br />
                  <h5 style={{ paddingBottom: 10 }}>
                    <b>#{encourageaultruism.result}</b>
                  </h5>
                  <h5>
                    {crowdfunding.result}
                    {langID.result}
                  </h5>
                  <h5>
                    <b>#{humanelement.result}</b>
                  </h5>
                  <h5>
                    <b>#{nofeecommision.result}</b>
                  </h5>
                  <h5>
                    <b>#{nomiddlemen.result}</b>
                  </h5>
                </div>
                <div className="">
                  <img
                    src="assets/images/02.jpg"
                    style={{
                      width: "99%",
                      height: "505px",
                      paddingleft: "10px",
                      paddingTop: "20px",
                    }}
                    alt="No Image"
                  />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              {/* </div> */}
            </section>
            {/*/#home-slider*/}
            <section
              id="services"
              style={{
                paddingTop: isMobile ? "15%" : "-25%",
              }}
            >
              <div
                className="containerss"
                style={{ marginLeft: "5%", marginRight: "5%" }}
              >
                <div className="row" style={{ marginTop: "-7%" }}>
                  <center>
                    <h2
                      style={{
                        fontWeight: "bold",
                        paddingBottom: isMobile ? "10%" : "5%",
                      }}
                      className="notranslate"
                    >
                      {oppertunitybringsmile.result}
                    </h2>
                  </center>
                  <SliderTest></SliderTest>
                </div>
              </div>
              <br />
              <br />
            </section>

            <section
              id="clients"
              style={{
                backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
                display: "none",
              }}
            >
              <div className="containerss">
                <div className="row">
                  <div className="col-sm-12">
                    <div
                      className="clients text-center wow fadeInUp"
                      data-wow-duration="500ms"
                      data-wow-delay="300ms"
                    >
                      <h2
                        className="title no translate"
                        style={{ fontWeight: "bold" }}
                      >
                        {blog.result}
                      </h2>
                      <p>
                        <img
                          src="assets/images/02.jpg"
                          className="img-responsive"
                          alt="No Image"
                        />
                      </p>

                      <p></p>
                    </div>
                    <div
                      className="clients-logo wow fadeIn"
                      data-wow-duration="1000ms"
                      data-wow-delay="600ms"
                    >
                      <div className="col-xs-3 col-sm-2"></div>
                      <div className="col-xs-3 col-sm-2"></div>
                      <div className="col-xs-3 col-sm-2"></div>
                      <div className="col-xs-3 col-sm-2"></div>
                      <div className="col-xs-3 col-sm-2"></div>
                      <div className="col-xs-3 col-sm-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <footer id="footer" style={{ color: "white" }}>
              <br />
              <div className="container notranslate">
                <div className="copyright">
                  <div className="row col-lg-12">
                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                      <p>
                        {copyright_new.result} &nbsp;{currentYear} ©{" "}
                        {paropakari_new.result} |&nbsp;
                        <a
                          href="#/Contactus"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {contactus.result}&nbsp;
                        </a>
                        |{" "}
                        <a
                          href="#/PrivacyPolicy"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{privacypolicy.result}&nbsp;
                        </a>
                        |
                        <a
                          href="#/TermsandCondition"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{termsandcondition.result}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div className="social-icons" style={{ color: "white" }}>
                        <ul className="nav nav-pills">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/beoneparopakari"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li>
                            <a
                              target="_blank"
                              href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                            >
                              <i className="fa fa-youtube-play" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/paropakari_beone/"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/paropakari/"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerss"></div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}
