import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import $ from "jquery";
import Cookies from 'js-cookie';
import { BASE_URLs } from '../Constants/BaseURL';

setTimeout(function () {
  $('#msgg').fadeOut('fast');
}, 3000);

// const refreshPage = () => {

//   setTimeout(() => window.location.reload(), 4000);


// }


export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ourstory: [],
      howitworks: [],
      Blog: [],
      Login: [],
      Paropakari: [],
      usernamee: [],
      welcome: [],
      passwordd: [],
      ForgetPassword: [],
      contactus: [],
      termsandcondition: [],
      signup: [],
      signin: [],
      Mobilenumber: [],
      copyright_new: [],
      paropakari_new: [],
      Emaill: [],
      ConfirmPasswordd: [],
      Reenteryourpassword: [],
      EnterCaptchavalue: [],
      Ifyoualreadyhasanaccount: [],
      causes: [],
      beneficiaries: [],
      beaparopakari: [],
      paropakaris: [],
      amountraised: [],
      EnterUsername: [],
      EnterPassword: [],
      MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter: [],
      PasswordandConfirmPasswordmustbesame: [],
      itshouldbeavalidemailaddress: [],
      pleaseenteremailorphonenumber: [],
      EnteraValidphonenumber: [],
      Copyright: [], enteryourpwd: [], enterconfirmpwd: [],
      paropakari: [],
      Captchisnotmatch: [],
      useralreadyexists: [],
      Phonenumberalreadyexists: [],
      Usernamealreadyexists: [],
      OTPsendtoyourRegisteredNumberorEmail: [],
      OTPsendtoyourRegisteredNumberorEmails: [],
      langID: [],
      privacypolicy: [],
      Emailalreadyexists: [],
      username: "",
      email: "",
      password: "",
      phonenumber: "",
      captcha: "",
      foo: [],
      formErrors: {
        username: "",
        email: "",
        password: "",
      //  phonenumber: "",
        confirmpassword: "",
        captcha: ""
      },
      formValidity: {
        username: false,
     //   phonenumber: false,
        email: false,
        password: false,
        confirmpassword: "",
        captcha: ""
      },
      formValues: {
        username: "",
        email: "",
        password: "",
     //   phonenumber: "",
        confirmpassword: "",
        captcha: ""
      },
      isSubmitting: false,
      movedown: true,
      isPasswordShown: false,
      isPasswordShowns: false
    };
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    // this.handleChanges = this.handleChanges.bind(this);
    this.handleChanged = this.handleChanged.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  togglePasswordVisiblity1 = () => {
    const { isPasswordShowns } = this.state;
    this.setState({ isPasswordShowns: !isPasswordShowns });
  };
  Footer = () => {

    


  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }
  //   componentDidMount () {
  //     loadCaptchaEnginge(6); 
  //     window.addEventListener('load', this.Footer);
  //     this.Footer();
  //     // window.removeEventListener('load', this.handleChanges);
  //     // this.handleChanges();
  //     document.title = "India's only expenseless, direct crowdfunding platform"
  //  };

  //  componentWillUnmount() {


  //   window.removeEventListener('load', this.Footer);
  //   this.Footer();
  //   // window.removeEventListener('load', this.handleChanges);
  //   // this.handleChanges();
  //   }

  componentDidMount() {
    loadCaptchaEnginge(6);
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
  };

  componentWillUnmount() {

    loadCaptchaEnginge(6);

    window.removeEventListener('load', this.Footer);

    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }

  handleValidation = target => {

    const { name, value } = target;

    const fieldValidationErrors = this.state.formErrors;

    const validity = this.state.formValidity;
    const isEmail = name === "email";
    const isPassword = name === "password";
    const isCpwd = name === "confirmpassword";
    //const isPhone = name === "phonenumber";
    const isUsername = name === "username";
    //const isUsername = isUsernameA.trim() ==""

    const emailTest = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    // const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const passwordTest =  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const passwordTest = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    const PhoneTest = new RegExp(/^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/)
    // if(isUsername == "" || isUsername == null) 
    // {
    //   fieldValidationErrors = `${usernaes}`
    // }
    // validity[name] = value.length > 0;


    if (isUsername) {
      var usernaes = this.state.EnterUsername.result;
      validity[name] = value.length > 0;
      fieldValidationErrors[name] = validity[name]
        ? ""
        : `${usernaes}`;
    }
    // if (isPhone) {

    //   var pho = this.state.EnteraValidphonenumber.result;
    //   validity[name] = value.length == 10;
    //   validity[name] = PhoneTest.test(value);
    //   fieldValidationErrors[name] = validity[name]
    //     ? ""
    //     : `${pho}`;

    // }
    if (isEmail) {
      {
        var passn = this.state.itshouldbeavalidemailaddress.result;
        validity[name] = emailTest.test(value);
        fieldValidationErrors[name] = validity[name] ? ""
          : `${passn}`;
      }

    }
    if (isPassword) {

      var pass = this.state.MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter.result;
      validity[name] = value.trim().length >= 8;
      validity[name] = passwordTest.test(value);
      fieldValidationErrors[name] = validity[name]
        ? ""
        : `${pass}`;
    }



    if (isCpwd) {

      var cws = this.state.PasswordandConfirmPasswordmustbesame.result;

      // validity[name] = value.length >= 3;

      // if(this.state.formValues.password != value )
      // {
      // validity[name] = confirmpasswordTest.test(value);
      validity[name] = this.state.formValues.password !== value.trim();
      fieldValidationErrors[name] = validity[name]
        ? ` ${cws}`
        : '';
      // }
    }

    this.setState({
      formErrors: fieldValidationErrors,
      formValidity: validity
    });
  };

  dosubmit() {

  }

  DefaultMenu() {
    //   
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));

    //let l_id = JSON.parse(localStorage.getItem('menuid'));

    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    

    //  

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~Welcome!~Username~Password~ContactUs~TermsandConditions~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var firstItem = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem1 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem2 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem3 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem4 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem5 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem6 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem7 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem8 = typeof items[8] !== "undefined" ? items[8] : null;
          var firstItem9 = typeof items[9] !== "undefined" ? items[9] : null;
          var firstItem10 = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            ourstory: firstItem,
            howitworks: firstItem1,
            Blog: firstItem2,
            Login: firstItem3,
            beaparopakari: firstItem4,

            welcome: firstItem5,
            usernamee: firstItem6,
            passwordd: firstItem7,
            contactus: firstItem8,
            termsandcondition: firstItem9,
            privacypolicy: firstItem10,
            langID: LangID

          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=SignUp~Forgotpassword~Mobilenumber~Email~ConfirmPassword~Reenteryourpassword~Already have an account?~signin~Enter Captcha~causes&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem10 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem11 = typeof items[1] !== "undefined" ? items[1] : null;

          var firstItem12 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem13 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem14 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem15 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem16 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem17 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem18 = typeof items[8] !== "undefined" ? items[8] : null;
          
          var firstItem19 = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            signup: firstItem10,
            ForgetPassword: firstItem11,
            Mobilenumber: firstItem12,
            Emaill: firstItem13,
            ConfirmPasswordd: firstItem14,
            Reenteryourpassword: firstItem15,
            Ifyoualreadyhasanaccount: firstItem16,
            signin: firstItem17,
            EnterCaptchavalue: firstItem18,
            causes: firstItem19,

            langID: LangID

          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=beneficiaries~paropakaris~amountraised~Enter Username~Enter Your Password~Password and Confirm Password Must Be the Same!~Enter a Valid Email Address~Enter Either Email or Mobile Number~Enter a Valid Mobile Number~User Already Exists!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem20 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem21 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem22 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem23 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem24 = typeof items[4] !== "undefined" ? items[4] : null;


          var firstItem26 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem27 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem28 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem29 = typeof items[8] !== "undefined" ? items[8] : null;
          var UserAlreadyExists = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            beneficiaries: firstItem20,
            paropakaris: firstItem21,
            amountraised: firstItem22,
            EnterUsername: firstItem23,
            EnterPassword: firstItem24,

            PasswordandConfirmPasswordmustbesame: firstItem26,
            itshouldbeavalidemailaddress: firstItem27,
            pleaseenteremailorphonenumber: firstItem28,
            EnteraValidphonenumber: firstItem29,
            useralreadyexists: UserAlreadyExists,
            langID: LangID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({


            MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter: MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter,

            langID: LangID
          });

        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright © 2022 Paropakari~paropakari~Captcha Does Not Match!~Phonenumber already exists!~Username already exists! Use a different user name~OTP sent to your registered number or email&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem30 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem31 = typeof items[1] !== "undefined" ? items[1] : null;
          var Captchisnotmatch = typeof items[2] !== "undefined" ? items[2] : null;
          var Phonenumberalreadyexists = typeof items[3] !== "undefined" ? items[3] : null;
          var Usernamealreadyexists = typeof items[4] !== "undefined" ? items[4] : null;
          var OTPsendtoyourRegisteredNumberorEmails = typeof items[5] !== "undefined" ? items[5] : null;
          var Emailalreadyexists = typeof items[6] !== "undefined" ? items[6] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            Copyright: firstItem30,
            paropakari: firstItem31,
            langID: LangID,
            Captchisnotmatch: Captchisnotmatch,
            Phonenumberalreadyexists: Phonenumberalreadyexists,
            Usernamealreadyexists: Usernamealreadyexists,
            OTPsendtoyourRegisteredNumberorEmails: OTPsendtoyourRegisteredNumberorEmails,
            Emailalreadyexists: Emailalreadyexists
          });

        });
    }




    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Enter Your Password~Enter Confirm Password&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var EnteryourPwd = typeof items[0] !== "undefined" ? items[0] : null;
          var EnterConfirmPwd = typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            enteryourpwd: EnteryourPwd,
            enterconfirmpwd: EnterConfirmPwd
          });

        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,

          });


          // 
        });
    }
  }



  handleChanges = () => {

    // this.setState({value: event.target.value}); 
    let user2 = sessionStorage.getItem('translate');

    var someVarName = "value";
    localStorage.setItem("someVarKey", someVarName);
    var someVarName = "value";
    localStorage.setItem("someVarKey", someVarName);
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~Welcome~Username~Password~ContactUs~TermsandConditions~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var firstItem = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem1 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem2 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem3 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem4 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem5 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem6 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem7 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem8 = typeof items[8] !== "undefined" ? items[8] : null;
          var firstItem9 = typeof items[9] !== "undefined" ? items[9] : null;
          var firstItem10 = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            ourstory: firstItem,
            howitworks: firstItem1,
            Blog: firstItem2,
            Login: firstItem3,
            beaparopakari: firstItem4,

            welcome: firstItem5,
            usernamee: firstItem6,
            passwordd: firstItem7,
            contactus: firstItem8,
            termsandcondition: firstItem9,
            privacypolicy: firstItem10,
            langID: LangID

          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=SignUp~Forgotpassword~Mobilenumber~Email~ConfirmPassword~Reenteryourpassword~Already have an account?~signin~Enter Captcha~causes&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem10 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem11 = typeof items[1] !== "undefined" ? items[1] : null;

          var firstItem12 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem13 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem14 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem15 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem16 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem17 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem18 = typeof items[8] !== "undefined" ? items[8] : null;

          var firstItem19 = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            signup: firstItem10,
            ForgetPassword: firstItem11,
            Mobilenumber: firstItem12,
            Emaill: firstItem13,
            ConfirmPassword: firstItem14,
            Reenteryourpassword: firstItem15,
            Ifyoualreadyhasanaccount: firstItem16,
            signin: firstItem17,
            EnterCaptchavalue: firstItem18,
            causes: firstItem19,

            langID: LangID

          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=beneficiaries~paropakaris~amountraised~Enter Username~Enter Your Password~Password and Confirm Password Must Be the Same!~Enter a Valid Email Address~Enter Either Email or Mobile Number~Enter a Valid Mobile Number~User Already Exists!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem20 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem21 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem22 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem23 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem24 = typeof items[4] !== "undefined" ? items[4] : null;


          var firstItem26 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem27 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem28 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem29 = typeof items[8] !== "undefined" ? items[8] : null;
          var UserAlreadyExists = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            beneficiaries: firstItem20,
            paropakaris: firstItem21,
            amountraised: firstItem22,
            EnterUsername: firstItem23,
            EnterPassword: firstItem24,

            PasswordandConfirmPasswordmustbesame: firstItem26,
            itshouldbeavalidemailaddress: firstItem27,
            pleaseenteremailorphonenumber: firstItem28,
            EnteraValidphonenumber: firstItem29,
            useralreadyexists: UserAlreadyExists,
            langID: LangID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({


            MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter: MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter,

            langID: LangID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright © 2022 Paropakari~paropakari~Captcha Does Not Match!~Phonenumber already exists!~Username already exists! Use a different user name~OTP sent to your registered number or email~Email already exists!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem30 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem31 = typeof items[1] !== "undefined" ? items[1] : null;
          var Captchisnotmatch = typeof items[2] !== "undefined" ? items[2] : null;
          var Phonenumberalreadyexists = typeof items[3] !== "undefined" ? items[3] : null;
          var Usernamealreadyexists = typeof items[4] !== "undefined" ? items[4] : null;
          var OTPsendtoyourRegisteredNumberorEmails = typeof items[5] !== "undefined" ? items[5] : null;
          var Emailalreadyexists = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            Copyright: firstItem30,
            paropakari: firstItem31,
            langID: LangID,
            Captchisnotmatch: Captchisnotmatch,
            Phonenumberalreadyexists: Phonenumberalreadyexists,
            Usernamealreadyexists: Usernamealreadyexists,
            OTPsendtoyourRegisteredNumberorEmails: OTPsendtoyourRegisteredNumberorEmails,
            Emailalreadyexists: Emailalreadyexists

          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Enter Your Password~Enter Confirm Password&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var EnteryourPwd = typeof items[0] !== "undefined" ? items[0] : null;
          var EnterConfirmPwd = typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            enteryourpwd: EnteryourPwd,
            enterconfirmpwd: EnterConfirmPwd
          });

        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,

          });


          // 
        });
    }
  }

  handleChanged(event) {
    this.setState({ value: event.target.value });
    localStorage.setItem('myData', event.target.value);
    
    localStorage.getItem('myData');
    window.sessionStorage.setItem('translate', (event.target.value));
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari'];
    

    var someVarName = "value";
    localStorage.setItem("someVarKey", someVarName);
    var someVarName = "value";
    localStorage.setItem("someVarKey", someVarName);
    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~Welcome!~Username~Password~ContactUs~TermsandConditions~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var firstItem = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem1 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem2 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem3 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem4 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem5 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem6 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem7 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem8 = typeof items[8] !== "undefined" ? items[8] : null;
          var firstItem9 = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            ourstory: firstItem,
            howitworks: firstItem1,
            Blog: firstItem2,
            Login: firstItem3,
            beaparopakari: firstItem4,
            privacypolicy: PrivacyPolicy,
            welcome: firstItem5,
            usernamee: firstItem6,
            passwordd: firstItem7,
            contactus: firstItem8,
            termsandcondition: firstItem9,

            langID: LangID

          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=SignUp~Forgotpassword~Mobilenumber~Email~ConfirmPassword~Reenteryourpassword~Ifyoualreadyhasanaccount~signin~EnterCaptchavalue~causes&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem10 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem11 = typeof items[1] !== "undefined" ? items[1] : null;

          var firstItem12 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem13 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem14 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem15 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem16 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem17 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem18 = typeof items[8] !== "undefined" ? items[8] : null;

          var firstItem19 = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            signup: firstItem10,
            ForgetPassword: firstItem11,
            Mobilenumber: firstItem12,
            Emaill: firstItem13,
            ConfirmPasswordd: firstItem14,
            Reenteryourpassword: firstItem15,
            Ifyoualreadyhasanaccount: firstItem16,
            signin: firstItem17,
            EnterCaptchavalue: firstItem18,
            causes: firstItem19,

            langID: LangID

          });

        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=beneficiaries~paropakaris~amountraised~Enter Username~Enter Your Password~Password and Confirm Password Must Be the Same!~Enter a Valid Email Address~Enter Either Email or Mobile Number~Enter a Valid Mobile Number~User Already Exists!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem20 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem21 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem22 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem23 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem24 = typeof items[4] !== "undefined" ? items[4] : null;


          var firstItem26 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem27 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem28 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem29 = typeof items[8] !== "undefined" ? items[8] : null;
          var UserAlreadyExists = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            beneficiaries: firstItem20,
            paropakaris: firstItem21,
            amountraised: firstItem22,
            EnterUsername: firstItem23,
            EnterPassword: firstItem24,
            PasswordandConfirmPasswordmustbesame: firstItem26,
            itshouldbeavalidemailaddress: firstItem27,
            pleaseenteremailorphonenumber: firstItem28,
            EnteraValidphonenumber: firstItem29,
            useralreadyexists: UserAlreadyExists,
            langID: LangID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({


            MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter: MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter,

            langID: LangID
          });

        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright © 2022 Paropakari~paropakari~Captcha Does Not Match!~Phonenumber already exists!~Username already exists! Use a different user name~OTP sent to your registered number or email~Email already exists!~Enter Your Password~Enter Confirm Password&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var firstItem30 = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem31 = typeof items[1] !== "undefined" ? items[1] : null;
          var Captchisnotmatch = typeof items[2] !== "undefined" ? items[2] : null;
          var Phonenumberalreadyexists = typeof items[3] !== "undefined" ? items[3] : null;
          var Usernamealreadyexists = typeof items[4] !== "undefined" ? items[4] : null;
          var OTPsendtoyourRegisteredNumberorEmails = typeof items[5] !== "undefined" ? items[5] : null;
          var Emailalreadyexists = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            Copyright: firstItem30,
            paropakari: firstItem31,
            langID: LangID,
            Captchisnotmatch: Captchisnotmatch,
            Phonenumberalreadyexists: Phonenumberalreadyexists,
            Usernamealreadyexists: Usernamealreadyexists,
            OTPsendtoyourRegisteredNumberorEmails: OTPsendtoyourRegisteredNumberorEmails,
            Emailalreadyexists: Emailalreadyexists
          });

        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Enter Your Password~Enter Confirm Password&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var EnteryourPwd = typeof items[0] !== "undefined" ? items[0] : null;
          var EnterConfirmPwd = typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            enteryourpwd: EnteryourPwd,
            enterconfirmpwd: EnterConfirmPwd
          });

        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
  }




  handleSubmit = (e) => {

    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { formValues, formValidity } = this.state;
    if (Object.values(formValidity).every(Boolean)) {

      this.setState({ isSubmitting: false });
    } else {
      for (let key in formValues) {
        let target = {
          name: key,
          value: formValues[key]
        };
        this.handleValidation(target);
      }
      this.setState({ isSubmitting: false });
    }
  };


  handleChange = ({ target }) => {
    const { formValues } = this.state;
    formValues[target.name] = target.value;
    this.setState({ formValues });
    this.handleValidation(target);
    // 
  };


  submit() {
    const emailpattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    const passpattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    // var usernaes = this.state.EnterUsername.result;
    // var passval = this.state.MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter.result;
    // var passn = this.state.itshouldbeavalidemailaddress.result;
    // var pho = this.state.EnteraValidphonenumber.result;
    // var cws = this.state.PasswordandConfirmPasswordmustbesame.result;
    // var EnterEmailorMobile = this.state.pleaseenteremailorphonenumber.result;
    let uname = document.getElementById("username").value
    let phnumber = document.getElementById("phonenumber").value
    let email = document.getElementById("email").value
    let passA = document.getElementById("Password").value
    let cpass = document.getElementById("confirmpassword").value
    let user_captcha = document.getElementById('captcha').value;
    let sample = validateCaptcha(user_captcha);
    
    const emailTest = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    const PhoneTest = new RegExp(/^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/)
    var EmailorMobile;
    var Password;
    var ConfirmPassword;

    let pass = passA.trim(" ")


    //   if(uname == "" || uname == null) 
    //   {
    //       alert(`${usernaes}`)
    //   }
    //   // else if ()
    //   // {
    //   //   alert()
    //   // }
    //    if(phnumber != "")
    //   {
    //     //if( phnumber != "" || phnumber != null)


    // if  (phnumber.length <= 9)
    //   {
    //     //if( phnumber != "" || phnumber != null)

    //     alert(`${pho}`)
    //     //alert(phnumber);
    //   }
    //   else
    //   {
    //     EmailorMobile=phnumber;
    //   }
    // }

    //       if ( email != "")
    //     {
    //       if(!emailpattern.test([email]) )
    //       {
    //         alert(`${passn}`)
    //       }
    //       else
    //       {
    //         EmailorMobile=email;
    //       }

    //     }

    //       if (email == "" && phnumber == "")
    //     {

    //       alert(EnterEmailorMobile)             

    //     }
    //    if (pass == "")
    //     {
    //      // alert('Password');
    //       alert(this.state.enteryourpwd.result)                

    //     }
    //     else if (pass != null)
    //     {
    //       //alert('Password1');
    //       if(!passpattern.test([pass]))
    //       {
    //         alert(`${passval}`)
    //       }
    //       else
    //       {
    //         Password=pass;
    //       }

    //     }
    //    if (cpass == "")
    //     {
    //       alert(this.state.enterconfirmpwd.result)
    //     }
    //     if (cpass != "")
    //     {
    //       if (pass != cpass)
    //       {
    //         alert(`${cws}`)
    //       }
    //       else
    //       {
    //         ConfirmPassword=cpass;
    //       }
    //     }

    //     if(uname != null && EmailorMobile != null && Password != null && ConfirmPassword != null)            
    //   {
    // alert(uname+EmailorMobile+Password+ConfirmPassword);
    if ((uname != null && uname != "" && email != null && emailTest.test(email) === true 
  //  && phnumber != null && PhoneTest.test(phnumber) === true 
    && pass != null && pass != "" && cpass != null && cpass != "" && (cpass == pass))) {
      
      
      
      
      

      // document.getElementById("text-box").innerHTML = "Please, Enter all details"
      // if((uname != null && uname !="" &&  pass != null && pass != "" && cpass != null && cpass != "" && (email != null || email != "" || phnumber!="" || phnumber != null )) )        
      // {
      //   document.getElementById("text-box").innerHTML = ""
      // }
      // if (phnumber == null || phnumber == "") {
      //   alert("Mobile Number Required");
      //   // document.getElementById("text-box").innerHTML = "Please, Enter Mobile Number"
      // }
      // else
        if (validateCaptcha(user_captcha) === true) {
          document.getElementById("text-box").innerHTML = ""
          // alert('Captcha Matched');
          loadCaptchaEnginge(6);
          document.getElementById('captcha').value = "";
          let url = "https://newtestapi.paropakari.com/api/Authenticate/register";
          let data = this.state;
          fetch(url, {
            method: 'POST',
            headers: {
              "Content-type": "application/json",
              "Accept": "application/json",
              "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
            },
            body: JSON.stringify({
              Username: this.state.username,
              Password: this.state.password,
              Email: this.state.email,
              Phonenumber: this.state.phonenumber,

            })

          }).then((result) => {
            result.json().then((resp) => {
              if (resp.message == "User already exists!") {
                //alert(resp.title);
                alert(this.state.useralreadyexists.result);
                window.location.reload();
              }
              else if (resp.message == "UserName already exists!") {
                //alert(resp.title);
                alert(this.state.Usernamealreadyexists.result);
                window.location.reload();
              }
              if (resp.message == "Ok") {
                
                localStorage.setItem('Phonenumber', JSON.stringify(resp.phonenumber));
                localStorage.setItem('Username', this.state.username);
                localStorage.setItem('Password', this.state.password);

                localStorage.setItem('ph', this.state.phonenumber);
                localStorage.setItem('mail', this.state.email);

                localStorage.setItem('Email', JSON.stringify(resp.email));
                let myCipher = this.cipher('mySecretSalt');
                localStorage.setItem('otp', myCipher(resp.otp));
                // localStorage.setItem('otp', JSON.stringify(resp.otp));
                window.location.href = "#/OTP";

                alert(this.state.OTPsendtoyourRegisteredNumberorEmails.result);
                // this.props.history.push('/login');
                


              }
            })
          })


        }
        else if (validateCaptcha(user_captcha) === false) {
          document.getElementById('captcha').value = ""
          alert(this.state.Captchisnotmatch.result);

          // window.location.reload();

        }
    }



  }
 decipher = (salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join('');
  };

  cipher = (salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
  };
  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  render() {
    const currentYear = new Date().getFullYear();
    const { isPasswordShown, copyright_new,
      paropakari_new } = this.state;
    const { isPasswordShowns } = this.state;
    const { formValues, formErrors, isSubmitting, touched } = this.state;
    const { ourstory, howitworks, beaparopakari, Blog, Login, Paropakari, welcome, usernamee, passwordd, ForgetPassword, contactus, termsandcondition, signup, Emaill,
      ConfirmPasswordd, Reenteryourpassword, Mobilenumber, Ifyoualreadyhasanaccount, signin, EnterCaptchavalue, causes, beneficiaries, paropakaris,
      amountraised, EnterUsername, EnterPassword, MustContain8CharactersOneUppercaseOneLowercaseOneNumberandonespecialcaseCharacter,
      PasswordandConfirmPasswordmustbesame, itshouldbeavalidemailaddress, pleaseenteremailorphonenumber, useralreadyexists,
      EnteraValidphonenumber, Copyright, paropakari, Captchisnotmatch, Phonenumberalreadyexists, langID, privacypolicy,
      Usernamealreadyexists, enteryourpwd, enterconfirmpwd,
      OTPsendtoyourRegisteredNumberorEmails } = this.state;
    let { foo } = this.state;
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content />
          <meta name="author" content />
          <title>Blog Masonry | Triangle</title>
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          <link href="./assets/css/Register.css" rel="stylesheet" />
          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          {/* 
          <Script
      url="../assets/js/jquery.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/bootstrap.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/lightbox.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/wow.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/main.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />  */}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          {/*/head*/}
          {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>      
              <div className="container">
                <div className="row">
                
                </div>
              </div>
              <div className="navbar navbar-inverse" role="banner">
                <div className="container">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#/Index1">
            <h1 style={{color:'blue',fontFamily:'Biysk-Regular',fontsize:'52px'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'23%',fontsize:'60px'}}  />AROPAKARI</h1>
          </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                    
                    <li className=""><a class="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',marginRight:'82px',marginLeft:'-251px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
       
       Be a Paropakari
       </a></li>
                      
                      <li><a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Our Story</a></li>                 
                      <li><a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>How It Works</a></li> 
                      <li><a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Blog</a></li>         
                                         
                      <li className="active"><a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Login</a></li>                    
                    </ul>
                  </div>
                 
                </div>
              </div>
            </header> */}
          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanged}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>

            <div className="mag notranslate">
              <div class="pro-nav">
                <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                <div class="pro-nav-header">
                  <div class="pro-nav-title notranslate">
                    <a className="navbar-brand" href="#/Index1">
                      <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-nav-btn">
                  <label for="pro-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : langID == "Hindi" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-hindi-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-hindi-menu">{Login.result}</a>
                    </div>
                  </div>
                  </> : langID == "Spanish" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : langID == "Kannada" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : langID == "Tamil" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-tamil-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-tamil-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-tamil-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-telugu-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-telugu-menu">{Login.result}</a>
                    </div>
                  </div>
                </>}
              </div>
            </div>

            {/* <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <nav class="navbar navbar-expand-xxl bg-light">
            <div className="navbar  navbar-inverse" role="banner">
              <div className="container" id="containerid" >
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="sr-only">Toggle navigassstion</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>

                  <a className="navbar-brand" href="#/Index1">
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                    </h1>
                  </a>

                </div>


                

                <div className="collapse navbar-collapse">

                <ul class="nav navbar-nav navbar-right">

<li id="paro" className="active">

{(() => {if (langID == 'Tamil' ) {return (
<li id="paro" className="active tamilbtn"><a className="btn1 third" id="tamilbtn1"  href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'English' ) {return (
<li id="paro" className="active englishbtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontsize:'2vw', fontWeight: 'bold',padding:'13px',marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'Hindi' ) {return (
<li id="paro" className="active hindibtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>

{beaparopakari.result}
</a></li>
);
}
else {return(

<li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>

);}

})()}

</li>

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 


</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}

</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}

</a>

);}

})()} 
</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{Blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{Blog.result}</a>

);}

})()} 


</li>         

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{Login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{Login.result}</a>

);}

})()} 



</li>                    
</ul>

                </div>

              </div>
            </div>
            </nav>
          </header> */}

            {/*/#header*/}

            {/*/#action*/}

            <section id="blog" className={this.state.movedown == true ? "padding-top padding-bottom new-padding" : "padding-top padding-bottom new-padding Tiptop"} style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>

              <div className="container">

                <div className="row">

                  <div id="rcont" class="rcont" style={{ height: '786px' }}>

                    <div id="login" class="form sign-in" style={{ backgroundColor: '#a5d9cf' }}>
                      {/* <h2 style={{color:'black'}}>{welcome.result}</h2> */}
                      <form className="" onSubmit={this.handleSubmit} role="form" style={{ margin: '5px' }} >
                        <label id="text-box" style={{ textAlign: "center", color: "red", marginTop: "-12px" }}></label>
                        {/* {formErrors.username?
        <div className="danger" style={{textAlign:"center", color:"red"}}> <i class="fa fa-times-circle"></i>{formErrors.username}</div> */}

                        {formErrors.username ?
                          <div className="danger" style={{ textAlign: "center", color: "red" }}><i class="fa fa-times-circle"></i>&nbsp;{formErrors.username}</div>
                          : formErrors.phonenumber ?
                            <div className="danger" style={{ textAlign: "center", color: "red" }}> <i class="fa fa-times-circle"></i>&nbsp;{formErrors.phonenumber}</div>
                            : formErrors.email ?
                              <div className="danger" style={{ textAlign: "center", color: "red" }}> <i class="fa fa-times-circle"></i>&nbsp;{formErrors.email}</div>

                              // : (formValues.email.length) ?

                              // <div> {(() => {
                              //   if (formValues.email.length > 1) {
                              //     return (

                              //       <div className="danger" style={{ textAlign: "center", color: "red" }}> {formErrors.email}</div>
                              //     );
                              //   }
                              //   //  else if (formValues.phonenumber.length > 0 )  {return ( 
                              //   //   <div className="danger" style={{textAlign:"center", color:"red"}}>  {formErrors.phonenumber}</div> 
                              //   //   );}



                              // })()}</div>

                              : formErrors.password ?
                                <div className="danger" style={{ textAlign: "center", color: "red" }}> <i class="fa fa-times-circle"></i>&nbsp;{formErrors.password}</div>
                                : formErrors.confirmpassword ?
                                  <div className="danger" style={{ textAlign: "center", color: "red" }}> <i class="fa fa-times-circle"></i>&nbsp;{formErrors.confirmpassword}</div>


                                  // : formValues.username.length != 0 && formValues.phonenumber.length == 0 ?

                                  //   <div className="danger" style={{ textAlign: "center", color: "red" }}><div id="msgg"> <i class="fa fa-times-circle"></i> </div>{formErrors.phonenumber}</div>





                                  :


                                  null

                        }
                        <label>


                          <span>{usernamee.result}</span>


                          <input id="username" name="username" onChange={this.handleChange} autoComplete="off"
                            value={formValues.username} maxLength={30}
                            onBlur={(data) => { this.setState({ username: data.target.value }) }}
                            placeholder="" required="required" type="text" />
                        </label>


                        <label>
                          <span>{Mobilenumber.result}</span>

                          <input value={formValues.phonenumber}
                            onBlur={(data) => { this.setState({ phonenumber: data.target.value }) }} autoComplete="off" onChange={this.handleChange} onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            id="phonenumber" maxLength={10} name="phonenumber" placeholder="" type="text" />
                        </label>


                        <label>
                          <span>{Emaill.result}</span>
                          <input onChange={this.handleChange} autoComplete="off"
                            value={formValues.email} required="required"
                            onBlur={(data) => { this.setState({ email: data.target.value }) }}
                            id="email" name="email" placeholder="" type="text" />
                        </label>


                        <label>
                          <span>{passwordd.result}</span>

                          {/* <input onChange={this.handleChange}
             value={formValues.password}
             onBlur={(data)=>{this.setState({password:data.target.value})}}
              id="Password" name="password" placeholder="" type="password" name="password" /> */}
                          <div className="passwowrd_foeld" style={{ display: "flex" }}>
                            <input value={formValues.password} autoComplete="off" onChange={this.handleChange} placeholder=""
                              onBlur={(data) => { this.setState({ password: data.target.value }) }} id="Password" name="password" type={isPasswordShown ? "text" : "password"} />

                            <i style={{ marginLeft: "-16px", marginTop: "9px" }}
                              className={isPasswordShown ? "fa fa-eye-slash" : "fa fa-eye password-icon"}
                              onClick={this.togglePasswordVisiblity}
                            /> </div>
                        </label>


                        <label>
                          <span>{ConfirmPasswordd.result}</span>

                          {/* <input  id="confirmpassword" 
   
              placeholder="" type="password" onChange={this.handleChange}
             value={formValues.confirmpassword} id="confirmpassword" name="confirmpassword"  /> */}
                          <div className="passwowrd_foeld" style={{ display: "flex" }}>
                            <input value={formValues.confirmpassword} autoComplete="off" onChange={this.handleChange} placeholder=""
                              id="confirmpassword" name="confirmpassword" type={isPasswordShowns ? "text" : "password"} />

                            <i style={{ marginLeft: "-16px", marginTop: "9px" }}
                              className={isPasswordShowns ? "fa fa-eye-slash" : "fa fa-eye password-icon"}
                              onClick={this.togglePasswordVisiblity1}
                            /> </div>
                        </label>
                        <br />
                        <center>
                          <div className="Captcha">
                            <LoadCanvasTemplate />
                          </div>

                          <div className="k-row" style={{marginTop:'4%'}}>
                            <input id="captcha" name="captcha" style={{ width: '50%', color: 'black',fontSize:'16px' }} type="text" autoComplete="off" placeholder={EnterCaptchavalue.result}></input>
                          </div>
                        </center>


                        {(() => {
                          if (langID == "English") {
                            return (
                              <button type="submit" onClick={
                                () => {
                                  this.submit();

                                  this.handleSubmit()
                                }
                              } id="Reg" class="submit">{signup.result}</button>
                            );
                          }
                          else if (langID == 'Telugu') {
                            return (

                              <button type="submit" onClick={
                                () => {
                                  this.submit();

                                  this.handleSubmit()
                                }
                              } id="Reg" class="submit" >{signup.result}</button>
                            );
                          }
                          else if (langID == 'Hindi') {
                            return (

                              <button type="submit" onClick={
                                () => {
                                  this.submit();

                                  this.handleSubmit()
                                }
                              } id="Reg" class="submit" >{signup.result}</button>
                            );
                          }
                          else if (langID == 'Spanish') {
                            return (

                              <button type="submit" onClick={
                                () => {
                                  this.submit();

                                  this.handleSubmit()
                                }
                              } id="Reg" class="submit" >{signup.result}</button>
                            );
                          }

                          else if (langID == 'Tamil') {
                            return (

                              <button type="submit" onClick={
                                () => {
                                  this.submit();

                                  this.handleSubmit()
                                }
                              } id="Reg" class="submit" >{signup.result}</button>
                            );
                          }


                          else if (langID == 'Kannada') {
                            return (

                              <button type="submit" onClick={
                                () => {
                                  this.submit();

                                  this.handleSubmit()
                                }
                              } id="Reg" class="submit" >{signup.result}</button>
                            );
                          }

                          else {
                            return (

                              <button type="submit" onClick={
                                () => {

                                  this.handleSubmit()
                                }
                              } id="Reg2" class="submit" >{signup.result}</button>
                            );
                          }

                        })()

                        }


                        <div class="tnr1" id="tnr1">
                          <center>  <a href="#/Login" class="new__btn img__btn m--up" style={{ textDecoration: 'none', color: 'rgb(102, 71, 18)', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px' }}>{Login.result}</a></center>

                        </div>
                      </form>
                    </div>

                    <div id="rsub-cont" class="sub-rcont">

                      <div id="rimgsub-cont" class="img">
                        <div class="img__text m--up">

                          <h3>{Ifyoualreadyhasanaccount.result}</h3>
                        </div>
                        <div class="img__text m--in">

                          <h3>If you already has an account, just sign in.</h3>
                        </div>
                        <div class="tnr">
                          <a href="#/Login" class="img__btn m--up" style={{ textDecoration: 'none', color: 'rgb(102, 71, 18)', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginLeft: '90px' }}>{Login.result}</a>
                          <span class="m--in">Login</span>
                        </div>
                      </div>
                      <div class="form sign-up">
                        <h2>Create your Account</h2>
                        <label>
                          <span>Name</span>
                          <input type="text" />
                        </label>
                        <label>
                          <span>Email</span>
                          <input type="email" />
                        </label>
                        <label>
                          <span>Password</span>
                          <input type="password" />
                        </label>
                        <button class="submit">Sign In</button>
                        <a href="#/Login" style={{ textDecoration: 'none' }} >Login</a>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </section>


            {/*/#blog*/}
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result}&nbsp;&nbsp; |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>
            {/*/#footer*/}
          </div>
        </div>
      </div>

    )
  }
}