import React, { Component } from 'react'
import $ from "jquery";
import Progress from "./Progress";
import { ShareSocial } from 'react-share-social'
import Popup from './Popup';
import Likepopup from './Likepopup';
import Comment from './Comment';
import Cookies from 'js-cookie';
import { BASE_URLs } from '../Constants/BaseURL';
import TaggedFriends from './TaggedFriends';

import { Facebook } from '@material-ui/icons';

$(document).ready(function () {
  $('.theme-loader1').fadeOut(1000);
})


// const refreshPage = () => {

//   setTimeout(() => window.location.reload(), 2000);


// }
$(document).ready(function () {
  //custom button for homepage
  $(".share-btn").click(function (e) {
    $('.networks-5').not($(this).next(".networks-5")).each(function () {
      $(this).removeClass("active");
    });
    $(this).next(".networks-5").toggleClass("active");
  });
});
export default class PayoutDetail extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      id: this.props.match.params.id,
      user_id: "",
      profile_imagePath: "",
      profile_name: "",
      upload_id: "",
      created_date: "",
      upload_description: "",
      upload_amount: "",
      upload_place: "",
      upload_title: "",
      status_80G: "",
      upload_num_of_beneficiaries: "",
      category_id: "",
      category_name: "",
      delete_status: false,
      file_name: "",
      file_type: "",
      likes: 0,
      filecount: 0,
      uid: "",
      comments: 3,
      confirmed: 0,
      pending: 0,
      response_pledge: "",
      response_message: "",
      emps: [],
      comm: [],
      Payin: [],
      ClaimDet: [],
      like: [], share: [],
      notify: [],
      paropakaris: [],
      Tagged: [],
      Liked: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [], Causefor_Id: [], detailsss: [],
      invite: [],
      profile: [], changepassword: [], group: [], logout: [], search: [], categories: [], foodandnutrition: [], health: [], professional: [], sports: [], socialwefare: [], others: [],
      sort: [], mostrecent: [], mostpopular: [], available80g: [], amountdesc: [], amountasc: [], complete: [], incomplete: [], education: [], enddate: [],
      causes: [], privacypolicy: [],
      beneficiaries: [],
      paropakariss: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [], tag: [], likess: [], comment: [], donateasmile: [],
      year: [], activity: [], mycauses: [], likedcauses: [], commentedcauses: [], pledgedcauses: [],
      langID: [],
      foo: [],
      HappinessMeter: [],
      Achieved: [],
      OnItsWay: [],
      Makeadifferencewithpledgeof: [],
      Writemessageto: [],
      Thecreatorcause: [],
      isresponsibleforproviding: [],
      clm: [],
      Paropakaritakesnoresponsibility: [],
      DonateNow: [],
      ScanandDonatewithAPP: [],
      CommentHere: [],

      YourMessagetotheNeedy: [],
      YourofSupport: [],
      googtrans: Cookies.get('googtrans', { domain: '.paropakari.com' }),
      Submit: [],
      EndDate: [],

    }

    this.handleClick = this.handleClick.bind(this);
    this.initialState = this.state;
    this.handleMsg = this.handleMsg.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleSubmitcollect = this.handleSubmitcollect.bind(this);
    this.handleSubmitcollecttt = this.handleSubmitcollecttt.bind(this);
    this.handleSubmitevents = this.handleSubmitevents.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: './Login',

    }
    this.props.history.push(page)
  }


  handleSubmitcollect = async event => {
    event.preventDefault();
    const { response_message, response_pledge } = this.state;
    const urll = new URL(window.location.href);
    const upload_id = urll.searchParams.get("upload_id");

    var percent = document.getElementById("response_pledge").value;

    if (percent == "") {
      alert("Please enter the payout amount");
    }
    else {

      var num = document.getElementById("amount").value;

      var out = num / 100 * percent;

      


      

      

      


      localStorage.setItem('Amount', JSON.stringify(out));


      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;

      const phone = document.getElementById("profile_mobileNumber").value
      const address = document.getElementById("profile_location").value
      const benename = document.getElementById("profile_name").value
      const bankAccount = document.getElementById("upload_BankAccount").value
      const ifsc = document.getElementById("upload_IFSC").value
      const upload_UPIIDd = document.getElementById("upload_UPIID").value
      const email = document.getElementById("profile_email").value
      //const upload_id=document.getElementById("upload_id").value

      
      //const data = new FormData(event.target);
      let Amount = JSON.parse(localStorage.getItem('Amount'));
      

      const datas = {
        "customer_email": email,

        "customer_name": benename,
        "customer_phone": phone,
        "customer_bank_account_number": bankAccount,
        "customer_bank_ifsc": ifsc
      }
      //https://newtestapi.paropakari.com/api/GetProfile/SupportCause?response_message=' + response_message + '&response_pledge=' + response_pledge + '&upload_id=' + this.props.match.params.id
      const url = "https://newtestapi.paropakari.com/api/Payment/Collectmoney?benename=" + benename + "&phone=" + phone + "&email=" + email + "&order_amount=" + Amount + "&order_currency=INR";

      const options = {
        method: "POST",
        headers: {

          // 'Content-Type': 'application/json; charset=utf-8',
          'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers':'Access-Control-Request-Headers',
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken

        },
        body: datas
      };

      // this.setState(this.initialState)
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        // localStorage.setItem('benefId', JSON.stringify(result.benefId));
        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));
        
        localStorage.setItem('order_token', JSON.stringify(result.order_token));
        localStorage.setItem('order_id', JSON.stringify(result.order_id));
        if (response.ok) {

          var profile_location = document.getElementById("profile_location").value
          var profile_mobileNumber = document.getElementById("profile_mobileNumber").value
          var profile_name = document.getElementById("profile_name").value
          var profile_email = document.getElementById("profile_email").value

          var upload_IFSC = document.getElementById("upload_IFSC").value
          var upload_UPIID = document.getElementById("upload_UPIID").value
          var upload_BankAccount = document.getElementById("upload_BankAccount").value
          // var upload_id=document.getElementById("upload_id").value


          this.props.history.push({
            pathname: "/SamplePayment",
            state: {
              data: {
                profile_email: profile_email,
                profile_location: profile_location,
                profile_mobileNumber: profile_mobileNumber,
                profile_name: profile_name,
                upload_IFSC: upload_IFSC,
                upload_UPIID: upload_UPIID,
                upload_BankAccount: upload_BankAccount,
                upload_id: this.props.match.params.id,
                response_message: response_message,

                // upload_id:upload_id
              }
            }
          });
        } else {
          alert('Something went wrong');

          // window.location.reload();
        }

      }
      catch (error) {
        console.error(error);
      }
    }

  }

  handleSubmitevents = async event => {

    event.preventDefault();

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;

    const data = new FormData(event.target);

    var url = "https://newtestapi.paropakari.com/api/Payout/UpdatePayout";

    var options = {
      method: "POST",
      headers: {

        Authorization: "Bearer " + accessToken,
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json'
      },
      body: data
    };

    
    try {
      const response = await fetch(url, options);

      if (response.ok) {


        alert("Updated Successfully");
        window.location.href = "#/PayoutList";

      }
      else {
        window.location.reload();
      }

    }
    catch (error) {
      console.error("error", error);
    }

  };

  getuser = () => {
    let url = "https://newtestapi.paropakari.com/api/Profile/GetMyProfile";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    

    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          detailsss: data
        });
        
        // 
      });
  }

  submit = () => {

    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    
    let url = "https://newtestapi.paropakari.com/api/Profile/Get_ResponseDetails?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          emps: data
        });
        
        // 
      });
  }
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('username')
    localStorage.removeItem('user')

    localStorage.clear();
    window.location.href = "#/Login";

  }
  AddLiking = async event => {
    event.preventDefault();


    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem('user'));

    const accessToken = user;
    const url = "https://newtestapi.paropakari.com/api/Profile/Add_LikeDetails";
    const options = {
      method: "POST",
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: data,
    };
    this.setState(this.initialState)
    const response = await fetch(url, options);
    const result = await response.json();
    if (response.ok) {

      // alert(' success')


      window.location.reload();

    }
    this.setState(this.initialState)
    

    

  }

  notify = () => {


    let url = "https://newtestapi.paropakari.com/api/Profile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });
        
        // 
      });
  }


  comment = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    

    let url = "https://newtestapi.paropakari.com/api/Profile/Get_Comment?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          comm: data
        });
        
        // 
      });
  }


  Tagged = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    

    let url = "https://newtestapi.paropakari.com/api/Profile/Get_TagDetails?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          Tagged: data
        });
        
        // 
      });
  }


  Liked = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    

    let url = "https://newtestapi.paropakari.com/api/Profile/Get_LikedUsers?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          Liked: data
        });
        
        // 
      });
  }

  Payin = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    
    let Admin_status = JSON.parse(localStorage.getItem('Admin_status'));

    
    if (Admin_status == 1) {
      let url = "https://newtestapi.paropakari.com/api/Payout/GetPayInInfo?id=" + this.props.match.params.id;
      let data = this.state;
      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;
      
      
      fetch(url, {
        method: 'GET',
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        //body:JSON.stringify(data)
      }).then(response => response.json())
        .then(data => {
          this.setState({
            Payin: data
          });
          
          // 
        });
    }
  }

  ClaimDetail = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    
    let Admin_status = JSON.parse(localStorage.getItem('Admin_status'));

    
    if (Admin_status == 1) {
      let url = "https://newtestapi.paropakari.com/api/Payout/GetClaimPayoutDetail?id=" + this.props.match.params.id;
      let data = this.state;
      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;
      
      
      fetch(url, {
        method: 'GET',
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        //body:JSON.stringify(data)
      }).then(response => response.json())
        .then(data => {
          this.setState({
            ClaimDet: data
          });
          
          // 
        });
    }
  }


  paropakaris = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    

    let url = "https://newtestapi.paropakari.com/api/Profile/Get_ParopakarisDetails?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          paropakaris: data
        });
        
        // 
      });
  }

  like = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    

    let url = "https://newtestapi.paropakari.com/api/Profile/Get_ParopakarisCount?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          like: data
        });
        
        // 
      });
  }

  ClaimAmountDetail = () => {

    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    
    let url = "https://newtestapi.paropakari.com/api/Profile/Get_ClaimAmountDetail?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          clm: data
        });
        
        var ClaimAmount = document.getElementById("claimamount").value;

        
        window.sessionStorage.setItem('ClaimAmount', (ClaimAmount));
      });
  }

  handleMsg = (event) => {
    const { response_message, response_pledge } = this.state;
    const urll = new URL(window.location.href);
    const upload_id = urll.searchParams.get("upload_id");
    event.preventDefault();

    var percent = document.getElementById("response_pledge").value;

    var num = document.getElementById("amount").value;

    var out = num / 100 * percent;



    

    

    


    localStorage.setItem('Amount', JSON.stringify(out));


    const data = new FormData(event.target);
    

    
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch('https://newtestapi.paropakari.com/api/Profile/SupportCause?response_message=' + response_message + '&response_pledge=' + response_pledge + '&upload_id=' + this.props.match.params.id, {
      method: 'POST',
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken

      },
      body: data,
    });
    var upload_Title = document.getElementById("upload_Title").value;

    alert("Payout details for " + upload_Title + " is updated Successfully");
    window.location.reload();


    
  }

  handlePayment = (event) => {
    const { response_message, response_pledge } = this.state;
    const urll = new URL(window.location.href);
    // const upload_id = urll.searchParams.get("upload_id");
    event.preventDefault();

    var percent = document.getElementById("response_pledge").value;

    var num = document.getElementById("amount").value;

    var out = num / 100 * percent;



    

    

    

    var upload_id = document.getElementById("upload_id").value;
    localStorage.setItem('Amount', JSON.stringify(out));
    localStorage.setItem('upload_id', JSON.stringify(upload_id));

    const data = new FormData(event.target);
    

    
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch('https://newtestapi.paropakari.com/api/Profile/SupportCause?response_message=' + response_message + '&response_pledge=' + response_pledge + '&upload_id=' + this.props.match.params.id, {
      method: 'POST',
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken

      },
      body: data,
    });

    //alert("Your Suuport sent successfully");

    this.handleOrder(event);
    //  window.location.reload();
    //  var Paymentt= {

    //   pathname: '#/SamplePayment',
    //   state: {
    //     data:{

    //      name: this.state.BeneficiaryName,
    //      id:1
    //     }
    //     }
    //   }

    //   this.props.history.push(Paymentt)
    // window.location.href = "#/SamplePayment";

    //   var profile_location= document.getElementById("profile_location").value
    //   var profile_mobileNumber= document.getElementById("profile_mobileNumber").value
    //   var profile_name= document.getElementById("profile_name").value
    //   var profile_email= document.getElementById("profile_email").value

    //   var upload_IFSC= document.getElementById("upload_IFSC").value
    //   var upload_UPIID= document.getElementById("upload_UPIID").value
    //   var upload_BankAccount= document.getElementById("upload_BankAccount").value
    // var upload_id=this.props.match.params.id;
    // 


    //   this.props.history.push( {pathname: "/SamplePayment",
    //   state: { data:{
    //     profile_email:profile_email,
    //     profile_location: profile_location,
    //     profile_mobileNumber:profile_mobileNumber,
    //     profile_name:profile_name,
    //     upload_IFSC:upload_IFSC,
    //     upload_UPIID:upload_UPIID,
    //     upload_BankAccount:upload_BankAccount,
    //     upload_id:upload_id
    //         } }});
    //window.location.reload();

    
  }



  handleOrder = async event => {
    event.preventDefault();

    var percent = document.getElementById("response_pledge").value;

    var num = document.getElementById("amount").value;
    var out = num / 100 * percent;
    
    
    

    localStorage.setItem('Amount', JSON.stringify(out));

    const newPercent = num / 100 * percent;
    this.setState({ downPaymentPercent: newPercent })
    
    
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    const url = "https://newtestapi.paropakari.com/api/Profile/CreateOrder?amount=" + newPercent;
    const options = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: "data",
    };

    const response = await fetch(url, options);
    const result = await response.json();

    localStorage.setItem('order_id', JSON.stringify(result.id));
    window.sessionStorage.setItem('order_id', (result.id));
    localStorage.setItem('razorpay_amount', JSON.stringify(result.amount));
    localStorage.setItem('status', JSON.stringify(result.status));
    if (response.ok) {

      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;

      alert('1');
      var percent = document.getElementById("response_pledge").value;

      var num = document.getElementById("amount").value;
      var out = num / 100 * percent;
      
      
      

      localStorage.setItem('Amount', JSON.stringify(out));

      const newPercent = num / 100 * percent;
      this.setState({ downPaymentPercent: newPercent })
      
      

      alert('11');
      var profile_name = document.getElementById("profile_name").value;
      
      var profile_location = document.getElementById("profile_location").value;
      var upload_BankAccount = document.getElementById("upload_BankAccount").value;
      var upload_IFSC = document.getElementById("upload_IFSC").value;
      var upload_UPIID = document.getElementById("upload_UPIID").value;
      var profile_email = document.getElementById("profile_email").value;
      // 
      var profile_mobileNumber = document.getElementById("profile_mobileNumber").value;
      //
      var user_id = document.getElementById("user_id").value;
      //

      var user_name = document.getElementById("user_name").value;
      //
      var user_number = document.getElementById("user_number").value;
      //
      var user_Bankac = document.getElementById("user_Bankac").value;
      //
      var user_IFSC = document.getElementById("user_IFSC").value;
      //
      var user_UPIID = document.getElementById("user_UPIID").value;
      //
      var user_email = document.getElementById("user_email").value;
      //
      var user_location = document.getElementById("user_location").value;
      //
      let order_id = JSON.parse(localStorage.getItem('order_id'));
      //
      window.sessionStorage.setItem('order_id', (order_id));
      //
      window.sessionStorage.setItem('name', (user_name));
      //
      window.sessionStorage.setItem('Email', (user_email));
      //
      window.sessionStorage.setItem('MobileNumber', (user_number));
      //
      window.sessionStorage.setItem('Amount', (out));
      //
      window.sessionStorage.setItem('Location', (profile_location));
      //
      let razorpay_amount = out;
      //
      let status = JSON.parse(localStorage.getItem('status'));
      alert('2');
      var datas = {
        upload_id: this.props.match.params.id,
        Donor_contribution: newPercent,
        Donor_AccountNumber: user_Bankac,
        Donor_IFSC: user_IFSC,
        Donor_UPI: user_UPIID,
        Donor_email: user_email,
        Donor_mobilenumber: user_number,
        Donor_Address: user_location,
        Beneficiary_userid: user_id,
        Beneficiary_name: profile_name,
        Donor_name: user_name,
        Beneficiary_email: profile_email,
        Beneficiary_mobilenumber: profile_mobileNumber,
        Beneficiary_AccountNumber: upload_BankAccount,
        Beneficiary_IFSC: upload_IFSC,
        Beneficiary_UPI: upload_UPIID,
        Order_id: order_id,
        Order_amount: razorpay_amount,
        Order_created_date: "21",
        Order_status: status,
        Dpercentageofsupport: percent,
        Payment_id: "23",
        Paymenta_amount: "24",
        Payment_created_date: "25",
        Payment_status: "26",


      };
      
      alert('3');
      var urll = "https://newtestapi.paropakari.com/api/Profile/Add_Order";


      var optionss = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          "Accept": "application/json",
          Authorization: "Bearer " + accessToken

        },
        body: JSON.stringify(datas)
      };


      try {
        const response = await fetch(urll, optionss);
        const result = await response.json();

        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));

        
        alert('4');
        if (response.ok) {
          alert('45');
          this.handleSubmitcollecttt();
          alert('5');
          // this.props.history.push( {pathname: "/Loader",
          // state: { data:{
          //   profile_email:this.props.location.state.data.profile_email,
          //   profile_location: this.props.location.state.data.profile_location,
          //   profile_mobileNumber:this.props.location.state.data.profile_mobileNumber,
          //   profile_name:this.props.location.state.data.profile_name,
          //   upload_IFSC:this.props.location.state.data.upload_IFSC,
          //   upload_UPIID:this.props.location.state.data.upload_UPIID,
          //   upload_BankAccount:this.props.location.state.data.upload_BankAccount,
          //   upload_id:this.props.location.state.data.upload_id,
          //   cf_payment_id:result.cf_payment_id
          //       } }});

        } else {
          alert('Failed!. Try Again');

          // window.location.reload();
        }

      }
      catch (error) {
        console.error(error);
      }

    }
  }

  handleSubmitcollecttt = () => {

    var percent = document.getElementById("response_pledge").value;
    
    //Suppose u want 3 number of character
    if (percent <= 100) {
      var num = document.getElementById("amount").value;
      var out = num / 100 * percent;
      
      
      

      localStorage.setItem('Amount', JSON.stringify(out));

      const newPercent = num / 100 * percent;
      this.setState({ downPaymentPercent: newPercent })
      
      

      var profile_name = document.getElementById("profile_name").value;
      //
      var profile_email = document.getElementById("profile_email").value;
      //
      var profile_mobileNumber = document.getElementById("profile_mobileNumber").value;
      //
      var status_80G = document.getElementById("status_80G").value;
      //
      var upload_BankAccount = document.getElementById("upload_BankAccount").value;
      //
      var upload_IFSC = document.getElementById("upload_IFSC").value;
      //
      var upload_UPIID = document.getElementById("upload_UPIID").value;
      //
      let order_id = JSON.parse(localStorage.getItem('order_id'));
      //
      let razorpay_amount = JSON.parse(localStorage.getItem('razorpay_amount'));
      //
      let status = JSON.parse(localStorage.getItem('status'));
      //
      if (status_80G == 0) {
        this.props.history.push({
          pathname: "/DonateSmileWithout80G",

          state: {
            data: {
              amount: newPercent,
              name: profile_name,
              status_80g: status_80G,
              mobilenumber: profile_mobileNumber,
              email: profile_email,
              bankaccount: upload_BankAccount,
              ifsc: upload_IFSC,
              order_id: order_id,
              status: status,
              upload_id: this.props.match.params.id
            }
          }
        });
      }
      else {
        this.props.history.push({
          pathname: "/DonateSmile",

          state: {
            data: {
              amount: newPercent,
              name: profile_name,
              status_80g: status_80G,
              mobilenumber: profile_mobileNumber,
              email: profile_email,
              bankaccount: upload_BankAccount,
              ifsc: upload_IFSC,
              order_id: order_id,
              status: status,
              upload_id: this.props.match.params.id
            }
          }
        });
      }
      


      // window.location.href = 'http://localhost:51827/Paropakari/Donate_a_Smile/?amount='+ newPercent + '&name=' + profile_name + '&status_80g=' + status80G +'&mobilenumber=' +profile_mobileNumber + '&email=' + profile_email + '&bankaccount=' +upload_BankAccount +'&ifsc=' + upload_IFSC ;
      var id = 9;
      //https://newtestapi.paropakari.com/api/GetProfile/SupportCause?response_message=' + response_message + '&response_pledge=' + response_pledge + '&upload_id=' + this.props.match.params.id
      // var url = 'http://localhost:51827/Paropakari/Donate_a_Smile/?amount=1000&name=Sush&status_80g=yes&mobilenumber=8548820436&email=sushmamt@gmail.com&bankaccount=sd5515151&ifsc=SBI56545455'
      // $.ajax({
      //         type: "GET",
      //         url: url,
      //         data: {}, 
      //         dataType: "html",
      //         header:{
      //           'Access-Control-Allow-Origin': '*',
      //         "Access-Control-Allow-Credentials" : "true",
      //         "Access-Control-Allow-Headers":"*",
      //         "Access-Control-Allow-Methods": "*"
      //       },
      //         success: function(data) { alert('got here with data'); 
      //         // window.location.href = 'http://localhost:51827/Event/Donation_Razorpay'
      //       },
      //         error: function() { alert('something bad happened');

      //       }
      //       });
    }
  }

  handleClick = (event) => {
    event.preventDefault();


    const data = new FormData(event.target);


    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch('https://newtestapi.paropakari.com/api/Profile/Add_Comment', {
      method: 'POST',
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken

      },
      body: data,
    });

    // alert("Added your Comment Successfully!");

    window.location.reload();

    
  }
  // Footer() {

  //   


  // let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
  //   let data = this.state;

  //   
  //     
  //   fetch(url, {
  //       method: 'GET',
  //       headers: {
  //         "Content-type": "application/json",
  //         "Accept": "application/json",

  //         "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //       },
  //       //body:JSON.stringify(data)
  //     }).then(response => response.json())
  //     .then(data => {
  //       this.setState({
  //         foo: data
  //       });
  //       
  //        // 
  //     });
  //}





  ReloadPage = () => {
    let reloadsmyactivity = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem('responsepagereload') !== null) {
      
      reloadsmyactivity = sessionStorage.getItem('responsepagereload');
      reloadsmyactivity++;
      window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
      
    }
    else {
      reloadsmyactivity = 1;
      window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
      
    }

    if (reloadsmyactivity == 1) {
      window.location.reload();
    }


  }






  DefaultMenu() {
    
    let reloads = 0;
    window.sessionStorage.setItem('reload', (reloads));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    


    

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == 'undefined') {
      GGID = this.state.googtrans.slice(4, 6);
    }
    else {
      GGID = MID;
    }

    let datatrans = GGID;

    


    localStorage.setItem("GoogleTranslate123", datatrans);

    let GID = sessionStorage.getItem('this.state.googtrans');


    


    let GIDS = JSON.parse(localStorage.getItem('this.state.googtrans'));
    
    
    let datatransID;
    if (datatrans == "en") {
      datatransID = "English";
    }
    else
      if (datatrans == "kn") {
        datatransID = "Kannada";
      }
      else
        if (datatrans == "ta") {
          datatransID = "Tamil";
        }
        else
          if (datatrans == "te") {
            datatransID = "Telugu";
          }
          else
            if (datatrans == "hi") {
              datatransID = "Hindi";
            }
            else
            if (datatrans == "es") {
              datatransID = "Spanish";
            }
            else
              if (datatrans == MID) {
                datatransID = MID;
              }


    
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Privacy Policy&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var FoodandNutrtion = typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;



          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            langID: LangID

          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;

          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;

          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var ContactUs = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition = typeof items[7] !== "undefined" ? items[7] : null;
          var CopyRight = typeof items[8] !== "undefined" ? items[8] : null;
          var Causes = typeof items[9] !== "undefined" ? items[9] : null;






          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: ContactUs,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            langID: LangID
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Educattion = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;



          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            langID: LangID,
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~On Its Way~Make a difference with pledge of~Write a message to&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses = typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter = typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Makeadifferencewithpledgeof = typeof items[8] !== "undefined" ? items[8] : null;
          var Writemessageto = typeof items[9] !== "undefined" ? items[9] : null;


          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Makeadifferencewithpledgeof: Makeadifferencewithpledgeof,
            Writemessageto: Writemessageto,
            langID: LangID
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=The creator of the cause~is responsible for providing any documents~Paropakari.com takes no responsibility for these documents~Donate a Smile~Scan and Donate with APP~Comment Here~Your Message to the Needy~Your of Support~Submit~End Date~Share&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Thecreatorcause = typeof items[0] !== "undefined" ? items[0] : null;
          var isresponsibleforproviding = typeof items[1] !== "undefined" ? items[1] : null;

          var Paropakaritakesnoresponsibility = typeof items[2] !== "undefined" ? items[2] : null;
          var DonateNow = typeof items[3] !== "undefined" ? items[3] : null;
          var ScanandDonatewithAPP = typeof items[4] !== "undefined" ? items[4] : null;
          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var YourMessagetotheNeedy = typeof items[6] !== "undefined" ? items[6] : null;
          var YourofSupport = typeof items[7] !== "undefined" ? items[7] : null;

          var Submit = typeof items[8] !== "undefined" ? items[8] : null;
          var EndDate = typeof items[9] !== "undefined" ? items[9] : null;
          var Share = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            Thecreatorcause: Thecreatorcause,
            isresponsibleforproviding: isresponsibleforproviding,
            Paropakaritakesnoresponsibility: Paropakaritakesnoresponsibility,
            DonateNow: DonateNow,
            ScanandDonatewithAPP: ScanandDonatewithAPP,
            CommentHere: CommentHere,
            YourMessagetotheNeedy: YourMessagetotheNeedy,
            YourofSupport: YourofSupport,
            Submit: Submit,
            EndDate: EndDate,
            share: Share,
            langID: LangID
          });


        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    // localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let l_id = JSON.parse(localStorage.getItem('MenuID'));
    
    let MID = event.target.value;
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;

          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var FoodandNutrtion = typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;



          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            langID: LangID

          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;

          var Complete = typeof items[1] !== "undefined" ? items[1] : null;
          var Incomplete = typeof items[2] !== "undefined" ? items[2] : null;

          var Howitwork = typeof items[3] !== "undefined" ? items[3] : null;
          var Blog = typeof items[4] !== "undefined" ? items[4] : null;

          var Contactus = typeof items[5] !== "undefined" ? items[5] : null;
          var Termsandcondition = typeof items[6] !== "undefined" ? items[6] : null;
          var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
          var Causes = typeof items[8] !== "undefined" ? items[8] : null;






          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            langID: LangID
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Educattion = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;



          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            langID: LangID,
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~On Its Way~Make a difference with pledge of~Write a message to&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses = typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter = typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Makeadifferencewithpledgeof = typeof items[8] !== "undefined" ? items[8] : null;
          var Writemessageto = typeof items[9] !== "undefined" ? items[9] : null;


          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Makeadifferencewithpledgeof: Makeadifferencewithpledgeof,
            Writemessageto: Writemessageto,
            langID: LangID
          });


        });
    }
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=The creator of the cause~is responsible for providing any documents~Paropakari.com takes no responsibility for these documents~Donate a Smile~Scan and Donate with APP~Comment Here~Your Message to the Needy~Your of Support~Submit~End Date~Share&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Thecreatorcause = typeof items[0] !== "undefined" ? items[0] : null;
          var isresponsibleforproviding = typeof items[1] !== "undefined" ? items[1] : null;

          var Paropakaritakesnoresponsibility = typeof items[2] !== "undefined" ? items[2] : null;
          var DonateNow = typeof items[3] !== "undefined" ? items[3] : null;
          var ScanandDonatewithAPP = typeof items[4] !== "undefined" ? items[4] : null;
          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var YourMessagetotheNeedy = typeof items[6] !== "undefined" ? items[6] : null;
          var YourofSupport = typeof items[7] !== "undefined" ? items[7] : null;

          var Submit = typeof items[8] !== "undefined" ? items[8] : null;
          var EndDate = typeof items[9] !== "undefined" ? items[9] : null;
          var Share = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            Thecreatorcause: Thecreatorcause,
            isresponsibleforproviding: isresponsibleforproviding,
            Paropakaritakesnoresponsibility: Paropakaritakesnoresponsibility,
            DonateNow: DonateNow,
            ScanandDonatewithAPP: ScanandDonatewithAPP,
            CommentHere: CommentHere,
            YourMessagetotheNeedy: YourMessagetotheNeedy,
            YourofSupport: YourofSupport,
            Submit: Submit,
            EndDate: EndDate,
            share: Share,
            langID: LangID
          });


        });
    }

  }










  componentDidMount() {
    window.addEventListener('load', this.submit);
    this.submit();
    window.addEventListener('load', this.comment);
    this.comment();
    window.addEventListener('load', this.like);
    this.like();
    window.addEventListener('load', this.paropakaris);
    this.paropakaris();
    window.addEventListener('load', this.Tagged);
    this.Tagged();
    window.addEventListener('load', this.Liked);
    this.Liked();
    window.addEventListener('load', this.Payin);
    this.Payin();
    window.addEventListener('load', this.ClaimDetail);
    this.ClaimDetail();

    window.addEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.ReloadPage);
    this.ReloadPage();
    window.addEventListener('load', this.ClaimAmountDetail);
    this.ClaimAmountDetail();
    window.addEventListener('load', this.getuser);
    this.getuser();
    // window.addEventListener('load', this.Footer);
    // this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    // const Script = document.createElement("script");
    // //id should be same as given to form element
    // const Form = document.getElementById('donateForm');
    // Script.setAttribute('src','https://checkout.razorpay.com/v1/payment-button.js')
    // Script.setAttribute('data-payment_button_id','pl_J4vFND8HkysKSE')
    // Form.appendChild(Script);
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"
  }




  componentWillUnmount() {
    window.removeEventListener('load', this.submit);
    this.submit();
    window.removeEventListener('load', this.comment);
    this.comment();
    window.removeEventListener('load', this.like);
    this.like();
    window.removeEventListener('load', this.Payin);
    this.Payin();
    window.removeEventListener('load', this.ClaimDetail);
    this.ClaimDetail();

    window.removeEventListener('load', this.paropakaris);
    this.paropakaris();
    window.removeEventListener('load', this.Tagged);
    this.Tagged();
    window.removeEventListener('load', this.Liked);
    this.Liked();
    window.removeEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.ReloadPage);
    this.ReloadPage();
    // window.removeEventListener('load', this.Footer);
    // this.Footer();
    window.removeEventListener('load', this.ClaimAmountDetail);
    this.ClaimAmountDetail();
    window.removeEventListener('load', this.getuser);
    this.getuser();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }

  // handleInputChange = (e) => {
  //   const { target: { name, value } } = e
  //   this.setState({ [name]: value })

  //   switch(name) {

  //     case 'downPaymentPercent' :
  //       
  //       const newAmount = parseInt(value/100 * this.state.fullPrice) // Assuming fullPrice set in state
  //       this.setState({ response_pledge: newAmount })
  //       
  //       break
  //     case 'response_pledge' :
  //     var percent = document.getElementById("response_pledge").value;
  //   var num = document.getElementById("amount").value;
  //   var out = num / 100 * percent;
  // 
  //   
  //   


  //   localStorage.setItem('Amount', JSON.stringify(out));
  //       
  //       const newPercent =num / 100 * percent ;
  //       this.setState({ downPaymentPercent: newPercent })
  //       
  //      
  //       break
  //     default:
  //       break
  //   }
  // }
  handleInputChange = (e) => {
    const { target: { name, value } } = e
    this.setState({ [name]: value })

    switch (name) {

      case 'downPaymentPercent':
        
        const newAmount = parseInt(value / 100 * this.state.fullPrice) // Assuming fullPrice set in state
        this.setState({ response_pledge: newAmount })
        
        break
      case 'response_pledge':
        var percent = document.getElementById("response_pledge").value;
        
        //Suppose u want 3 number of character
        if (percent <= 100) {
          var num = document.getElementById("amount").value;
          var out = num / 100 * percent;
          
          
          

          localStorage.setItem('Amount', JSON.stringify(out));
          
          const newPercent = num / 100 * percent;
          this.setState({ downPaymentPercent: newPercent })
          
          
          break
        }
        else {

          
          var str = document.getElementById('response_pledge').value;
          str = str.substring(0, str.length - (1, 2));
          document.getElementById('response_pledge').value = str;
        }
    }
  }

  // The 2 inputs


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    
  }

  // handleSubmitevents = (event) => {
  //   event.preventDefault();

  //   if (this.handleFormValidation()) {
  //     alert(' success')
  //     this.setState(this.initialState)
  //     
  //   }
  //   

  // };

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  render() {
    const { paropakaris } = this.state;
    const { comm } = this.state;
    const { emps, detailsss, clm, Payin, ClaimDet } = this.state;
    const { like } = this.state;
    const { Tagged } = this.state;
    const { Liked } = this.state;
    const { notify } = this.state;
    var username = JSON.parse(localStorage.username);
    var Admin_status = JSON.parse(localStorage.getItem('Admin_status'));
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() + 1;
    var mm2 = today.getMonth() + 2; //January is 0 so need to add 1 to make it 1!


    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    today = yyyy + '-' + mm + '-' + dd;
    var nextmonth = yyyy + '-' + mm2 + '-' + dd;
    var nextYear = yyyy2 + '-' + mm + '-' + dd;
    
    
    
    let { foo } = this.state;
    const { howitworks, blog, contactus, termsandcondition, causes, beneficiaries, paropakariss, amountraised,
      copyright, beaparopakari, myjourney, createacause, invite, profile, changepassword, group, logout, search,
      categories, education, foodandnutrition, health, professional, sports, socialwefare, others,
      sort, mostrecent, mostpopular, available80g, amountdesc, amountasc, complete, incomplete, enddate, tag, likess, Causefor_Id,
      comment, donateasmile, year, activity, mycauses, likedcauses, commentedcauses, pledgedcauses, HappinessMeter, share,
      Achieved, privacypolicy,
      OnItsWay,
      Makeadifferencewithpledgeof,
      Writemessageto,
      Thecreatorcause,
      isresponsibleforproviding,
      YourMessagetotheNeedy,
      YourofSupport,
      Paropakaritakesnoresponsibility,
      DonateNow,
      ScanandDonatewithAPP,
      CommentHere, Submit, EndDate, langID } = this.state;

    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          {/*/head*/}
          <div onClick={this.refreshPage}>
            <select className="form-control" style={{ width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
               <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
          </div>

          &nbsp;&nbsp; &nbsp;&nbsp;
          {/* <div id="google_translate_element" onClick={refreshPage}
  className="form-control" style={{display:'inline', width:'10%',marginTop:'0',marginLeft:'0',float:'right',width:'fit-content'}} >

   </div>
 */}

          <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
            <div className="container">
              <div className="row">

              </div>
            </div>
            <div className="navbar navbar-inverse" role="banner">
              <div className="container">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                  <a className="navbar-brand" href="#/HomePage" >
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI</h1>
                  </a>

                </div>

                <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">


                    {/* {(() => {if (langID == 'Tamil' ) {return (
       <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'40px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
       {beaparopakari.result}
       </a></li>
);
}
                 else {return(

                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
           {beaparopakari.result}
           </a></li>

);}

})()}    
         */}


                    <li className=""><a href="#/AdmLognParopakari" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>{logout.result}</a></li>



                  </ul>
                </div>

              </div>
            </div>
          </header>
          {/*/#header*/}

          <div>


            {/*/#page-breadcrumb*/}
            <section id="blog" className="" style={{ backgroundColor: 'rgb(174, 230, 219)' }}>
              <br />
              <div className="container">

                <div className="row">

                  <div className="col-md-6 col-sm-6">

                    {(() => {
                      if (Admin_status == 1) {
                        return (

                          <div className="row">
                            <form autoComplete="off" onSubmit={this.handleSubmitevents} >
                              {emps.map((emp) => (
                                // <tr key={emp.user_id}> 
                                <div className="col-sm-12 col-md-12" style={{ marginLeft: "50%", backgroundColor: "white", borderRadius: "80px", marginTop: "-10px" }}>
                                  <a href="#/PayoutList" class="btn red block circular" style={{ width: '18%', height: '20%', padding: '8px 17px', margin: '25px 289px', border: 'none', borderRadius: 4 }} >Back</a>
                                  <br />

                                  <h3 style={{ fontsize: '15px', textAlign: "center" }}><a style={{ fontsize: '20px', textDecoration: 'none', color: 'black' }}><b>{emp.upload_title}</b></a></h3>
                                  <input type="hidden" id="upload_Title" name="upload_Title" value={emp.upload_title} />
                                  <div className="row" style={{
                                    marginLeft: "124px", width: "464px",
                                    border: "1px solid #000"
                                  }}>
                                    <div className="col-lg-5"><p>{emp.category_name}</p></div>

                                    <div className="col-lg-7"><p style={{ marginLeft: "106px" }}>{emp.upload_place}</p></div>


                                  </div>


                                  <div className="row" style={{
                                    marginLeft: "124px", width: "464px",
                                    border: "1px solid #000", marginTop: "2px"
                                  }}>
                                    <div className="col-lg-4"><p>{emp.profile_name}</p></div>

                                    <div className="col-lg-7"><p style={{ marginLeft: "147px" }}>{emp.created_date}</p></div>
                                    <div className="col-lg-1"><p></p></div>

                                  </div>



                                  <div className="post-content overflow">


                                    <p style={{ width: '700px', textAlign: "center", fontSize: "17px" }}><b>{emp.upload_description}</b></p>
                                    <div className="row" style={{ marginLeft: "130px" }}>
                                      <div className="col-lg-6">
                                        <b className="notranslate" >{enddate.result}

                                        </b>
                                      </div>

                                      <div className="col-lg-6" >
                                        <p>&nbsp;{emp.required_by}</p>
                                      </div>

                                    </div>

                                  </div>

                                  <div>
                                    {ClaimDet.map((clm) => (
                                      <div>

                                        <input type="hidden" value={clm.upload_id} id="upload_id" name="upload_id" />

                                        <div className="row" style={{ marginLeft: "130px" }}>
                                          <div className="col-lg-6">
                                            <b>Amount</b>
                                          </div>

                                          <div className="col-lg-6" >
                                            <p> {clm.claim_amount}</p>
                                          </div>

                                        </div>
                                        <br />


                                        <div className="row" style={{ marginLeft: "130px" }}>
                                          <div className="col-lg-6">
                                            <b>Email</b>
                                          </div>

                                          <div className="col-lg-6" >
                                            <p> {clm.benef_Email}</p>
                                          </div>

                                        </div>
                                        <br />

                                        <div className="row" style={{ marginLeft: "130px" }}>
                                          <div className="col-lg-6">
                                            <b>Contact</b>
                                          </div>

                                          <div className="col-lg-6" >
                                            <p> {clm.benef_MobileNumber}</p>
                                          </div>

                                        </div>
                                        <br />

                                        <div className="row" style={{ marginLeft: "130px" }}>
                                          <div className="col-lg-6">
                                            <b>Created</b>
                                          </div>

                                          <div className="col-lg-6" >
                                            <p> {clm.claim_date}</p>
                                          </div>

                                        </div>
                                        <br />

                                        <div className="row" style={{ marginLeft: "130px" }}>
                                          <div className="col-lg-6">
                                            <b>Status</b>
                                          </div>

                                          <div className="col-lg-6" >
                                            <p>

                                              <select id="Payout_status" name="Payout_status"
                                                value={this.state.payout_status}
                                                onChange={this.handleChange} class="text-field-outlined"
                                              >

                                                {(() => {
                                                  if (clm.payout_status === 'processed') {
                                                    return (
                                                      <option value={clm.payout_status} style={{ color: '#6eff6e' }}>
                                                        {clm.payout_status}
                                                      </option>

                                                    );
                                                  }
                                                  else {
                                                    return (
                                                      <option value={clm.payout_status} style={{ color: 'red' }}>
                                                        {clm.payout_status}
                                                      </option>

                                                    );
                                                  }

                                                })()}

                                                <option value="processing">processing</option>
                                                <option value="processed">processed</option>
                                              </select>
                                              <input type="hidden" value={clm.payout_status} id="P_status" name="P_status" />
                                            </p>
                                          </div>

                                        </div>
                                        <br />

                                        <div className="row" style={{ marginLeft: "130px" }}>
                                          <div className="col-lg-6">
                                            <b>Transaction ID</b>
                                          </div>

                                          <div className="col-lg-6 " >
                                            <p>
                                              {/* {clm.transaction_id}<br/> */}
                                              <input type="text" defaultValue={clm.transaction_id} name="Transaction_id" class="text-field-outlined" id="Transaction_id" />
                                              <input type="hidden" value="none" id="Transactionid" name="Transactionid" />
                                            </p>
                                          </div>

                                        </div>
                                        <br />

                                        <div className="row" style={{ marginLeft: "130px" }}>
                                          <div className="col-lg-6">
                                            <b>Payout Date</b>
                                          </div>

                                          <div className="col-lg-6" >
                                            <p>
                                              {/* {clm.payout_date} */}
                                              <input defaultValue={clm.payout_date} type="text" name="Payout_date" min="1920-01-01"
                                                onFocus={(e) => e.target.type = 'date'} id="Payout_date" dateFormat="yyyy-MM-dd"
                                                max={today} class="text-field-outlined" />

                                              <input type="hidden" value="2021-06-24" name="P_date" id="P_date" />
                                            </p>
                                          </div>

                                        </div>
                                        <button class="btn green block circular" style={{ width: '18%', height: '20%', padding: '8px 17px', margin: '25px 295px', border: 'none', borderRadius: 4, color: 'white' }}>Update</button>


                                      </div>

                                    ))}

                                  </div>



                                </div>

                              ))}

                            </form>
                          </div>



                        );
                      }


                    })()}
                  </div>
                  <div className="col-md-6 col-sm-6 notranslate" style={{ marginTop: "5px" }}>
                    {/* tablr design */}
                    <div className="row">
                      <div style={{ overflowX: "auto" }}>



                        <table class="table" style={{ border: "1px solid #000080 ", width: "95%", marginLeft: "30px" }}>

                          <thead style={{ backgroundColor: "darkblue", color: "white" }}>
                            <tr>
                              <th scope="col">Upload ID</th>
                              <th scope="col">Donor Name</th>
                              <th scope="col">% of Support</th>
                              <th scope="col">Contribution</th>
                              <th scope="col">Payment Date</th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Mode of Payment</th>

                            </tr>
                          </thead>
                          <tbody>
                            {Payin.map((p) => (
                              <tr className="hovercolr" >

                                <td>{p.upload_id}</td>
                                <td>{p.donor_name}</td>
                                <td>{p.dpercentageofsupport}</td>
                                <td>{p.donor_contribution}</td>
                                <td>{p.payment_created_date}</td>
                                <td>{p.payment_status}

                                </td>
                                <td>{p.donorModeofPayment}

                                </td>

                              </tr>
                            ))}
                          </tbody>




                        </table>


                        <div>
                        </div>
                      </div>
                    </div>
                    <div className="media-body">
                    </div>

                  </div>


                </div>
                <div className="sidebar-item categories" style={{ float: 'right', position: 'fixed' }}>

                  {/* <ul className="nav navbar-stacked" style={{float:'right'}}>
                <li><a href="#/InviteFriend">Invite</a></li>
                <li><a href="#/HomePage">Be a Paropakari</a></li>
                
              </ul> */}
                </div>




              </div>

            </section>



          </div>

          {/*/#blog*/}
          <div style={{ backgroundColor: '#664712' }}>
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container notranslate">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p style={{ marginLeft: '45px' }}>{copyright.result}&nbsp; |&nbsp;<a href="#/Contactuslogin" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicyLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandConditionLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white', marginLeft: '72px' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>

          </div>
          {/*/#footer*/}
        </div>
      </div>

    )
  }
}