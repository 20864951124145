import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import loading from "../assets/images/loading.gif";
import "../assets/css/bootstrap.min.css";
import { BASE_URLs } from '../Constants/BaseURL';
// Import css files
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        right: "-4px",
      }}
      onClick={onClick}
    >
      <img
        src="../assets/images/home/slider/back5.png"
        height="25px"
        width="25px"
        style={{ borderRadius: "25px" }}
      ></img>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        left: "8px",
        zIndex: "+1",
      }}
      onClick={onClick}
    >
      {/* <img src="../assets/images/home/arr.png" height="70px" width="70px"></img> */}
      <img
        src="../assets/images/home/slider/back3.png"
        height="25px"
        width="25px"
        style={{ borderRadius: "25px" }}
      ></img>
    </div>
  );
}
function SliderTest() {
  const config = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          // autoplay: true,
        },
      },
    ],
  };
  const [data, setData] = useState([]);
  const [sliderRef, setSliderRef] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    
    loadData();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loadData = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    await fetch(
      "https://newtestapi.paropakari.com/api/Authenticate/Get_LandingPosts",
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then((response) => response.json())
      .then((receivedData) => setData(receivedData));
  };

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
    }),
  };

  const [settings, setSettings] = useState(config);

  const products = [
    {
      img: "/images/product1.jpg",
      title: "Dolore magna",
      text: "Lorem ipsum dolor sit amet elit.",
    },
    {
      img: "/images/product2.jpg",
      title: "Eget est lorem",
      text: "Lorem Ipsum adipiscing elit ipsum.",
    },
    {
      img: "/images/product3.jpg",
      title: "Tempus imperdiet",
      text: "Orci porta non pulvinar neque laoreet.",
    },
    {
      img: "/images/product4.jpg",
      title: "Mattis rhoncus",
      text: "Bibendum neque egestas congue quisque.",
    },
    {
      img: "/images/product5.jpg",
      title: "Odio ut enim",
      text: "Mattis rhoncus urna neque viverra justo.",
    },
  ];

  const onChangeCenterMode = (e) => {
    if (e.target.checked) {
      setSettings({
        ...config,
        centerMode: true,
        centerPadding: "50px",
      });
    } else {
      setSettings(config);
    }
  };

  const NewStyle = {
    backgroundColor: "white",
    width: "87%",
    height: "100px",
    overflow: "hidden",
    borderRadius: "0px 0px 20px 20px",
  };

  return (
    <div className="App">
      {data.length == 0 ? (
        <img className="loading-img" src={loading} />
      ) : (
        <Slider {...settings}>
          {data.map((x, i) => {
            return (
              <div className="container">
                <div key={i} className="img-card">
                  {(() => {
                    if (
                      x.file_type == ".png" ||
                      x.file_type == ".jpg" ||
                      x.file_type == ".jfi" ||
                      x.file_type == ".jfif" ||
                      x.file_type == ".webp" ||
                      x.file_type == ".JPG" ||
                      x.file_type == ".jpeg"
                    ) {
                      return (
                        <a>
                          {" "}
                          <PhotoProvider
                          // speed={() => 800}
                          // easing={(type) =>
                          //   type === 2
                          //     ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                          //     : "cubic-bezier(0.34, 1.56, 0.64, 1)"
                          // }
                          >
                            <PhotoView src={x.file_name}>
                              <img
                                className="responsive"
                                alt="Causes"
                                src={x.file_name}
                              />
                            </PhotoView>
                          </PhotoProvider>
                        </a>
                      );
                    } else if (
                      x.file_type == ".pdf"
                      //|| x.file_type == ".docx" ||
                      // x.file_type == ".doc" ||
                      // x.file_type == ".xlsx"
                    ) {
                      return (
                        <a>
                          {/* <div
                            id="Iframe-Master-CC-and-Rs"
                            class="set-margin set-padding set-border set-box-shadow center-block-horiz"
                          >
                            <div
                              class="responsive-wrapper
                                         responsive-wrapper-wxh-572x612"
                              style={{
                                WebkitOverflowScrolling: "touch",
                                // overflow: "auto",
                              }}
                            > */}
                          {/* <iframe
                                className="img-responsive"
                                // src={x.file_name + "#toolbar=0"}
                                src={"https://docs.google.com/gview?url=" + x.file_name + "&embedded=true"}
                                autoplay="0"
                                width="100%"
                                height="300"
                              /> */}

                          <embed
                            src={x.file_name + "#toolbar=0"}
                            value={x.file_name + "#toolbar=0"}
                            title={x.file_type}
                            scrolling="no"
                            className="img-responsive"
                            id="indexcardimg"
                            height="300"
                            // style={{width:"25vw",height:"40vh"}}
                          ></embed>
                          {/* </div>
                          </div> */}
                        </a>
                      );
                    } else if (
                      x.file_type == ".docx" ||
                      x.file_type == ".doc"

                      // || x.file_type == ".xlsx"
                    ) {
                      return (
                        <a>
                          {/* <div
                            id="Iframe-Master-CC-and-Rs"
                            class="set-margin set-padding set-border set-box-shadow center-block-horiz"
                          >
                            <div
                              class="responsive-wrapper
                                         responsive-wrapper-wxh-572x612"
                              style={{
                                WebkitOverflowScrolling: "touch",
                                // overflow: "auto",
                              }}
                            > */}
                          <iframe
                            className="responsive"
                            // src={x.file_name + "#toolbar=0"}
                            src={
                              "https://docs.google.com/gview?url=" +
                              x.file_name +
                              "&embedded=true"
                            }
                            // autoplay="0"
                            // width="100%"
                            height="300"
                          />

 

                          {/* <embed
                                src={x.file_name + "#toolbar=0"}
                                value={x.file_name + "#toolbar=0"}
                                title=""
                                scrolling="no"
                                className="img-responsive"
                                style={{ width: "100%", height: "300" }}
                              ></embed> */}
                          {/* </div> */}
                          {/* </div> */}
                        </a>
                      );
                    } else if (x.file_type == ".xlsx"||x.file_type == ".xls") {
                      return (
                        <a>
                          {/* <div
                            id="Iframe-Master-CC-and-Rs"
                            class="set-margin set-padding set-border set-box-shadow center-block-horiz"
                          >
                            <div
                              class="responsive-wrapper
                                         responsive-wrapper-wxh-572x612"
                              style={{
                                WebkitOverflowScrolling: "touch",
                                // overflow: "auto",
                              }}
                            > */}
                          <iframe
                            className="responsive"
                            // src={x.file_name + "#toolbar=0"}

                            // <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=your_excel_file_url" width="100%" height="600px" frameborder="0">

                            src={
                              "https://view.officeapps.live.com/op/embed.aspx?src=" +
                              x.file_name //+  "&embedded=true"
                            }
                            // autoplay="0"
                            // width="100%"
                            height="300"
                          />

                          {/* <embed
                                src={x.file_name + "#toolbar=0"}
                                value={x.file_name + "#toolbar=0"}
                                title=""
                                scrolling="no"
                                className="img-responsive"
                                style={{ width: "100%", height: "300" }}
                              ></embed> */}
                          {/* </div> */}
                          {/* </div> */}
                        </a>
                      );
                    } else if (
                      x.file_type == ".mp4" ||
                      x.file_type == ".webm"
                    ) {
                      return (
                        <a>
                          <video
                            className="responsive"
                            width="89%"
                            height="290px"
                            controls
                            style={{background:"black"}}
                          >
                            <source src={x.file_name} type="video/mp4" />
                          </video>
                          {/* <embed src={(x.file_name + "#toolbar=0")} value={x.file_name + "#toolbar=0"} title="" scrolling="no" className="img-responsive" style={{ top: "0", left: "0", width: "100%", backgroundSize: "cover" }}></embed> */}
                        </a>
                      );
                    } else {
                      return (
                        <a>
                          {/* <iframe
                            src={"https://docs.google.com/gview?url=" + x.file_name + "&embedded=true"}
                            autoplay="0"
                            className="img-responsive"
                          /> */}
                          <embed
                            src={x.file_name + "#toolbar=0"}
                            value={x.file_name + "#toolbar=0"}
                            title=""
                            scrolling="no"
                            className="img-responsive"
                            style={{width:"25vw",height:"40vh"}}
                            
                          ></embed>
                        </a>
                      );
                    }
                  })()}
                  {/* class='slick-card-body' */}
                  <div
                    style={{
                      backgroundColor: "white",
                      width: isMobile ? "87%" : "88%",
                      height: "100px",
                      overflow: "hidden",
                      borderRadius: "0px 0px 20px 20px",
                    }}
                  >
                    <div className="slick-card-title">
                      <a
                        href="#/Login"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {" "}
                        {x.category_name}
                      </a>
                    </div>
                    <div className="slick-card-text">
                    <a
  href="#/Login"
  style={{ textDecoration: "none", color: "black" }}
>
  {x.upload_title.length > 15 ? `${x.upload_title.slice(0, 30)}...` : x.upload_title}
</a>

                      <br />
                      <a className="slick-card-btn" href="#/Login">
                        Donate a Smile <span>&#128578;</span>
                      </a>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  &nbsp;
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
}

export default SliderTest;
