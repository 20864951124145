import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Script from 'react-load-script'
import { BASE_URLs } from '../Constants/BaseURL';
//import SideBars from './SideBars.js';


export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id : this.props.match.params.id,
      pno: '',
      pswd: '',
      foo: [],
      Menu: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      copyright_new:[],
      paropakari_new:[],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      welcome: [],
      usernames: [],
      passwords: [],
      forgotpassword: [],
      signin: [],
      donthaveacc: [],
      signup: [],
      entercaptchvalue: [],
      enter: [],
      langID: [],
      or: [],
      email: [],
      mobilenumber: [],
      submit: [],
      privacypolicy: [],
      Captchisnotmatch: [], enteryouremailormob: [],
      PleaseenteryourregisteredNumberorEmail: [],
      OTPsendtoyourRegisteredNumberorEmails:[],
      EmailAddressPhonenumberisnotvalid: [],
      movedown: true,
    };
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('username')
    localStorage.removeItem('user')

    localStorage.clear();
    window.location.href = "#/Login";

  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  cipher = (salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
  };


  onSignInSubmit = async event => {
    event.preventDefault();
    let user_captcha = document.getElementById('user_captcha_input').value;
    var email = document.getElementById('pno');
    var email1 = document.getElementById('pno').value;
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    if (email.value == "") {
      alert(this.state.enteryouremailormob.result);
    }
    else if (!mailFormat.test(email.value)) {
      alert(this.state.PleaseenteryourregisteredNumberorEmail.result?this.state.PleaseenteryourregisteredNumberorEmail.result:'Email Adress or Mobile Number is Not Valid. Provide a Valid Email or Mobile Number');
      //alert("Email address or mobile number is doesn't exist. Go to signup");
      return false;
    }
    else if (validateCaptcha(user_captcha) === true) {



      // alert('Captcha Matched');

      var body = {
        password: this.state.password,
        username: this.state.username
      };


      const data = new FormData(event.target);
//data.append('pno','8870581524');


      const url = "https://newtestapi.paropakari.com/api/Authenticate/ForgotOTP?pno=" +email1;

      const options = {
        method: "POST",
        headers: {
          Accept: 'application/json',
          "Content-type": "application/json",
          'Access-Control-Allow-Origin':'*',
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        body: data
      };
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        
        // localStorage.setItem('OTP', JSON.stringify(result.otp));

       

        if (response.ok) {
          let myCipher = this.cipher('mySecretSalt');
          // localStorage.setItem('otp', JSON.stringify(data.otp));
          localStorage.setItem('OTP', myCipher(result.otp));
          localStorage.setItem('Pno', JSON.stringify(result.pno));
          
          var page = {
            pathname: '#/ResetOTP',
            state: {
              data: {
                password: this.state.password,
                username: this.state.username,
              }
            }
          }

          alert(this.state.OTPsendtoyourRegisteredNumberorEmails.result)
          window.location.href = "#/ResetOTP";
        }
        if (result.message == "please enter your registered number or email") {
          
          alert(this.state.PleaseenteryourregisteredNumberorEmail.result);
          

        }
      } catch (error) {
        console.error(error);
      }
    }


    else {
      document.getElementById('user_captcha_input').value=""
      alert(this.state.Captchisnotmatch.result ? this.state.Captchisnotmatch.result : 'Please enter valid captcha');
    }
  }


  onSubmitOTP = (e) => {
    e.preventDefault()

    const code = this.state.otp
    
    let otp = JSON.parse(localStorage.getItem('OTP'));
    if (code == otp) {

      var page = {
        pathname: '#/Reset',
        pno: this.state.pno,

      }
      this.props.history.push(page)
      // alert("User is verified");
    }
  }

  Footer() {

    


  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }

  DefaultMenu() {
    //   

    //  let l_id = JSON.parse(localStorage.getItem('menuid'));
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));

    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    
    //    
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Or~Email~Mobile Number~Submit~Privacy Policy~Please enter valid captcha~OTP sent to your registered number or email~Please enter your registered Number or Email&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      //Email Adress or Mobile Number is Not Valid. Provide a Valid Email or Mobile Number
      //~Captch is not match~Please enter your registered Number or Email~Email Adress or Mobile Number is Not Valid. Provide a Valid Email or Mobile Number
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var PrivacyPolicy = typeof items[7] !== "undefined" ? items[7] : null;
          var Captchisnotmatch = typeof items[8] !== "undefined" ? items[8] : null;
          var OTPsendtoyourRegisteredNumberorEmails = typeof items[9] !== "undefined" ? items[9] : null;
          var PleaseenteryourregisteredNumberorEmail= typeof items[10] !== "undefined" ? items[10] : null;
          //var EmailAddressPhonenumberisnotvalid= typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            privacypolicy: PrivacyPolicy,
            Captchisnotmatch: Captchisnotmatch,
            OTPsendtoyourRegisteredNumberorEmails: OTPsendtoyourRegisteredNumberorEmails,

            PleaseenteryourregisteredNumberorEmail:PleaseenteryourregisteredNumberorEmail
            //EmailAddressPhonenumberisnotvalid:EmailAddressPhonenumberisnotvalid
          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,

            langID: LangID
          });


        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Enter Your Email or Mobile Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Enteryouremailormob = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            enteryouremailormob: Enteryouremailormob,


            langID: LangID
          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome!~Username~Password~Forgot password~Login~Dont have an account Please&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,

            langID: LangID
          });


        });
    }
    
 if (MID != null) {
  let url =
    "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
  let user = JSON.parse(localStorage.getItem("user"));
  const accessToken = user;
  
  fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + accessToken,
      "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
    }
  })
    .then((response) => response.json())
    .then((data) => {
      this.setState({
        Details: data
      });
      
      const longArray = data;
      const size = 1;
      var ary = data;
      // localStorage.setItem('menuid', 'English');
      const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
        .map(function () { return this.splice(0, size) }, longArray.slice());
      var items = data;

      var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
      var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
    
      var LangID = MID;
      window.sessionStorage.setItem('translate', (LangID));
      localStorage.setItem('MenuID', JSON.stringify(LangID));
      this.setState({
        copyright_new: copyright_new,
        paropakari_new: paropakari_new,
        
      });


      // 
    });
}
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Or~Email~Mobile Number~Submit~Privacy Policy~Please enter valid captcha~OTP sent to your registered number or email~Please enter your registered Number or Email&lang=" + MID;

      //Email Adress or Mobile Number is Not Valid. Provide a Valid Email or Mobile Number
      let user = JSON.parse(localStorage.getItem("user"));
      //~Captch is not match~Please enter your registered Number or Email~Email Adress or Mobile Number is Not Valid. Provide a Valid Email or Mobile Number
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var PrivacyPolicy = typeof items[7] !== "undefined" ? items[7] : null;
          var Captchisnotmatch = typeof items[8] !== "undefined" ? items[8] : null;
          var OTPsendtoyourRegisteredNumberorEmails = typeof items[9] !== "undefined" ? items[9] : null;
          var PleaseenteryourregisteredNumberorEmail= typeof items[10] !== "undefined" ? items[10] : null;
          // var EmailAddressPhonenumberisnotvalid= typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            privacypolicy: PrivacyPolicy,
            Captchisnotmatch: Captchisnotmatch,
            OTPsendtoyourRegisteredNumberorEmails:OTPsendtoyourRegisteredNumberorEmails,
            PleaseenteryourregisteredNumberorEmail:PleaseenteryourregisteredNumberorEmail
            // EmailAddressPhonenumberisnotvalid:EmailAddressPhonenumberisnotvalid
          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,

            langID: LangID
          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome!~Username~Password~Forgot password~Login~Dont have an account Please&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,

            langID: LangID
          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Enter Your Email or Mobile Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Enteryouremailormob = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            enteryouremailormob: Enteryouremailormob,


            langID: LangID
          });


        });
    }
    
 if (MID != null) {
  let url =
    "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
  let user = JSON.parse(localStorage.getItem("user"));
  const accessToken = user;
  
  fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + accessToken,
      "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
    }
  })
    .then((response) => response.json())
    .then((data) => {
      this.setState({
        Details: data
      });
      
      const longArray = data;
      const size = 1;
      var ary = data;
      // localStorage.setItem('menuid', 'English');
      const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
        .map(function () { return this.splice(0, size) }, longArray.slice());
      var items = data;

      var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
      var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
    
      var LangID = MID;
      window.sessionStorage.setItem('translate', (LangID));
      localStorage.setItem('MenuID', JSON.stringify(LangID));
      this.setState({
        copyright_new: copyright_new,
        paropakari_new: paropakari_new,
        
      });


      // 
    });
}
    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }


          setCookie('googtrans', datatransIDs, 30);
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());

    
    return (
      <div>{newArray}</div>
    )
  }


  componentDidMount() {
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    loadCaptchaEnginge(6);
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"
  }
  componentWillUnmount() {
    loadCaptchaEnginge(6);
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
  render() {
    let { foo, Menu } = this.state;
    const currentYear = new Date().getFullYear();
    const { newArray, howitworks, beaparopakari, whatwedo, blog, privacypolicy,
      enteryouremailormob, login, indiaexpensless, ourstory, oppertunitybringsmile, contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, whatwedodesc, langID, welcome, usernames, passwords, forgotpassword, signin, donthaveacc, signup, entercaptchvalue, enter, or, email, mobilenumber, submit,OTPsendtoyourRegisteredNumberorEmails,
      PleaseenteryourregisteredNumberorEmail, copyright_new,
      paropakari_new } = this.state;
    return (
      <div id="myElement">
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>

        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }} className="notranslate">
        
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          <link href="./assets/css/Register.css" rel="stylesheet" />
          {/*[if lt IE 9]>
            
            
        <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />

          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;

          <div>

            <div className="mag notranslate">
              <div className="pro-nav">
                <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                <div className="pro-nav-header">
                  <div className="pro-nav-title notranslate">
                    <a className="navbar-brand" href="#/Index1">
                      <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div className="pro-nav-btn">
                  <label for="pro-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? <>
                  <div className="pro-nav-links pro-side">
                    <p className='pro-center'>
                      <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx">
                      <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-menu">{login.result}</a>
                    </div>
                  </div>
                </> : langID == "Hindi" ? <>
                  <div className="pro-nav-links pro-side">
                    <p className='pro-center'>
                      <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx">
                      <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-hindi-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-hindi-menu">{login.result}</a>
                    </div>
                  </div>
                  </> : langID == "Spanish" ? <>
                  <div className="pro-nav-links pro-side">
                    <p className='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx">
                      <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{login.result}</a>
                    </div>
                  </div>
                </> : langID == "Kannada" ? <>
                  <div className="pro-nav-links pro-side">
                    <p className='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx">
                      <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{login.result}</a>
                    </div>
                  </div>
                </> : langID == "Tamil" ? <>
                  <div className="pro-nav-links pro-side">
                    <p className='pro-center'>
                      <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-tamil-boxx">
                      <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-tamil-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-tamil-menu">{login.result}</a>
                    </div>
                  </div>
                </> : <>
                  <div className="pro-nav-links pro-side">
                    <p className='pro-center'>
                      <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx">
                      <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-telugu-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-telugu-menu">{login.result}</a>
                    </div>
                  </div>
                </>}
              </div>
            </div>

            {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>    

         <div className="navbar navbar-inverse" role="banner">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="sr-only">Toggle navigassstion</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>

                    <a className="navbar-brand" href="#/Index1">
                    	<h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo"style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI
                            
                        </h1>
                    </a>
                    
                </div>
                <div className="collapse navbar-collapse">
                   
       <ul className="nav navbar-nav navbar-right">

                    


<li id="paro" className="active">
{(() => {if (langID == 'Tamil' ) {return (
  <a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'15px',marginLeft:'-195px',fontSize:10,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a>

);
}
else {return(

<a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a>

);}

})()} 
 

</li>

      <li>
      {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 
      
        
        </li> 
  <li >
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}
    
</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}
    
    </a>

);}

})()} 
    </li> 
  <li>
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{blog.result}</a>

);}

})()} 
  
    
    </li>         
                     
  <li className="active">
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{login.result}</a>

);}

})()} 

    
    
    </li>                    
</ul>
                </div>
               
            </div>
        </div>
</header> */}

            {/*/#header*/}

            {/*/#action*/}

            <section id="blog" className={this.state.movedown == true ? "padding-top padding-bottom new-padding" : "padding-top padding-bottom new-padding home-Tiptop"} style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}>

<div className="container " id="containerid">

<div className="row">

  <div id="rcont" className="rcont">

    <div id="login" className="form sign-in" style={{ backgroundColor: '#a5d9cf' }}>
                      <form role="form" onSubmit={this.onSignInSubmit} >

                        <h2 style={{ color: 'black' }}>{welcome.result}</h2>

                        <input name="__RequestVerificationToken" type="hidden" defaultValue="PdlsOftvA33cACXYmdfrToI7ZxCMY85gro9hMBmX8g3C0ljb1Evh9ZzfgQAXal5wawUEVJy_BuahRFspEFtMa-qiBIxzmCIk15gr0b5UzpY1" />

                        <label>
                          <span>{mobilenumber.result} &nbsp; {or.result} &nbsp; {email.result}</span>
                          <input data-val="true" value={this.state.pno} onChange={this.handleChange}autoComplete="off" placeholder={enter.result}
                            id="pno" maxLength={50} name="pno" type="text" />
                        </label>

                        <br />
                        <center>
                          <div className="Captcha" style={{ marginTop: '2%' }}>
                            <LoadCanvasTemplate />
                          </div>

                          <div className="k-row" >
                            <input id="user_captcha_input" name="user_captcha_input" autoComplete="off" style={{ width: '50%', color: 'black',fontSize:'16px' }} type="text" placeholder={entercaptchvalue.result}></input>
                          </div>
                        </center>
                        <div id="success" />
                        {(() => {
                          if (langID == "English") {
                            return (
                              <button type="submit" id="Reg" className="submit" onclick={this.onSubmitOTP}>{submit.result}</button>
                            );
                          }
                          else if (langID == 'Telugu') {
                            return (

                              <button type="submit" id="Reg" className="submit" onclick={this.onSubmitOTP}>{submit.result}</button>
                            );
                          }
                          else if (langID == 'Hindi') {
                            return (

                              <button type="submit" id="Reg" className="submit" onclick={this.onSubmitOTP}>{submit.result}</button>
                            );
                          }
                          else if (langID == 'Kannada') {
                            return (

                              <button type="submit" id="Reg" className="submit" onclick={this.onSubmitOTP}>{submit.result}</button>
                            );
                          }
                          else if (langID == 'Spanish') {
                            return (

                              <button type="submit" id="Reg" className="submit" onclick={this.onSubmitOTP}>{submit.result}</button>
                            );
                          }


                          else {
                            return (

                              <button type="submit" id="Reg2" className="submit" onclick={this.onSubmitOTP}>{submit.result}</button>
                            );
                          }

                        })()

                        }

                        <div className="tnr1" id="tnr1">
                          {/* <center>  <a href="#/Register" className="img__btn m--up" style={{ textDecoration: 'none', color: 'black', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px' }}>  {signup.result}</a></center> */}
                        </div>
                      </form>
                    </div>
                    <div id="rsub-cont" className="sub-rcont">

                      <div id="rimgsub-cont" className="img">

                        <div className="img__text m--up">

                          <h3></h3>
                        </div>
                        <div className="img__text m--in">


                        </div>
                        <div className="tnr" id="tnr">


                          <span className="m--in" ></span>
                        </div>
                      </div>


                      <div className="form sign-up">
                        <h2></h2>
                        <label>
                          <span></span>
                          <input type="text" />
                        </label>
                        <label>
                          <span></span>
                          <input type="email" />
                        </label>
                        <label>
                          <span></span>
                          <input type="password" />
                        </label>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <div style={{ backgroundColor: '#f2dba8' }}>
                <footer id="footer" style={{ color: 'white' }}>
                  <br />
                  <div className="container">
                    <div className="copyright">

                      <div className="row col-lg-12">

                        <div className="col-lg-9 mb-6">
                          {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                          <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result}&nbsp; |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                        </div>
                        <div className="col-lg-3 mb-3">

                          <div className="social-icons" style={{ color: 'white' }}>
                            <ul className="nav nav-pills">
                              <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                              {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                              <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                              <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                              <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="containerss">

                  </div>
                </footer>
              </div>
            </div>
            {/*/#footer*/}
          </div>
        </div>
      </div>
    )
  }
}