import React, { Component } from 'react'
import { BASE_URLs } from '../Constants/BaseURL';
export default class LogoutLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
      AreyousureLogout: [],
    };
  }

  handleLogout = () => {
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }

    let url =
      "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Are you sure you want to Logout?&lang=" + MID;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }
    })
      .then((response) => response.json())
      .then((data) => {

        var items = data;        
        var AreyousureLogout = typeof items[0] !== "undefined" ? items[0] : null;

        var LangID = MID;
        window.sessionStorage.setItem('translate', (LangID));
        localStorage.setItem('MenuID', JSON.stringify(LangID));

        this.setState({
          AreyousureLogout: AreyousureLogout
        }, () => {
          let confirmLogout = window.confirm(this.state.AreyousureLogout.result);

          if (confirmLogout) {
            window.location.href = '#/Login'
          }
        }
        );
      });
  }

  render() {

    return (
      <li className="hover-text">
        <a onClick={this.handleLogout}>
          <b>{this.props.logoutResult}</b>
        </a>
      </li>
    )
  }
}