import React, { Component } from "react";
import Script from "react-load-script";
import Cookies from "js-cookie";
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from "../Constants/BaseURL";
import { Email } from "@material-ui/icons";
export default class Friends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      pno: "",
      pswd: "",
      cpswd: "",
      inviteFriendID: "",
      name: "",
      mobile: "",
      flag: "",
      created_Date: "",
      emps: [],
      inviteuser: [],
      notify: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      requiredforr: [],
      self: [],
      familyfriends: [],
      others: [],
      beneficiaryname: [],
      submit: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [],
      donateasmile: [],
      privacypolicy: [],
      invitefriends: [],
      namee: [],
      emails: [],
      mobilenumber: [],
      friends: [],
      langID: [],
      InvitedSuccessfully: [],
      Inviting: [],
      formErrors: {},
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
      myFriends: [],
      invitedFriends: [],
      invitedFriendsList: [],
      inviteagain: [],
      norecords: [],
      wanttodelete: [],
      deletee: [],
      checked:1
    };
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login",
    };
    this.props.history.push(page);
  };
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  submit = () => {
    let url = "https://newtestapi.paropakari.com/api/Invite/Get_Invites";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          emps: data,
        });
      });
  };

  getInviteUser = () => {
    let url = "https://newtestapi.paropakari.com/api/Invite/GetInviteuser";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          inviteuser: data,
        });
      });
  };

  notify = () => {
    let url =
      "https://newtestapi.paropakari.com/api/Profile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });
      });
  };
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    localStorage.clear();
    window.location.href = "#/Login";
  };

  handleInvite = async (name, email, mobilenumber) => {
    const { upload_id } = this.state;
    const { InvitedSuccessfully } = this.state;
    // alert(name+email+mobilenumber);
    // event.preventDefault();
    //   var uid = document.getElementById('upload_id');
    // alert("uploadId"+uploadid+tableid+statustype)

    //  const data = new FormData(event.target);

    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    const url =
      "https://newtestapi.paropakari.com/api/Invite/InviteAgain?name=" +
      name +
      "&email=" +
      email +
      "&mobilenumber=" +
      mobilenumber;
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + accessToken,
      },
    };

    // var dist = document.getElementById('upload_id');
    //
    //           this.setState(this.initialState)
    const response = await fetch(url, options);
    const result = await response.json();

    if (response.ok) {
      let lang = localStorage.getItem("MenuID");
      if (lang != null) {
        lang = lang.replace(/^"(.*)"$/, "$1"); // Removes surrounding quotes
      }

      let ConLang;
      if (lang === "Tamil") {
        // alert("ta");
        ConLang = "அழைப்பிதழ் வெற்றிகரமாக அனுப்பப்பட்டது";
      } else if (lang === "Telugu") {
        //  alert("te");
        ConLang = "ఆహ్వానం విజయవంతంగా పంపబడింది";
      } else if (lang === "Hindi") {
        //  alert("hi");
        ConLang = "आमंत्रण सफलतापूर्वक भेजा गया";
      } else if (lang === "Spanish") {
        //  alert("hi");
        ConLang = "Invitación enviada exitosamente";
      } else if (lang == "Kannada") {
        //  alert("karan");
        ConLang = "ಆಹ್ವಾನವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ";
      } else if (lang === "English") {
        // alert("English");
        ConLang = "Invitation sent successfully";
      } else {
        // alert("English");
        ConLang = "Invitation sent successfully";
      }
      alert(ConLang);
    } else {
      //  alert( 'Same Credentials Already Exists');
      // window.location.reload();
    }
  };

  handleDelete = async (mobile, email) => {
    let emailormobile;

    if (email !== null && email !== "" && email !== "No Email") {
      emailormobile = email;
    } 
    else if (mobile !== null && mobile !== "" && mobile !== "No Number") {
      emailormobile = mobile;
    }
    
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    var x = window.confirm(
      this.state.wanttodelete.result + " " + emailormobile
        ? this.state.wanttodelete.result + " " + emailormobile
        : "Are You Sure? Want to Delete?" + " " + emailormobile
    );
    if (x) {
      let user = JSON.parse(localStorage.getItem("user"));

      const accessToken = user;
      const url =
        "https://newtestapi.paropakari.com/api/Invite/DeleteInvite?mobileorEmail=" +
        emailormobile;
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + accessToken,
        },
      };

      const response = await fetch(url, options);
      const result = await response.json();
      if (response.ok) {
        let lang = localStorage.getItem("MenuID");
        if (lang != null) {
          lang = lang.replace(/^"(.*)"$/, "$1"); // Removes surrounding quotes
        }

        let ConLang;
        if (lang === "Tamil") {
          // alert("ta");
          ConLang = emailormobile + " " + "வெற்றிகரமாக நீக்கப்பட்டது";
        } else if (lang === "Telugu") {
          //  alert("te");
          ConLang = emailormobile + " " + "విజయవంతంగా తొలగించబడింది";
        } else if (lang === "Hindi") {
          //  alert("hi");
          ConLang = emailormobile + " " + "सफलतापूर्वक मिटाया गया";
        } else if (lang === "Spanish") {
          //  alert("hi");
          ConLang = emailormobile + " " + "Borrado exitosamente";
        } else if (lang == "Kannada") {
          //  alert("karan");
          ConLang = emailormobile + " " + "ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ";
        } else if (lang === "English") {
          // alert("English");
          ConLang = emailormobile + " " + "Deleted Successfully";
        } else {
          // alert("English");
          ConLang = emailormobile + " " + "Deleted Successfully";
        }
        alert(ConLang);
       
        this.setState({checked:2})
        this.submit();

        this.getInviteUser();

        // window.location.reload();
      } else {
        //  alert( 'Same Credentials Already Exists');
        //  window.location.reload();
      }
    } else {
      return false;
    }
  };

  Footer = () => {
    let url =
      "https://newtestapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });
      });
  };

  DefaultMenu() {
    let RefreshID = 0;
    window.sessionStorage.setItem("reload", RefreshID);
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Invite Friends~Name~Email~Mobile Number~Friends~Same Credential Already Exists~Invitation sent successfully&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var InviteFriends = typeof items[3] !== "undefined" ? items[3] : null;
          var Name = typeof items[4] !== "undefined" ? items[4] : null;
          var Email = typeof items[5] !== "undefined" ? items[5] : null;
          var MobileNumber = typeof items[6] !== "undefined" ? items[6] : null;
          var Friends = typeof items[7] !== "undefined" ? items[7] : null;
          var SameCredentialAlreadyExists =
            typeof items[8] !== "undefined" ? items[8] : null;
          var InvitedSuccessfully =
            typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            invitefriends: InviteFriends,
            name: Name,
            email: Email,
            mobilenumber: MobileNumber,
            friends: Friends,
            SameCredentialAlreadyExists: SameCredentialAlreadyExists,
            langID: LangID,
            InvitedSuccessfully: InvitedSuccessfully,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~My Friends~InvitedFriends~Invited Friends List~Invite Again~No Records Found~Are You Sure? Want to Delete?~Delete&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;

          var MyFriends = typeof items[2] !== "undefined" ? items[2] : null;
          var InvitedFriends =
            typeof items[3] !== "undefined" ? items[3] : null;
          var InvitedFriendsList =
            typeof items[4] !== "undefined" ? items[4] : null;
          var inviteagain = typeof items[5] !== "undefined" ? items[5] : null;
          var norecords = typeof items[6] !== "undefined" ? items[6] : null;
          var wanttodelete = typeof items[7] !== "undefined" ? items[7] : null;
          var deletee = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,

            myFriends: MyFriends,
            invitedFriends: InvitedFriends,
            invitedFriendsList: InvitedFriendsList,
            inviteagain: inviteagain,
            norecords: norecords,
            wanttodelete: wanttodelete,
            deletee: deletee,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Invite Friends~Name~Email~Mobile Number~Friends~Same Credential Already Exists~Invitation sent successfully&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var InviteFriends = typeof items[3] !== "undefined" ? items[3] : null;
          var Name = typeof items[4] !== "undefined" ? items[4] : null;
          var Email = typeof items[5] !== "undefined" ? items[5] : null;
          var MobileNumber = typeof items[6] !== "undefined" ? items[6] : null;
          var Friends = typeof items[7] !== "undefined" ? items[7] : null;
          var SameCredentialAlreadyExists =
            typeof items[8] !== "undefined" ? items[8] : null;
          var InvitedSuccessfully =
            typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            invitefriends: InviteFriends,
            name: Name,
            email: Email,
            mobilenumber: MobileNumber,
            friends: Friends,
            SameCredentialAlreadyExists: SameCredentialAlreadyExists,
            langID: LangID,
            InvitedSuccessfully: InvitedSuccessfully,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~My Friends~InvitedFriends~Invited Friends List~Invite Again~No Records Found~Are You Sure? Want to Delete?~Delete&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;

          var MyFriends = typeof items[2] !== "undefined" ? items[2] : null;
          var InvitedFriends =
            typeof items[3] !== "undefined" ? items[3] : null;
          var InvitedFriendsList =
            typeof items[4] !== "undefined" ? items[4] : null;
          var inviteagain = typeof items[5] !== "undefined" ? items[5] : null;
          var norecords = typeof items[6] !== "undefined" ? items[6] : null;
          var wanttodelete = typeof items[7] !== "undefined" ? items[7] : null;
          var deletee = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,

            myFriends: MyFriends,
            invitedFriends: InvitedFriends,
            invitedFriendsList: InvitedFriendsList,
            inviteagain: inviteagain,
            norecords: norecords,
            wanttodelete: wanttodelete,
            deletee: deletee,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
  }

  componentDidMount() {
    window.addEventListener("load", this.submit);
    this.submit();

    window.addEventListener("load", this.getInviteUser);
    this.getInviteUser();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    document.title = "India's only expenseless, direct crowdfunding platform";
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.getInviteUser);
    this.getInviteUser();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  render() {
    const { emps, inviteuser } = this.state;
    const { notify } = this.state;
    let { foo, Menu } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      norecords,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      InvitedSuccessfully,
      changepassword,
      group,
      logout,
      others,
      requiredforr,
      familyfriends,
      self,
      submit,
      beneficiaryname,
      email,
      privacypolicy,
      inviteagain,
      invitefriends,
      name,
      emails,
      mobilenumber,
      friends,
      langID,
      Inviting,
      copyright_new,
      paropakari_new,
      myFriends,
      wanttodelete,
      deletee,
      invitedFriends,
      invitedFriendsList,
    } = this.state;
    var username = JSON.parse(localStorage.username);
    const currentYear = new Date().getFullYear();
    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd notranslate"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
          className="notranslate"
        >
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          {/*/head*/}
          <select
            className="form-control notranslate"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
            value={this.state.value}
            onChange={this.handleChanges}
          >
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
            <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontSize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design notranslate" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p
                        class="pro-center-tamil"
                        style={{ marginRight: "10px" }}
                      >
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/*/#page-breadcrumb*/}
            <section
              id="blog"
              className={this.state.movedown == true ? null : "Tiptop"}
              style={{ backgroundColor: "#aee6db" }}
            >
              <br />
              <div id="tsum-tabs">
                <main id="maintab">
                  <input id="tab1" type="radio" name="tabs" onClick={()=>this.setState({checked:1})}  checked={this.state.checked==1} />
                  <label for="tab1">
                    {myFriends.result ? myFriends.result : "My Friends"}
                  </label>

                  <input id="tab2" type="radio" name="tabs" onClick={()=>this.setState({checked:2})}  checked={this.state.checked==2} />
                  <label for="tab2">
                    {invitedFriends.result
                      ? invitedFriends.result
                      : "InvitedFriends"}{" "}
                  </label>

                  <section id="content1" style={{ minHeight: "0" }}>
                    <br />
                    <div className="container" id="containerid">
                      <br />
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <div
                            class="cards"
                            style={{
                              borderRadius: "20px",
                              backgroundColor: "#c7f0d2",
                              width: "100%",
                            }}
                          >
                            {/* backgroundColor:"#c7f0d2" light yellow green 
                               rgb(78 202 179 / 64%) skyblue
                                #36afa7 blue
                                #95E5D4*/}
                            <center></center>
                            <div class="table-responsive">
                              <h3 class="card-title">
                                <b>
                                  <center>
                                    {myFriends.result
                                      ? myFriends.result
                                      : "My Friends"}
                                  </center>
                                </b>
                              </h3>
                              <br />
                              <table
                                class="table  nowrap no-border"
                                style={{ borderRadius: "20px" }}
                                // style={{tableLayout:"fixed"}}
                              >
                                {inviteuser.length > 0 ? (
                                  <>
                                    <thead>
                                      <tr style={{}}>
                                        <th style={{ textAlign: "inherit" }}>
                                          <b style={{ fontSize: "15px" }}>
                                            {name.result}
                                          </b>
                                        </th>

                                        <th style={{ textAlign: "inherit" }}>
                                          <b style={{ fontSize: "15px" }}>
                                            {email.result}/{mobilenumber.result}
                                          </b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <br />
                                    <tbody>
                                      {inviteuser.map((invitee) => (
                                        <tr key={invite.user_id}>
                                          <td>{invitee.name}</td>
                                          <td>{invitee.phoneNumber}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                ) : (
                                  norecords.result
                                )}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section id="content2" style={{ minHeight: "0" }}>
                    <br />
                    <div className="container" id="containerid">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <div
                            class="cards"
                            style={{
                              borderRadius: "20px",
                              backgroundColor: "#c7f0d2",
                              width: "100%",
                            }}
                          >
                            <div class="table-responsive">
                              <h3 class="card-title">
                                <b>
                                  <center>
                                    {invitedFriendsList.result
                                      ? invitedFriendsList.result
                                      : "InvitedFriends List"}
                                  </center>
                                </b>
                              </h3>
                              <br />
                              <table class="table  nowrap no-border">
                                {emps.length > 0 ? (
                                  <>
                                    <thead>
                                      <tr>
                                        <th style={{ textAlign: "inherit" }}>
                                          {name.result}
                                        </th>

                                        <th style={{ textAlign: "inherit" }}>
                                          {email.result}
                                        </th>

                                        <th style={{ textAlign: "inherit" }}>
                                          {mobilenumber.result}{" "}
                                        </th>
                                        <th style={{ textAlign: "inherit" }}>
                                          {" "}
                                        </th>
                                      </tr>
                                    </thead>
                                    <br />
                                    <tbody>
                                      {emps.map((invite) => (
                                        <tr key={invite.user_id}>
                                          <td>{invite.name}</td>

                                          <td>{invite.email}</td>

                                          <td>{invite.mobile}</td>
                                          <td>
                                            <a
                                              className="btn btn-secondary pulse"
                                              id="pulse"
                                              style={{
                                                textDecoration: "none",
                                                marginTop: "-5px",
                                                backgroundColor:
                                                  "rgb(102, 71, 18)",
                                                color: "white",
                                              }}
                                              onClick={async () => {
                                                await this.handleInvite(
                                                  invite.name,
                                                  invite.email,
                                                  invite.mobile
                                                );
                                              }}
                                            >
                                              <span>{inviteagain.result}</span>
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              className="btn btn-secondary pulse"
                                              id="pulse"
                                              style={{
                                                textDecoration: "none",
                                                marginTop: "-5px",
                                                backgroundColor:
                                                  "rgb(102, 71, 18)",
                                                color: "white",
                                              }}
                                              onClick={async () => {
                                                await this.handleDelete(
                                                  invite.mobile,
                                                  invite.email,
                                                );
                                              }}
                                            >
                                              <span>{deletee.result}</span>
                                            </a>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                ) : (
                                  norecords.result
                                )}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                    </div>
                  </section>
                </main>
              </div>

              <br />
            </section>

            {/*/#team*/}
            <div style={{ backgroundColor: "#664712" }}>
              <footer id="footer" style={{ color: "white" }}>
                <br />
                <div className="container">
                  <div className="copyright">
                    <div className="row col-lg-12">
                      <div className="col-lg-9 mb-6">
                        {/* {foo.map((fo) => (
                          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
                    <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
                      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
                      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
                      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
                      </p>
                      ))} */}

                        <p style={{ marginLeft: "45px" }}>
                          {copyright_new.result} &nbsp;{currentYear} ©{" "}
                          {paropakari_new.result}&nbsp; |&nbsp;
                          <a
                            href="#/Contactuslogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            {contactus.result}&nbsp;
                          </a>
                          |{" "}
                          <a
                            href="#/PrivacyPolicy"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{privacypolicy.result}&nbsp;
                          </a>
                          |
                          <a
                            href="#/TermsandCondition"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{termsandcondition.result}
                          </a>
                        </p>
                      </div>
                      <div className="col-lg-3 mb-3">
                        <div
                          className="social-icons"
                          style={{ color: "white", marginLeft: "72px" }}
                        >
                          <ul className="nav nav-pills">
                            <li>
                              <a
                                target="_blank"
                                href="https://www.facebook.com/beoneparopakari"
                              >
                                <i className="fa fa-facebook" />
                              </a>
                            </li>
                            {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                            <li>
                              <a
                                target="_blank"
                                href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                              >
                                <i className="fa fa-youtube-play" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/paropakari_beone/"
                              >
                                <i className="fa fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/company/paropakari/"
                              >
                                <i className="fa fa-linkedin" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerss"></div>
              </footer>
            </div>
          </div>
          {/*/#footer*/}
        </div>
      </div>
    );
  }
}
