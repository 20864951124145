import React, { Component } from "react";
import $ from "jquery";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

import Cookies from "js-cookie";
import { BASE_URLs } from '../Constants/BaseURL';
// const refreshPage = () => {
//   setTimeout(() => window.location.reload(), 4000);
// };

export default class ClaimSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      user_id: "",
      profile_imagePath: "",
      profile_name: "",
      upload_id: "",
      created_date: "",
      upload_description: "",
      upload_amount: "",
      upload_place: "",
      upload_title: "",
      status_80G: "",
      upload_num_of_beneficiaries: "",
      category_id: "",
      category_name: "",
      delete_status: false,
      file_name: "",
      file_type: "",
      likes: 0,
      filecount: 0,
      uid: "",
      comments: 3,
      confirmed: 0,
      pending: 0,
      response_pledge: "",
      response_message: "",
      emps: [],
      uploadid: [],
      comm: [],
      like: [],
      share: [],
      notify: [],
      paropakaris: [],
      Tagged: [],
      Liked: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      search: [],
      categories: [],
      foodandnutrition: [],
      health: [],
      professional: [],
      sports: [],
      socialwefare: [],
      others: [],
      sort: [],
      mostrecent: [],
      mostpopular: [],
      available80g: [],
      amountdesc: [],
      amountasc: [],
      complete: [],
      incomplete: [],
      education: [],
      enddate: [],
      causes: [],
      privacypolicy: [],
      beneficiaries: [],
      paropakariss: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [],
      tag: [],
      likess: [],
      comment: [],
      donateasmile: [],
      year: [],
      activity: [],
      mycauses: [],
      likedcauses: [],
      commentedcauses: [],
      pledgedcauses: [],
      langID: [],
      foo: [],
      HappinessMeter: [],
      Achieved: [],
      OnItsWay: [],
      Makeadifferencewithpledgeof: [],
      Writemessageto: [],
      Thecreatorcause: [],
      isresponsibleforproviding: [],
      detailsss: [],
      Paropakaritakesnoresponsibility: [],
      DonateNow: [],
      ScanandDonatewithAPP: [],
      CommentHere: [],
      name: [],
      email: [],
      mobilenumber: [],
      bankaccount: [],
      ifsc: [],
      upiid: [],
      location: [],
      orderID: [],

      YourMessagetotheNeedy: [],
      YourofSupport: [],
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),
      Submit: [],
      EndDate: [],
      isErrorMessageHidden: true,
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.transferSample = this.transferSample.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  handlePayment = async (event) => {
    if (localStorage.getItem("razorpay_payment_id") !== null) {
      let razorpay_payment_id = JSON.parse(
        localStorage.getItem("razorpay_payment_id")
      );
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      const url =
        "https://newtestapi.paropakari.com/api/Profile/Paymentstatus?razorpay_payment_id=" +
        razorpay_payment_id;
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: razorpay_payment_id,
      };

      const response = await fetch(url, options);
      const result = await response.json();
      
      
      localStorage.setItem("status", JSON.stringify(result.status));
      localStorage.setItem("method", JSON.stringify(result.method));
      if (response.ok) {
        let razorpay_payment_id = JSON.parse(
          localStorage.getItem("razorpay_payment_id")
        );

        var upload_id = JSON.parse(localStorage.getItem("upload_id"));
        let order_id = JSON.parse(localStorage.getItem("order_id"));

        let cardName = JSON.parse(localStorage.getItem("card[name]"));
        let cardNumber = JSON.parse(localStorage.getItem("card[number]"));
        let cvv = JSON.parse(localStorage.getItem("card[cvv]"));
        let expiryMonth = JSON.parse(
          localStorage.getItem("card[expiry_month]")
        );
        let expiryYear = JSON.parse(localStorage.getItem("card[expiry_year]"));
        let method = JSON.parse(localStorage.getItem("method"));
        let status = JSON.parse(localStorage.getItem("status"));
        let NetBanking = JSON.parse(localStorage.getItem("netbanking"));
        let UPI = JSON.parse(localStorage.getItem("upi"));
        
        if (cardName == null) {
          cardName = "None";
        }
        if (cardNumber == null) {
          cardNumber = "None";
        }
        if (cvv == null) {
          cvv = 0;
        }
        if (expiryMonth == null) {
          expiryMonth = 0;
        }
        if (expiryYear == null) {
          expiryYear = 0;
        }
        if (NetBanking == null) {
          NetBanking = "None";
        }
        if (UPI == null) {
          UPI = "None";
        }
        var Amount = sessionStorage.getItem("Amount");

        var data1 = {
          razorpay_payment_id: razorpay_payment_id,
        };
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        var urll =
          "https://newtestapi.paropakari.com/api/Profile/Add_Payment?razorpay_payment_id=" +
          razorpay_payment_id +
          "&upload_id=" +
          upload_id +
          "&Order_id=" +
          order_id +
          "&cardName=" +
          cardName +
          "&cardNumber=" +
          cardNumber +
          "&cvv=" +
          cvv +
          "&expiryMonth=" +
          expiryMonth +
          "&expiryYear=" +
          expiryYear +
          "&DonorModeofPayment=" +
          method +
          "&Payment_status=" +
          status +
          "&Payment_amount=" +
          Amount +
          "&Donor_NetBankName=" +
          NetBanking +
          "&Donor_UPI=" +
          UPI;

        var optionss = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: JSON.stringify(data1),
        };

        try {
          const response = await fetch(urll, optionss);
          const result = await response.json();

          if (response.ok) {
            alert("Successfully Completed");
          } else {
            alert("Failed!. Try Again");
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  handleClick = (event) => {
    window.location.href = "#/HomePage";
  };

  ReloadPage = () => {
    let reloads = 0;

    if (sessionStorage.getItem("reloadlogin") !== null) {
      
      reloads = sessionStorage.getItem("reloadlogin");
      reloads++;
      window.sessionStorage.setItem("reloadlogin", reloads);
      
    } else {
      reloads = 1;
      window.sessionStorage.setItem("reloadlogin", reloads);
      
    }

    if (reloads == 1) {
      window.location.reload();
    }
  };
  transferSample = async (event) => {
    event.preventDefault();
  };
  clickHandler = () => {
    let textValue = document.getElementById("Donor_PAN").value;
    if (textValue === "") this.setState({ isErrorMessageHidden: false });
    else this.setState({ isErrorMessageHidden: true });
  };
  setWithout80G(e) {
    window.location.href = "#/DonateSmileWithout80G";
  }

  notify = () => {
    let url = "https://newtestapi.paropakari.com/api/Profile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });
        
      });
  };

  comment = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    

    let url =
      "https://newtestapi.paropakari.com/api/Profile/Get_Comment?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comm: data,
        });
        
      });
  };

  componentDidMount() {
    window.addEventListener("load", this.Footer);
    this.Footer();

    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.ReloadPage);
    this.ReloadPage();

    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
  }

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login.js",
    };
    this.props.history.push(page);
  };

  handleFormValidation() {
    
    const { username, password } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!username) {
      formIsValid = false;
      formErrors["opErr"] = "Enter Username.";
    }
    if (!password) {
      formIsValid = false;
      formErrors["npErr"] = "Enter Password.";
    }

    this.setState({ formErrors: formErrors });
    
    return formIsValid;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit = () => {
    const { username, password } = this.state;
    alert(`Signed up with username: ${username} password: ${password}`);
  };

  Footer() {


  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });
        
      });
  }

  DefaultMenu() {

    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }
    

    

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == "undefined") {
      GGID = this.state.googtrans.slice(4, 6);
    } else {
      GGID = MID;
    }

    let datatrans = GGID;

    

    localStorage.setItem("GoogleTranslate123", datatrans);

    let GID = sessionStorage.getItem("this.state.googtrans");

    

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));
    
    
    let datatransID;
    if (datatrans == "en") {
      datatransID = "English";
    } else if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Privacy Policy&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;

          window.sessionStorage.setItem("translate", LangID);
          var Name = sessionStorage.getItem("name");
          var Email = sessionStorage.getItem("Email");
          var MobileNumber = sessionStorage.getItem("MobileNumber");
          var Amount = sessionStorage.getItem("Amount");
          var Location = sessionStorage.getItem("Location");
          var OrderID = sessionStorage.getItem("order_id");
          var UploadID = sessionStorage.getItem("UploadID");
          window.sessionStorage.setItem("order_id", OrderID);

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
            name: Name,
            email: Email,
            mobilenumber: MobileNumber,
            amount: Amount,
            orderID: OrderID,
            location: Location,
            uploadid: UploadID,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;

          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;

          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;

          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var ContactUs = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition =
            typeof items[7] !== "undefined" ? items[7] : null;
          var CopyRight = typeof items[8] !== "undefined" ? items[8] : null;
          var Causes = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;

          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: ContactUs,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Educattion = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;

          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~On Its Way~Make a difference with pledge of~Write a message to&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses =
            typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Makeadifferencewithpledgeof =
            typeof items[8] !== "undefined" ? items[8] : null;
          var Writemessageto =
            typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;

          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Makeadifferencewithpledgeof: Makeadifferencewithpledgeof,
            Writemessageto: Writemessageto,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=The creator of the cause~is responsible for providing any documents~Paropakari.com takes no responsibility for these documents~Donate a Smile~Scan and Donate with APP~Comment Here~Your Message to the Needy~Your of Support~Submit~End Date~Share&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Thecreatorcause =
            typeof items[0] !== "undefined" ? items[0] : null;
          var isresponsibleforproviding =
            typeof items[1] !== "undefined" ? items[1] : null;

          var Paropakaritakesnoresponsibility =
            typeof items[2] !== "undefined" ? items[2] : null;
          var DonateNow = typeof items[3] !== "undefined" ? items[3] : null;
          var ScanandDonatewithAPP =
            typeof items[4] !== "undefined" ? items[4] : null;
          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var YourMessagetotheNeedy =
            typeof items[6] !== "undefined" ? items[6] : null;
          var YourofSupport = typeof items[7] !== "undefined" ? items[7] : null;

          var Submit = typeof items[8] !== "undefined" ? items[8] : null;
          var EndDate = typeof items[9] !== "undefined" ? items[9] : null;
          var Share = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;

          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            Thecreatorcause: Thecreatorcause,
            isresponsibleforproviding: isresponsibleforproviding,
            Paropakaritakesnoresponsibility: Paropakaritakesnoresponsibility,
            DonateNow: DonateNow,
            ScanandDonatewithAPP: ScanandDonatewithAPP,
            CommentHere: CommentHere,
            YourMessagetotheNeedy: YourMessagetotheNeedy,
            YourofSupport: YourofSupport,
            Submit: Submit,
            EndDate: EndDate,
            share: Share,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem("menuid", event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];
    
    let MID = event.target.value;
    let LnID = event.target.value;
    if (LnID == "Kannada") {
      LnID = "kn";
    } else if (LnID == "Tamil") {
      LnID = "ta";
    } else if (LnID == "Telugu") {
      LnID = "te";
    } else if (LnID == "Hindi") {
      LnID = "hi";
    } else if (LnID == "Spanish") {
      LnID = "es";
    } else if (LnID == event.target.value) {
      LnID = event.target.value;
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition =
            typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile =
            typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;
          var LanguageID = MID;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            languageid: LanguageID,
            lID: LID,
          });

          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return <p>{result}</p>;
          });
        });
    }
    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome!~Username~Password~Forgot password~Login~Dont have an account?&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword =
            typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,

            langID: LangID,
            lID: LID,
          });

          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return <p>{result}</p>;
          });
        });
    }
    if (MID != null) {
      

      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Enter Username.~Enter Password.~Username and Password is incorrect!. Try Again~is not matching~Or~Email~Mobile Number&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;

          var EnterPassword = typeof items[4] !== "undefined" ? items[4] : null;
          var EnterUsername = typeof items[3] !== "undefined" ? items[3] : null;
          var UsernameandPasswordisincorrectTryAgain =
            typeof items[5] !== "undefined" ? items[5] : null;

          var Captchisnotmatch =
            typeof items[6] !== "undefined" ? items[6] : null;
          var Or = typeof items[7] !== "undefined" ? items[7] : null;
          var Email = typeof items[8] !== "undefined" ? items[8] : null;
          var MobileNumber = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            lID: LID,
            EnterPassword: EnterPassword,
            EnterUsername: EnterUsername,
            UsernameandPasswordisincorrectTryAgain:
              UsernameandPasswordisincorrectTryAgain,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            Captchisnotmatch: Captchisnotmatch,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          
          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          

          

          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );
          
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size))
      .fill("")
      .map(function () {
        return this.splice(0, size);
      }, longArray.slice());

    
    return <div>{newArray}</div>;
  }

  render() {
    const { username, password, l_name } = this.state;
    var username1 = JSON.parse(localStorage.username);
    const { foo } = this.state;
    const { notify } = this.state;
    const { Details, firstNElements,copyright_new, paropakari_new, } = this.state;
    var upload_id = JSON.parse(localStorage.getItem("upload_id"));
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakariss,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      search,
      name,
      email,
      mobilenumber,
      location,
      amount,
      categories,
      education,
      foodandnutrition,
      health,
      professional,
      sports,
      socialwefare,
      others,
      orderID,
      sort,
      mostrecent,
      mostpopular,
      available80g,
      amountdesc,
      amountasc,
      complete,
      incomplete,
      enddate,
      tag,
      likess,
      comment,
      donateasmile,
      year,
      activity,
      mycauses,
      likedcauses,
      commentedcauses,
      pledgedcauses,
      HappinessMeter,
      share,
      Achieved,
      privacypolicy,
      uploadid,
      OnItsWay,
      Makeadifferencewithpledgeof,
      Writemessageto,
      Thecreatorcause,
      isresponsibleforproviding,
      YourMessagetotheNeedy,
      YourofSupport,
      Paropakaritakesnoresponsibility,
      DonateNow,
      ScanandDonatewithAPP,
      CommentHere,
      Submit,
      EndDate,
      langID,
    } = this.state;

    const currentYear = new Date().getFullYear();
    const handleRoute = () => {
      this.props.history.push("#/HomePage");
    };

    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
          className="notranslate"
        >
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content />
          <meta name="author" content />
          <title>Blog Masonry | Triangle</title>
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          <div onClick={this.refreshPage}>
            <select
              className="form-control"
              style={{
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
               <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
          </div>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontsize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b> <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Login">
                                <b>{logout.result}</b>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b> <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Login">
                                <b>{logout.result}</b>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b> <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Login">
                                <b>{logout.result}</b>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                    ) : langID == "Spanish" ? (
                      <>
                        <div class="pro-home-nav-links pro-side pro-top">
                          <p class="pro-center">
                            <a className="new-tamil-design" href="#/CauseFor">
                              {beaparopakari.result}
                            </a>
                          </p>
                          <ul className="parop-home-tamil-boxx parop-boxx-top">
                            <li className="parop-menu">
                              <a className="text-parop-menu" href="#/Myactivity">
                                {myjourney.result}
                              </a>
                            </li>
                            <li className="parop-menu">
                              <a href="#/CauseFor">{createacause.result}</a>
                            </li>
                            <li className="parop-menu">
                              <a href="#/InviteFriend">{invite.result}</a>
                            </li>
                            <li className="parop-menu parop-notify">
                              <a href="#/Notification">
                                <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                                  {notify.map((notifyy) => (
                                    <span class="-count">{notifyy.noticount}</span>
                                  ))}
                                </div>
                              </a>
                            </li>
                            <li className="parop-menu">
                              <a>
                                <b>{username}</b> <i className="fa fa-angle-down"></i>
                              </a>
                              <ul class="dropdown">
                                <li className="hower-text">
                                  <a href="#/Profile">
                                    <b>{profile.result}</b>
                                  </a>
                                </li>
                                <li className="hower-text">
                                  <a href="#/ChangePassword">
                                    <b>{changepassword.result}</b>
                                  </a>
                                </li>
                                <li className="hower-text">
                                  <a href="#/Group">
                                    <b>{group.result}</b>
                                  </a>
                                </li>
                                <li className="hower-text">
                                  <a href="#/Login">
                                    <b>{logout.result}</b>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b> <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Login">
                                <b>{logout.result}</b>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b> <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Login">
                                <b>{logout.result}</b>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <section
              id="blog"
              className={this.state.movedown == true ? null : "Tiptop"}
              style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
            >
              <div className="container" id="containerid">
                <form
                  className=""
                  onSubmit={this.handleClick}
                  role="form"
                  style={{ margin: "5px" }}
                >
                  <input
                    type="hidden"
                    value={langID}
                    id="lang_id"
                    onChange={this.handleChange}
                    name="langid"
                  />
                  <input
                    type="hidden"
                    value={orderID}
                    id="Order_id"
                    onChange={this.handleChange}
                    name="Order_id"
                  />
                  <input
                    type="hidden"
                    value={uploadid}
                    id="upload_id"
                    onChange={this.handleChange}
                    name="upload_id"
                  />

                  <div style={{ marginLeft: "41%" }}></div>
                  <center>
                    <h1>Thank You</h1>
                  </center>
                  <center>
                    {" "}
                    <img
                      src="./assets/images/PaymentSuccess.png"
                      style={{ width: "15%" }}
                    />
                  </center>
                  <center>
                    <h2>Your Donation Was Claimed Successfully</h2>
                  </center>

                  <center>
                    {" "}
                    <button
                      type="submit"
                      id="Regclaim"
                      class="submit"
                      onClick={this.clickHandler}
                    >
                      Continue
                    </button>
                  </center>
                  <div style={{ marginLeft: "41%" }}></div>
                  <br />

                  <br></br>
                </form>
              </div>
            </section>

            <div style={{ backgroundColor: "#f2dba8" }}>
              <footer id="footer" style={{ color: "white" }}>
                <br />
                <div className="container notranslate">
                  <div className="copyright">
                    <div className="row col-lg-12">
                      <div className="col-lg-9 mb-6">
                        <p style={{ marginLeft: "45px" }}>
                        {copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;
                          <a
                            href="#/Contactuslogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            {contactus.result}&nbsp;
                          </a>
                          |{" "}
                          <a
                            href="#/PrivacyPolicyLogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{privacypolicy.result}&nbsp;
                          </a>
                          |
                          <a
                            href="#/TermsandConditionLogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{termsandcondition.result}
                          </a>
                        </p>
                      </div>
                      <div className="col-lg-3 mb-3">
                        <div
                          className="social-icons"
                          style={{ color: "white", marginLeft: "72px" }}
                        >
                          <ul className="nav nav-pills">
                            <li>
                              <a
                                target="_blank"
                                href="https://www.facebook.com/beoneparopakari"
                              >
                                <i className="fa fa-facebook" />
                              </a>
                            </li>

                            <li>
                              <a
                                target="_blank"
                                href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                              >
                                <i className="fa fa-youtube-play" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/paropakari_beone/"
                              >
                                <i className="fa fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/company/paropakari/"
                              >
                                <i className="fa fa-linkedin" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerss"></div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
