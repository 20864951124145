import React, { Component } from 'react'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Cookies from 'js-cookie';
import { BASE_URLs } from '../Constants/BaseURL';
const refreshPage = () => {

  setTimeout(() => window.location.reload(), 4000);


}




//import SideBars from './SideBars.js';

//setTimeout(function(){$('.errormsg').fadeOut();}, 9000);

export default class AdmLognParopakari extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // id : this.props.match.params.id,
      username: "",
      password: "",
      l_name: "",
      formErrors: {},
      foo: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      welcome: [],
      usernames: [],
      passwords: [],
      forgotpassword: [],
      signin: [],
      donthaveacc: [],
      signup: [],
      entercaptchvalue: [],
      enter: [],
      langID: [],
      languageid: [],
      lID: [],
      dlangID: [],
      EnterUsername: [],
      EnterPassword: [],
      or: [], email: [], mobilenumber: [],
      UsernameandPasswordisincorrectTryAgain: [],
      privacypolicy: [],
      Captchisnotmatch: [],
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
    };
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.performLogin = this.performLogin.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
  }



  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }


  ReloadPage = () => {
    // let reloads=sessionStorage.getItem('reloadlogin');
    //  // setTimeout(() => window.location.reload(), 2000);
    //   if(sessionStorage.getItem('reloadlogin') !== null ){
    //     
    //     reloads= sessionStorage.getItem('reloadlogin');
    //     reloads++;
    //     window.sessionStorage.setItem('reloadlogin', (reloads));
    //     
    //   }
    //   else{
    //     reloads=1;
    //     window.sessionStorage.setItem('reloadlogin', (reloads));
    //     
    //   }

    //   if(reloads ==1)
    //   {
    //     reloads= sessionStorage.getItem('reloadlogin');
    //     
    //     window.location.reload();
    //   }
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem('reloadlogin') !== null) {
      
      reloads = sessionStorage.getItem('reloadlogin');
      reloads++;
      window.sessionStorage.setItem('reloadlogin', (reloads));
      
    }
    else {
      reloads = 1;
      window.sessionStorage.setItem('reloadlogin', (reloads));
      
    }

    if (reloads == 1) {
      window.location.reload();
    }


  }

  componentDidMount() {
    // if(sessionStorage.getItem('dtranslate') == null){
    //   setTimeout(() => {

    //       
    //      window.alerthello2();
    //     }, 3000);

    // }
    // if(sessionStorage.getItem('translate') !== null){
    //   setTimeout(() => {

    //       
    //     // window.alerthello();
    //     }, 3000);

    // }


    loadCaptchaEnginge(6);
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.ReloadPage);
    this.ReloadPage();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    window.onload = window.localStorage.clear();
  };

  componentWillUnmount() {

    loadCaptchaEnginge(6);
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.ReloadPage);
    this.ReloadPage();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: './Login.js',

    }
    this.props.history.push(page)
  }

  handleFormValidation() {
    
    const { username, password } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!username) {

      formIsValid = false;
      formErrors["opErr"] = "Enter Username.";
    }
    if (!password) {
      formIsValid = false;
      formErrors["npErr"] = "Enter Password.";
    }


    this.setState({ formErrors: formErrors });
    
    return formIsValid;

  }



  paropakaris = () => {
    // const urll = new URL(window.location.href);
    // const Id = urll.searchParams.get("upload_id");
    // 
    // 
    let url = `${BASE_URLs}Payout/GetAdminstatus`;
    // let url = "https://newtestapi.paropakari.com/api/Payout/GetAdminstatus";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          paropakaris: data
        });
        
        localStorage.setItem('Admin_status', JSON.stringify(data[0].admin_status));

        let Admin_status = JSON.parse(localStorage.getItem('Admin_status'));

        

        if (Admin_status == 1) {
          // var LangID = document.getElementById("lang_id").value
          window.location.href = "#/PayoutList";
          // var page= {
          //   pathname: '#/PayoutList',
          //   state: {
          //     data:{
          //      username: this.state.username,
          //      password: this.state.password,
          //     }
          //   }
          // }
        }
        else {
          alert("You are not authorized");
          window.location.reload();
        }
      });
  }



  performLogin = async event => {
    event.preventDefault();
    
    //  debugger;   

    if (this.handleFormValidation()) {
      
      // debugger;   
      //  localStorage.setItem('document',JSON.stringify(this.state));
      const vg = this.state.howitworks;
      //  alert(vg.result);
      let user_captcha = document.getElementById('user_captcha_input').value;

      if (validateCaptcha(user_captcha) === true) {
        // alert('Captcha Matched');

        var body = {
          password: this.state.password,
          username: this.state.username,
          Admin_status: "Yes"
        };
        let url = `${BASE_URLs}Authenticate/AdminLogin`;
        

        var options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            "Accept": "application/json"
          },
          body: JSON.stringify(body)
        };


        try {
          const response = await fetch(url, options);
          const result = await response.json();
          localStorage.setItem('user', JSON.stringify(result.token));
          localStorage.setItem('username', JSON.stringify(result.name));
          localStorage.setItem('userid', JSON.stringify(result.userid));
          ///localStorage.setItem('create', JSON.stringify(result.id));

          

          if (result.message == 'Username or Password is Incorrect' || result.message == 'Unauthorized user') {
            window.onload = window.localStorage.clear();
            alert('Username or Password is incorrect');

            window.location.reload();


          }
          else {
            this.paropakaris();
          }
          // let user = JSON.parse(localStorage.getItem('user'));
          // const accessToken = user;
          // if (accessToken != "undefined") {


          //window.location.href = "#/PayoutList";

          //   let Admin_status = JSON.parse(localStorage.getItem('Admin_status'));
          //   alert("Hi",Admin_status);
          //   
          //     if(Admin_status == 1)
          //     {
          //  var LangID = document.getElementById("lang_id").value
          //  window.location.href = "#/PayoutList";
          //   // var page= {
          //   //   pathname: '#/PayoutList',
          //   //   state: {
          //   //     data:{
          //   //      username: this.state.username,
          //   //      password: this.state.password,
          //   //     }
          //   //   }
          //   // }
          // }
          //   
          //   //window.alert("login succesful");
          //   //this.props.history.push(page)
          //  // alert("Login Successful...");



          // } else {
          // //  const status =this.state.UsernameandPasswordisincorrectTryAgain.result ? this.state.UsernameandPasswordisincorrectTryAgain : 'User Name and Password is incorrect!. Try again.'
          // // alert(this.state.UsernameandPasswordisincorrectTryAgain.result ? this.state.UsernameandPasswordisincorrectTryAgain.result : 'User Name and Password is incorrect!. Try again.');

          //    alert("jhg");
          //   window.location.reload();
          // }
        }



        catch (error) {
          console.error(error);
        }

      }
      else {
        alert('Captcha ' + this.state.Captchisnotmatch.result);
      }


    };


  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSubmit = () => {
    const { username, password } = this.state;
    alert(`Signed up with username: ${username} password: ${password}`);
  };

  Footer() {

    

   
  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }


  DefaultMenu() {
    //   
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    
    let MID;
    //         if(sessionStorage.getItem('transliteration') !== null){
    //           MID= sessionStorage.getItem('transliteration');
    //         }
    // else
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    



    let datatrans = MID;

    

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem('translate');
    

    let GIDS = JSON.parse(localStorage.getItem('this.state.googtrans'));
    
    
    let datatransID = MID;
    if (datatrans == "kn") {

      datatransID = "Kannada";
    }
    else
      if (datatrans == "ta") {
        datatransID = "Tamil";
      }
      else
        if (datatrans == "te") {
          datatransID = "Telugu";
        }
        else
          if (datatrans == "hi") {
            datatransID = "Hindi";
          }
          else
            if (datatrans == MID) {
              datatransID = MID;
            }

    let LnID = MID;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
            if (LnID == MID) {
              LnID = MID;
            }

    if (MID != null) {
      
      // let url = `${BASE_URLs}Authenticate/AdminLogin`;
      let url =
      `${BASE_URLs}Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;
          var LanguageID = MID;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            languageid: LanguageID,
            lID: LID

          });
          ;
        });

    }
    if (MID != null) {
      

      let url =
      `${BASE_URLs}Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome!~Username~Password~Forgot password~Login~Dont have an account?&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,

            langID: LangID,
            lID: LID

          });

        });

    }
    if (MID != null) {
      

      let url =
      `${BASE_URLs}Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Enter Username.~Enter Password.~User Name or Password is Incorrect. Please Retry~Please enter valid captcha~Or~Email~Mobile Number&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;


          var EnterPassword = typeof items[4] !== "undefined" ? items[4] : null;
          var EnterUsername = typeof items[3] !== "undefined" ? items[3] : null;
          var UsernameandPasswordisincorrectTryAgain = typeof items[5] !== "undefined" ? items[5] : null;

          var Captchisnotmatch = typeof items[6] !== "undefined" ? items[6] : null;
          var Or = typeof items[7] !== "undefined" ? items[7] : null;
          var Email = typeof items[8] !== "undefined" ? items[8] : null;
          var MobileNumber = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            lID: LID,
            EnterPassword: EnterPassword,
            EnterUsername: EnterUsername,
            UsernameandPasswordisincorrectTryAgain: UsernameandPasswordisincorrectTryAgain,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            Captchisnotmatch: Captchisnotmatch
          });

        });

    }
    if (MID != null) {
      let url =
      `${BASE_URLs}Authenticate/Translatev?values=Copyright~Paropakari&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            
          });


          // 
        });
    }

  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    let LnID = event.target.value;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
            if (LnID == event.target.value) {
              LnID = event.target.value;
            }
    if (MID != null) {


      let url =
      `${BASE_URLs}Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;
          var LanguageID = MID;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            languageid: LanguageID,
            lID: LID

          });


          // alert(firstItem);
          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return (<p >{result}</p>)
          });
          // 
        });

    }
    if (MID != null) {
      

      let url =
      `${BASE_URLs}Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome!~Username~Password~Forgot password~Login~Dont have an account?&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,

            langID: LangID,
            lID: LID

          });


          // alert(firstItem);
          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return (<p >{result}</p>)
          });
          // 
        });

    }
    if (MID != null) {
      

      let url =
      `${BASE_URLs}Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Enter Username.~Enter Password.~User Name or Password is Incorrect. Please Retry~Please enter valid captcha~Or~Email~Mobile Number&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;


          var EnterPassword = typeof items[4] !== "undefined" ? items[4] : null;
          var EnterUsername = typeof items[3] !== "undefined" ? items[3] : null;
          var UsernameandPasswordisincorrectTryAgain = typeof items[5] !== "undefined" ? items[5] : null;

          var Captchisnotmatch = typeof items[6] !== "undefined" ? items[6] : null;
          var Or = typeof items[7] !== "undefined" ? items[7] : null;
          var Email = typeof items[8] !== "undefined" ? items[8] : null;
          var MobileNumber = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            lID: LID,
            EnterPassword: EnterPassword,
            EnterUsername: EnterUsername,
            UsernameandPasswordisincorrectTryAgain: UsernameandPasswordisincorrectTryAgain,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            Captchisnotmatch: Captchisnotmatch
          });



          // 
        });

    }
    if (MID != null) {


      let url =
      `${BASE_URLs}Authenticate/Translatev?values=Cancel&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          
          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
    if (MID != null) {
      let url =
      `${BASE_URLs}Authenticate/Translatev?values=Copyright~Paropakari&lang=` + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            
          });


          // 
        });
    }

  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());

    
    return (
      <div>{newArray}</div>
    )
  }

  render() {
    const { username, password, l_name } = this.state;
    const { opErr, npErr } = this.state.formErrors;
    const isEnabled = username.length > 0 && password.length > 8;
    const error = localStorage.getItem('error');
    const { foo } = this.state;

    const { Details, firstNElements } = this.state;
    const { newArray, howitworks, beaparopakari, whatwedo, blog, login, indiaexpensless, ourstory, oppertunitybringsmile,
      contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, whatwedodesc,
      langID, welcome, usernames, passwords, forgotpassword, signin, donthaveacc, signup, entercaptchvalue,
      enter, EnterPassword, dlangID, lID, privacypolicy, languageid, or, email, mobilenumber,copyright_new, paropakari_new,
      EnterUsername, UsernameandPasswordisincorrectTryAgain, Captchisnotmatch } = this.state;

      const currentYear = new Date().getFullYear();
    const handleRoute = () => {

      this.props.history.push("#/HomePage");
    }
    return (


      <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content />
        <meta name="author" content />
        <title>Blog Masonry | Triangle</title>
        <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
        <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
        <link href="./assets/css/lightbox.css" rel="stylesheet" />
        <link href="./assets/css/animate.min.css" rel="stylesheet" />
        <link href="./assets/css/main.css" rel="stylesheet" />
        {/* <link href="./assets/css/Register.css" rel="stylesheet" /> */}
        <link href="./assets/css/NewregisterDonatesmile.css" rel="stylesheet" />
        {/*[if lt IE 9]>
              
              
          <![endif]*/}
        <link rel="shortcut icon" href="images/ico/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
        {/*/head*/}

        {/* 
  <div onClick={refreshPage}> */}

        <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
          <option value="English">Languages</option>
          <option value="Kannada">Kannada</option>
          <option value="Tamil">Tamil</option>
          <option value="Hindi">Hindi</option>
          <option value="Telugu">Telugu</option>
          <option value="English">English</option>

        </select>

        {/* </div> */}

        &nbsp;&nbsp; &nbsp;&nbsp;





        <div>

          <div className="mag notranslate">
            <div class="pro-home-nav">
              <input type="checkbox" id="pro-home-nav-check" onClick={this.checkfun} />
              <div class="pro-home-nav-header">
                <div class="pro-home-nav-title notranslate">
                  <a className="navbar-brand" href="#/HomePage">
                    <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                    </h1>
                  </a>
                </div>
              </div>

              <div class="pro-home-nav-btn">
                <label for="pro-home-nav-check">
                  <div className="pro-naav">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </label>
              </div>

              {languageid == "English" ? <>
                <div class="pro-home-nav-links pro-side pro-top">

                  <ul className="parop-home-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Login">{login.result}</a></li>




                  </ul>
                </div>
              </> : languageid == "Hindi" ? <>
                <div class="pro-home-nav-links pro-side pro-top">

                  <ul className="parop-home-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Login">{login.result}</a></li>


                  </ul>
                </div>
              </> : languageid == 'Tamil' ? <>
                <div class="pro-home-nav-links pro-side pro-top">

                  <ul className="parop-home-tamil-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Login">{login.result}</a></li>


                  </ul>
                </div>
              </> : languageid == "Kannada" ? <>
                <div class="pro-home-nav-links pro-side pro-top">

                  <ul className="parop-home-tamil-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Login">{login.result}</a></li>


                  </ul>
                </div>
                </> : languageid == "Spanish" ? <>
                <div class="pro-home-nav-links pro-side pro-top">

                  <ul className="parop-home-tamil-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Login">{login.result}</a></li>


                  </ul>
                </div>
              </> : languageid == "Telugu" ? <>
                <div class="pro-home-nav-links pro-side pro-top">

                  <ul className="parop-home-tamil-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Login">{login.result}</a></li>


                  </ul>
                </div>
              </> : null}
            </div>
          </div>



          <section id="blog" className={this.state.movedown == true ? "padding-top padding-bottom" : "padding-top padding-bottom Tiptop"} style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>

            <div className="container" id="containerid">


              <div className="row">

                <div id="rcont" class="rcont">

                  <div id="login" class="form sign-in" style={{ backgroundColor: '#a5d9cf' }}>

                    <form className="" onSubmit={this.performLogin} role="form" style={{ margin: '5px' }} >

                      <h2 style={{ color: 'black' }}>{welcome.result}</h2>

                      <label>
                        <span>{mobilenumber.result} &nbsp; {or.result} &nbsp; {email.result}</span>

                        <input type="hidden" value={langID} id="lang_id"
                          onChange={this.handleChange} name="langid" />
                        <input type="hidden" value={lID} id="l_id"
                          onChange={this.handleChange} name="langid" />

                        {/* <input type="text"  value={dlangID} id="dlang_ID"   
          onChange={this.handleChange}  name="dlangID" /> */}

                        <input type="text" value={this.state.username}
                          onChange={this.handleChange} autoComplete="off" placeholder=""
                          id="handleEmailChange" maxLength={30} name="username" />

                      </label>
                      <div style={{ marginLeft: "41%" }} >
                        {(() => {
                          if (languageid == 'Kannada' || languageid == 'Tamil') {
                            return (
                              <span className="errormsg" style={{ color: "red", marginLeft: '-46px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span>

                            );
                          }


                        })()}

                        {(() => {
                          if (languageid == 'Hindi') {
                            return (
                              <span className="errormsg" style={{ color: "red", marginLeft: '-20px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span>

                            );
                          }


                        })()}
                        {(() => {
                          if (languageid == 'Telugu') {
                            return (
                              <span className="errormsg" style={{ color: "red", marginLeft: '-76px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span>

                            );
                          }


                        })()}
                        {(() => {
                          if (languageid == 'English') {
                            return (
                              <span className="errormsg" style={{ color: "red" }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span>

                            );
                          }


                        })()}

                      </div>

                      <label>
                        <span>{passwords.result}</span>
                        <input type="password" value={this.state.password} maxLength={20} autoComplete="off" onChange={this.handleChange} placeholder=""
                          data-val-length-max={100} data-val-length-min={4} data-val-required="The Password field is required." id="Password" name="password" />
                      </label>

                      <div style={{ marginLeft: "41%" }} >
                        {(() => {
                          if (languageid == 'Kannada') {
                            return (
                              <span className="errormsg" style={{ color: "red", marginLeft: '-13px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span>

                            );
                          }


                        })()}

                        {(() => {
                          if (languageid == 'Tamil') {
                            return (
                              <span className="errormsg" style={{ color: "red", marginLeft: '-55px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span>

                            );
                          }


                        })()}
                        {(() => {
                          if (languageid == 'Hindi' || languageid == 'English') {
                            return (
                              <span className="errormsg" style={{ color: "red" }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span>

                            );
                          }


                        })()}
                        {(() => {
                          if (languageid == 'Telugu') {
                            return (
                              <span className="errormsg" style={{ color: "red", marginLeft: '-33px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span>

                            );
                          }


                        })()}

                      </div>
                      <br />
                      <center>
                        <div className="Captcha">
                          <LoadCanvasTemplate />
                        </div>

                        <div className="k-row" >
                          <input id="user_captcha_input" name="user_captcha_input" autoComplete="off" style={{ width: '50%', color: 'black' }} type="text" placeholder={entercaptchvalue.result}></input>
                        </div>
                      </center>

                      <br></br>
                      {(() => {
                        if (languageid == 'Tamil') {
                          return (
                            <center>  <a href={"#/Forgot"} class="forgot-pass" id="linkid" ><b className="forgotpass">{forgotpassword.result}?</b></a>
                            </center>
                          );
                        }
                        else {
                          return (

                            <center>    <a href={"#/Forgot"} class="forgot-pass" id="linkid" ><b className="forgotpass">{forgotpassword.result}?</b></a>
                            </center>
                          );
                        }

                      })()}

                      {(() => {
                        if (languageid == 'English') {
                          return (
                            <button type="submit" id="Reg" class="submit" style={{ width: '2%4' }}  >{login.result}</button>

                          );
                        }
                        else {
                          return (

                            <button type="submit" id="Reg" class="submit" style={{ width: '45%' }} >{login.result}</button>
                          );
                        }

                      })()

                      }



                    </form>

                  </div>

                  <div id="rsub-cont" class="sub-rcont">

                    <div id="rimgsub-cont" class="img">

                      <div class="img__text m--up">

                        {/* <h3>{donthaveacc.result} <a href={"#/Register/"+langID} style={{textDecoration:'none',color:'black'}} >{signup.result}</a></h3> */}
                      </div>
                      <div class="img__text m--in">

                        <h3>If you already has an account, just sign in.</h3>
                      </div>
                      <div class="tnr" id="tnr">
                        {/* {(() => {if (languageid == 'Tamil' || languageid == 'Kannada') {return (
   <a href={"#/Register"} class="img__btn m--up" style={{textDecoration:'none',color:'background: rgb(102, 71, 18);',paddingTop:'10px',paddingBottom:'10px',paddingLeft:'10px',paddingRight:'10px',marginLeft:'50px'}}>  {signup.result}</a>

);
}
                 else {return(

                  <a href={"#/Register"} class="img__btn m--up" style={{textDecoration:'none',color:'background: rgb(102, 71, 18);',paddingTop:'10px',paddingBottom:'10px',paddingLeft:'10px',paddingRight:'10px',marginLeft:'90px'}}>  {signup.result}</a>
);}

})()}     
     
        <span class="m--in" >Sign In</span> */}
                      </div>
                    </div>


                    <div class="form sign-up">
                      <h2>Create your Account</h2>
                      <label>
                        <span>Name</span>
                        <input type="text" />
                      </label>
                      <label>
                        <span>Email</span>
                        <input type="email" />
                      </label>
                      <label>
                        <span>Password</span>
                        <input type="password" />
                      </label>


                    </div>

                  </div>

                </div>


              </div>



            </div>
          </section>


          {/*/#blog*/}
          <div style={{ backgroundColor: '#f2dba8' }}>
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;<a href="#/Contactuslogin" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>
          </div>
        </div>
        {/*/#footer*/}
      </div>


    )
  }
}