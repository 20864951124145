import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Cookies from 'js-cookie';


import Script from 'react-load-script'
import { Phone } from '@material-ui/icons';
import { BASE_URLs } from '../Constants/BaseURL';

//import SideBars from './SideBars.js';


export default class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id : this.props.match.params.id,
      pno: '',
      pswd: '',
      foo: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      indiaexpensless: [],
      Passwordresend5timesonly: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      welcome: [],
      usernames: [],
      passwords: [],
      count: 0,
      forgotpassword: [],
      signin: [],
      donthaveacc: [],
      signup: [],
      entercaptchvalue: [],
      enter: [],
      langID: [],
      Sorry: [],
      OTP: [],
      notVerify: [],
      or: [], otpnumber: [],
      emails: [],
      mobilenumber: [],
      privacypolicy: [], pleaseverifyotp: [], otpresentsuccess: [], otpverified: [], otpnotverified: [],
      submit: [], captchadoesnotmatch: [], resendotp: [],
      enterotp: [],
      Captchisnotmatch: [],
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
    };
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    // this.reSubmitotp= this.reSubmitotp;  
  }
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  // reSubmitotp= async event => {
  //     event.preventDefault();
  //  const data = new FormData(event.target);
  //  let Phonenumber = JSON.parse(localStorage.getItem('Phonenumber'));


  //             const url ="https://newtestapi.paropakari.com/api/Otp/Register";
  //             const options = {
  //                 method: "POST",
  // headers:{

  //     Accept: 'application/json'    

  // },
  // body: data,
  // };
  // this.setState(this.initialState)  
  // const response = await fetch(url, options);
  // //const result = await response.json();
  // if (response.ok)
  // {
  //       alert('OTP Resend successfully!')

  // }
  // this.setState(this.initialState)
  // 
  // }
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('username')
    localStorage.removeItem('user')

    localStorage.clear();
    window.location.href = "#/Login";

  }

  CheckConfirm() {
    //alert("called");

    let Phonenumber = JSON.parse(localStorage.getItem('Phonenumber'));
    let Email = JSON.parse(localStorage.getItem('Email'));


    if (Email !== null && Email !== "") {
      //alert("email")
      // 
      let url = "https://newtestapi.paropakari.com/api/Authenticate/ConfirmEmailorMobile?EmailOrMobile=" + Email;

      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;

      fetch(url, {
        method: 'GET',
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        //body:JSON.stringify(data)
      }).then(response => response.json())
        .then(data => {
          this.setState({
            GroupList: data,
            allPosts: data
          });

          // 

          // if(localStorage.setItem('EmailConfirm', JSON.stringify(data[0].emailConfirmed)) == 'undefined')
          // {
          //  localStorage.setItem('EmailConfirm' ,'false');
          //  }
          //
          localStorage.setItem('EmailConfirm', JSON.stringify(data[0].emailConfirmed));
          localStorage.setItem('PhoneConfirm', JSON.stringify(data[0].phoneNumberConfirmed));
          let EmailConfirm = JSON.parse(localStorage.getItem('EmailConfirm'));
          let PhoneConfirm = JSON.parse(localStorage.getItem('PhoneConfirm'));

          if (EmailConfirm == true || PhoneConfirm == true) {
            var page = {
              pathname: 'Login',
              pno: this.state.pno,

            }
            this.props.history.push(page)

          }
          else {
            alert(this.state.pleaseverifyotp.result);
          }
        });
    }
    if (Phonenumber !== null && Phonenumber !== "") {
      //  alert("phn")
      // 
      let url = "https://newtestapi.paropakari.com/api/Authenticate/ConfirmEmailorMobile?EmailOrMobile=" + Phonenumber;

      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;

      fetch(url, {
        method: 'GET',
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        //body:JSON.stringify(data)
      }).then(response => response.json())
        .then(data => {
          this.setState({
            GroupList: data,
            allPosts: data
          });

          localStorage.setItem('EmailConfirm', JSON.stringify(data[0].emailConfirmed));

          localStorage.setItem('PhoneConfirm', JSON.stringify(data[0].phoneNumberConfirmed));
          let EmailConfirm = JSON.parse(localStorage.getItem('EmailConfirm'));
          let PhoneConfirm = JSON.parse(localStorage.getItem('PhoneConfirm'));

          if (EmailConfirm == true || PhoneConfirm == true) {
            var page = {
              pathname: 'Login',
              pno: this.state.pno,

            }
            this.props.history.push(page)

          }
          else {
            alert(this.state.pleaseverifyotp.result);
          }
          // 
        });
    }

  }
  componentDidUpdate() {
    // Store count in localStorage whenever it changes
    localStorage.setItem('count', this.state.count.toString());
  }

  reSubmitOTP = async event => {
    event.preventDefault()

    // const code =this.state.otp
    // 

    // let otp = JSON.parse(localStorage.getItem('otp'));
    // 

    this.setState(prevState => ({
      count: prevState.count + 1

    })

    );

    let user_captcha = document.getElementById('user_captcha_input').value;
    // ++this.state.count;
    //alert("called", this.state.count);

    if (this.state.count >= 5) {

      alert(this.state.Passwordresend5timesonly.result + '!')
      //   document.getElementById("linkid").style.visibility = "hidden";
      document.getElementById("linkid").disabled = true;
      // anchor.setAttribute("class", "disabled");

      document.getElementById("linkid").style.pointerEvents = "none";
      document.getElementById("linkid").style.cursor = "default";
    }
    else {

      // let Email = JSON.parse(localStorage.getItem('Email'));
      // // alert(Email);
      // 
      // let Phonenumber = JSON.parse(localStorage.getItem('Phonenumber'));
      // if (Email != '') {
      //   let EmailOrMobile = Email;
      //   const url = "https://newtestapi.paropakari.com/api/Otp/ResendOTP?Email=" + EmailOrMobile;
      //   const options = {
      //     method: "POST",
      //     headers: {

      //       "Content-type": "application/json",
      //       "Accept": "application/json",
      //       // Authorization: "Bearer " + accessToken,
      //       "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      //     },
      //     body: "data",
      //   };
      //   // this.setState(this.initialState)  
      //   const response = await fetch(url, options);
      //   const result = await response.json();
      //   if (result.message == "OTP Resend successfully!") {
      //     alert(this.state.otpresentsuccess.result);
      //     localStorage.setItem('otp', JSON.stringify(result.otp));
      //   }
      //   // if(response.status==500)
      //   // {
      //   //   // alert("elsecalled");
      //   //     let EmailOrMobile= JSON.parse(localStorage.getItem('Phonenumber'));;
      //   //     const url ="https://newtestapi.paropakari.com/api/Otp/ResendOTPMob?Mobile=" +EmailOrMobile ;
      //   //     const options = {
      //   //         method: "POST",
      //   // headers:{

      //   // Accept: 'application/json',             
      //   // //Authorization: "Bearer " +accessToken 
      //   // },
      //   // body: "data",
      //   // };
      //   //      // this.setState(this.initialState)  
      //   //       const response = await fetch(url, options);
      //   //       const result = await response.json();
      //   //       
      //   //     if (result.message=="OTP Resend successfully!") {
      //   //       alert("OTP Resent Successfully!");
      //   //       localStorage.setItem('otp',JSON.stringify(result.otp) );

      //   //   //     var page={
      //   //   //       pathname:'Login',
      //   //   //     pno:this.state.pno,

      //   //   //   }
      //   //   //  this.props.history.push(page)
      //   //  // this.CheckConfirm();
      //   // }



      //   // }
      // }
      // if (Phonenumber != '' || Phonenumber != null) {
      //   alert("this");
      let EmailOrMobile = JSON.parse(localStorage.getItem('Phonenumber'));
      const url = "https://newtestapi.paropakari.com/api/Authenticate/LoginResendOTP?EmailOrMobile=" + EmailOrMobile;
      const options = {
        method: "POST",
        headers: {

          "Content-type": "application/json",
          "Accept": "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        body: "data",
      };
      // this.setState(this.initialState)  
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.message == "OTP Resend successfully!") {
        alert(this.state.otpresentsuccess.result);
        let myCipher = this.cipher('mySecretSalt');
        localStorage.setItem('otp', myCipher(result.otp));
        // localStorage.setItem('otp', JSON.stringify(result.otp));
      }

      //  }
    }

  }


  cipher = (salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
  };

  decipher = (salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join('');
  };

  onSubmitOTP = async event => {
    event.preventDefault()

    const code = this.state.otp
    //
    const myDecipher = this.decipher('mySecretSalt');
    var otp = localStorage.getItem('otp');
    otp = myDecipher(otp).toString();
    // let otp = JSON.parse(localStorage.getItem('otp'));
    //
    let Phonenumber = JSON.parse(localStorage.getItem('Phonenumber'));
    let Email = JSON.parse(localStorage.getItem('Email'));
    let user_captcha = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha) === true) {
      if (code == otp) {


        let Email = JSON.parse(localStorage.getItem('Email'));

        let Phonenumber = JSON.parse(localStorage.Phonenumber);

        if (Phonenumber != '') {
          //  alert("elsecalled");

          let EmailOrMobile = JSON.parse(localStorage.Phonenumber);

          const url = "https://newtestapi.paropakari.com/api/Authenticate/OTPVerify?EmailOrMobile=" + EmailOrMobile;
          const options = {
            method: "POST",
            headers: {

              "Content-type": "application/json",
              "Accept": "application/json",
              // Authorization: "Bearer " + accessToken,
              "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
            },
            body: "data",
          };
          // this.setState(this.initialState)  

          const response = await fetch(url, options);
          const result = await response.json();

          // 

          if (result.message == "OTP Verified successfully!") {
            alert(this.state.otpverified.result);
            //     var page={
            //       pathname:'Login',
            //     pno:this.state.pno,

            //   }
            //  this.props.history.push(page)
            this.CheckConfirm();
          }
        }
        else
          if (Email != '') {
            let EmailOrMobile = Email;

            const url = "https://newtestapi.paropakari.com/api/Authenticate/OTPVerify?EmailOrMobile=" + EmailOrMobile;
            const options = {
              method: "POST",
              headers: {

                "Content-type": "application/json",
                "Accept": "application/json",
                // Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
              },
              body: "data",
            };
            // this.setState(this.initialState)  
            const response = await fetch(url, options);
            const result = await response.json();
            //  
            if (result.message == "OTP Verified successfully!") {
              alert(this.state.otpverified.result);

              this.CheckConfirm();
              //     var page={
              //       pathname:'Login',
              //     pno:this.state.pno,

              //   }
              //  this.props.history.push(page)

            }
          }

        // const accessToken=user;
      }

      else {
        alert(this.state.Sorry.result + ", " + this.state.OTP.result + " " + this.state.notVerify.result);

        // window.location.reload();
      }
    }
    else {

      document.getElementById('user_captcha_input').value = ""

      alert(this.state.captchadoesnotmatch.result ? this.state.captchadoesnotmatch.result : 'Please enter valid captcha');
    }
  }



  handleSubmit = async event => {
    event.preventDefault();



    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem('user'));

    const accessToken = user;
    const url = "https://newtestapi.paropakari.com/api/Profile/OTPVerify?EmailOrMobile=True";
    const options = {
      method: "POST",
      headers: {

        "Content-type": "application/json",
        "Accept": "application/json",
        // Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      body: data,
    };
    // this.setState(this.initialState)  
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.message == "Cause created successfully!") {
      //   alert(this.state.Causecreatedsuccessfully.result?this.state.Causecreatedsuccessfully.result:'Your cause on its way to bring happiness!');
      // 

      //const prof_benID=result.prof_benID
      //window.location.reload();
      // var benename = document.getElementById("benfname3").value
      // this.handleSubmittrans(prof_benID);
    }
    else if (result.message == "Please, Enter Your UPI ID in your Profile!") {
      alert(result.message);
    }
    else {
      alert("Try to fill all field.Retry");
      // window.location.reload();   
    }
    //  this.setState(this.initialState)
    //  
  }


  Footer() {

    // 


  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;



    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });

        //  // 
      });
  }

  DefaultMenu() {
    //   

    // let l_id = JSON.parse(localStorage.getItem('menuid'));
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    //   
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~PrivacyPolicy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome~Username~Password~Forgot password~Login~Dont have an account Please&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[10] !== "undefined" ? items[10] : null;
          var Beneficiaries = typeof items[11] !== "undefined" ? items[11] : null;
          var Paropakaris = typeof items[12] !== "undefined" ? items[12] : null;
          var Amountraised = typeof items[13] !== "undefined" ? items[13] : null;

          var Welcome = typeof items[14] !== "undefined" ? items[14] : null;
          var Usernames = typeof items[15] !== "undefined" ? items[15] : null;
          var Passwords = typeof items[16] !== "undefined" ? items[16] : null;
          var ForgotPassword = typeof items[17] !== "undefined" ? items[17] : null;
          var Signin = typeof items[18] !== "undefined" ? items[18] : null;
          var DonthavAcc = typeof items[19] !== "undefined" ? items[19] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,
            langID: LangID

          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OTP verified successfully~Sorry~ OTP~ is not verified~Please enter valid captcha&lang=" + MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "

        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var OTPVerified = typeof items[0] !== "undefined" ? items[0] : null;
          var Captchadoesnotmatch = typeof items[4] !== "undefined" ? items[4] : null;
          var Sorry = typeof items[1] !== "undefined" ? items[1] : null;
          var notVerify = typeof items[3] !== "undefined" ? items[3] : null;
          var OTP = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            otpverified: OTPVerified,
            notVerify: notVerify,
            Sorry: Sorry,
            OTP: OTP,
            captchadoesnotmatch: Captchadoesnotmatch,
            langID: LangID

          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Resend OTP&lang=" + MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var ResendOTP = typeof items[0] !== "undefined" ? items[0] : null;

          // var OTPNotVerified = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            resendotp: ResendOTP,
            langID: LangID

          });


        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Or~Email~Mobile Number~Submit~Enter OTP~Please Verify Your OTP~OTP Resent Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterOTP = typeof items[7] !== "undefined" ? items[7] : null;
          var PleaseVerifyOTP = typeof items[8] !== "undefined" ? items[8] : null;
          var OTPResentSuccess = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            pleaseverifyotp: PleaseVerifyOTP,
            otpresentsuccess: OTPResentSuccess,
            enterotp: EnterOTP
          });

        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Resend OTP will only be sent 5 times~OTP Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
          var Passwordresend5timesonly = typeof items[2] !== "undefined" ? items[2] : null;
          var otpnumber = typeof items[3] !== "undefined" ? items[3] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            Passwordresend5timesonly: Passwordresend5timesonly,
            otpnumber: otpnumber,
          });
        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });

    localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome~Username~Password~Forgot password~Login~Dont have an account Please&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[10] !== "undefined" ? items[10] : null;
          var Beneficiaries = typeof items[11] !== "undefined" ? items[11] : null;
          var Paropakaris = typeof items[12] !== "undefined" ? items[12] : null;
          var Amountraised = typeof items[13] !== "undefined" ? items[13] : null;

          var Welcome = typeof items[14] !== "undefined" ? items[14] : null;
          var Usernames = typeof items[15] !== "undefined" ? items[15] : null;
          var Passwords = typeof items[16] !== "undefined" ? items[16] : null;
          var ForgotPassword = typeof items[17] !== "undefined" ? items[17] : null;
          var Signin = typeof items[18] !== "undefined" ? items[18] : null;
          var DonthavAcc = typeof items[19] !== "undefined" ? items[19] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,
            langID: LangID

          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Or~Email~Mobile Number~Submit~Enter OTP~Please Verify Your OTP~OTP Resent Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterOTP = typeof items[7] !== "undefined" ? items[7] : null;
          var PleaseVerifyOTP = typeof items[8] !== "undefined" ? items[8] : null;
          var OTPResentSuccess = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            pleaseverifyotp: PleaseVerifyOTP,
            otpresentsuccess: OTPResentSuccess,
            enterotp: EnterOTP
          });


        });
    }

    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OTP verified successfully~Sorry~OTP~is not verified~Please enter valid captcha&lang=" + MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var OTPVerified = typeof items[0] !== "undefined" ? items[0] : null;
          var Captchadoesnotmatch = typeof items[4] !== "undefined" ? items[4] : null;
          var Sorry = typeof items[1] !== "undefined" ? items[1] : null;
          var notVerify = typeof items[3] !== "undefined" ? items[3] : null;
          var OTP = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            otpverified: OTPVerified,
            notVerify: notVerify,
            Sorry: Sorry,
            OTP: OTP,
            captchadoesnotmatch: Captchadoesnotmatch,
            langID: LangID

          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Resend OTP~Resend OTP will only be sent 5 times&lang=" + MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var ResendOTP = typeof items[0] !== "undefined" ? items[0] : null;
          var Passwordresend5timesonly = typeof items[1] !== "undefined" ? items[1] : null;
          // var OTPNotVerified = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            resendotp: ResendOTP,
            Passwordresend5timesonly: Passwordresend5timesonly,
            langID: LangID

          });


        });
    }

    if (MID != null) {


      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Cancel~OTP Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;
          var otpnumber = typeof items[1] !== "undefined" ? items[1] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;






          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                    if (datatranslirate == "Spanish") {
                      datatransIDs = "/en/es";
                    }
                    else
                      if (datatranslirate == MID) {
                        datatransIDs = MID;
                      }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));


          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);







          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;



          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');

          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
            otpnumber: otpnumber,

          });


        });
    }
    if (MID != null) {
      let url =
        "https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());


    return (
      <div>{newArray}</div>
    )
  }


  componentDidMount() {
    const storedCount = localStorage.getItem('count');
    if (storedCount) {
      this.setState({ count: parseInt(storedCount) });
    }
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"
    loadCaptchaEnginge(6);
  }
  componentWillUnmount() {
    loadCaptchaEnginge(6);
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }
  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  render() {

    var Phonenumber = JSON.parse(localStorage.getItem('Phonenumber'));
    var Email = JSON.parse(localStorage.getItem('Email'));
    var OTP = localStorage.OTP;
    const { newArray, howitworks, privacypolicy, beaparopakari, whatwedo, blog, login, captchadoesnotmatch, resendotp,
      otpnotverified, otpverified, pleaseverifyotp, indiaexpensless, otpresentsuccess, ourstory, oppertunitybringsmile,
      contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, whatwedodesc, langID,
      welcome, usernames, passwords, forgotpassword, signin, donthaveacc, signup, entercaptchvalue, enter, or, emails, otpnumber,
      mobilenumber, submit, enterotp, copyright_new, paropakari_new } = this.state;
    let { foo } = this.state;
    const currentYear = new Date().getFullYear();
    return (
      <div id="myElement">
        <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>

          <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }} className="notranslate">
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content />
            <meta name="author" content />
            <title>Blog Masonry | Triangle</title>
            <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
            <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
            <link href="./assets/css/lightbox.css" rel="stylesheet" />
            <link href="./assets/css/animate.min.css" rel="stylesheet" />
            <link href="./assets/css/main.css" rel="stylesheet" />
            <link href="./assets/css/Register.css" rel="stylesheet" />
            {/*[if lt IE 9]>
              
              
          <![endif]*/}
            <link rel="shortcut icon" href="images/ico/favicon.ico" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
            <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
            {/*/head*/}

            {/* 
            <div onClick={refreshPage}> */}

            <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
               <option value="Spanish">Spanish</option>
              <option value="English">English</option>

            </select>

            {/* </div> */}

            &nbsp;&nbsp; &nbsp;&nbsp;

            <div>
              {/* <div className="mag notranslate">
                <div class="pro-nav">
                  <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                  <div class="pro-nav-header">
                    <div class="pro-nav-title notranslate">
                      <a className="navbar-brand" href="#/Index1">
                        <h1 className="text-nowrap navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                        </h1>
                      </a>
                    </div>
                  </div>

                  <div class="pro-nav-btn">
                    <label for="pro-nav-check">
                      <div className="pro-naav">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </label>
                  </div>

                  {langID == "English" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Hindi" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-hindi-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-hindi-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Kannada" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-kannada-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-kannada-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Tamil" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-tamil-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-tamil-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-tamil-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-telugu-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-telugu-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </>}
                </div>
              </div> */}

              <div className="HeaderMenualignment">
                <div className="mag notranslate">
                  <div className="pro-nav">
                    <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                    <div className="pro-nav-header">
                      <div className="pro-nav-title notranslate">
                        <a className="navbar-brand" href="#/Index1">
                          <h1 className="text-nowrap navbar-new-text">
                            <img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '1.7vh', width: '26%', fontSize: '3vh' }} />
                            AROPAKARI
                          </h1>
                        </a>
                      </div>
                    </div>

                    <div className="pro-nav-btn">
                      <label htmlFor="pro-nav-check">
                        <div className="pro-naav">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </label>
                    </div>

                    {langID === "English" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : langID === "Hindi" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-hindi-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-hindi-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                      </> : langID === "Spanish" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-kannada-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-kannada-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : langID === "Kannada" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-kannada-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-kannada-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : langID === "Tamil" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-tamil-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-tamil-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-tamil-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-telugu-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-telugu-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </>}
                  </div>
                </div>
              </div>


              <section id="blog" className={this.state.movedown == true ? "padding-top padding-bottom new-padding" : "padding-top padding-bottom new-padding home-Tiptop"} style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}>

                <div className="container " id="containerid">

                <div className="row">

                  <div id="rcont" class="rcont">

                    <div id="login" class="form sign-in" style={{ backgroundColor: '#a5d9cf' }}>

                      <form role="form" onSubmit={this.onSubmitOTP} >

                        <h2 style={{ color: 'black' }}></h2>

                        <input name="__RequestVerificationToken" type="hidden" defaultValue="PdlsOftvA33cACXYmdfrToI7ZxCMY85gro9hMBmX8g3C0ljb1Evh9ZzfgQAXal5wawUEVJy_BuahRFspEFtMa-qiBIxzmCIk15gr0b5UzpY1" />

                        <label>
                          <span>{enterotp.result}</span>
                          <input data-val="true" value={this.state.otp} onChange={this.handleChange} pattern="[0-9]*"
                            id="otp" maxLength={6} name="otp" placeholder={otpnumber.result} type="text" />
                        </label>
                        <br />
                        <center>
                          <div className="Captcha">
                            <LoadCanvasTemplate />
                          </div>

                          <div className="k-row" >
                            <input id="user_captcha_input" name="user_captcha_input" style={{ width: '50%', color: 'black', fontSize: '16px' }} type="text" placeholder={entercaptchvalue.result}></input>
                          </div>
                        </center>
                        <input name="Phonenumber" id="Phonenumber" type="hidden" value={Phonenumber} onChange={this.handleChange} />
                        <input name="Email" id="Email" type="hidden" value={Email} onChange={this.handleChange} />
                        {/* <input name="Email" id="Email" type="text" value={Email} onChange={this.handleChange} /> */}
                        <br />
                        {(() => {
                          if (Phonenumber != '') {
                            return (
                              <center><p> OTP Sent to Your Registered {Phonenumber} </p></center>
                            );
                          }
                          else if (Email != '') {
                            return (
                              <center><p> OTP Sent to Your Registered {Email} </p></center>
                            );
                          }

                        })()}

                        <center> <a href="" class="forgot-pass" id="linkid" onClick={this.reSubmitOTP}><b className="forgotpass">{resendotp.result}</b></a>
                        </center>
                        <div id="success" />
                        {(() => {
                          if (langID == "English") {
                            return (
                              <button type="submit" id="Reg" class="submit" >{submit.result}</button>
                            );
                          }
                          else if (langID == 'Telugu') {
                            return (

                              <button type="submit" id="Reg" class="submit" >{submit.result}</button>
                            );
                          }
                          else if (langID == 'Hindi') {
                            return (

                              <button type="submit" id="Reg" class="submit" >{submit.result}</button>
                            );
                          }
                          else if (langID == 'Kannada') {
                            return (

                              <button type="submit" id="Reg" class="submit" >{submit.result}</button>
                            );
                          }
                          else if (langID == 'Spanish') {
                            return (

                              <button type="submit" id="Reg" class="submit" >{submit.result}</button>
                            );
                          }

                          else {
                            return (

                              <button type="submit" id="Reg2" class="submit" >{submit.result}</button>
                            );
                          }

                        })()

                        }
                        {/* <button type="submit" id="Reg"  class="submit">{submit.result}</button> */}
                      </form>
                    </div>

                    <div id="rsub-cont" class="sub-rcont">

                      <div id="rimgsub-cont" class="img">

                        <div class="img__text m--up">

                          <h3></h3>
                        </div>
                        <div class="img__text m--in">


                        </div>
                        <div class="tnr" id="tnr">


                          <span class="m--in" ></span>
                        </div>
                      </div>


                      <div class="form sign-up">
                        <h2></h2>
                        <label>
                          <span></span>
                          <input type="text" />
                        </label>
                        <label>
                          <span></span>
                          <input type="email" />
                        </label>
                        <label>
                          <span></span>
                          <input type="password" />
                        </label>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <div style={{ backgroundColor: '#f2dba8' }}>
                <footer id="footer" style={{ color: 'white' }}>
                  <br />
                  <div className="container">
                    <div className="copyright">

                      <div className="row col-lg-12">

                        <div className="col-lg-9 mb-6">
                          {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                          <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result}&nbsp; |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                        </div>
                        <div className="col-lg-3 mb-3">

                          <div className="social-icons" style={{ color: 'white' }}>
                            <ul className="nav nav-pills">
                              <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                              {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                              <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                              <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                              <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="containerss">

                  </div>
                </footer>
              </div>
            </div>
            {/*/#footer*/}
          </div>
        </div>
      </div>
    )
  }
}