

import React, { useState, useEffect, useReducer } from 'react'
import $ from "jquery";
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Cookies from 'js-cookie';
import axios from 'axios';
import { data } from 'browserslist';
import { BASE_URLs } from '../Constants/BaseURL';
import { getDefaultNormalizer } from '@testing-library/react';


export default function PayoutList() {
  const [Footer, setFooter] = useState([]);
  const [Menu, setMenu] = useState([]);
  const [Details, setDetails] = useState([]);
  const [newArray, setnewArray] = useState([]);
  const [ourstory, setourstory] = useState([]);
  const [howitworks, sethowitworks] = useState([]);
  const [beaparopakari, setbeaparopakari] = useState([]);
  const [whatwedo, setwhatwedo] = useState([]);
  const [blog, setblog] = useState([]);
  const [login, setlogin] = useState([]);
  const [indiaexpensless, setindiaexpensless] = useState([]);
  const [oppertunitybringsmile, setoppertunitybringsmile] = useState([]);
  const [contactus, setcontactus] = useState([]);
  const [termsandcondition, settermsandcondition] = useState([]);
  const [causes, setcauses] = useState([]);
  const [beneficiaries, setbeneficiaries] = useState([]);
  const [paropakaris, setparopakaris] = useState([]);
  const [amountraised, setamountraised] = useState([]);
  const [copyright, setcopyright] = useState([]);
  const [whatwedodesc, setwhatwedodesc] = useState([]);
  const [crowdfunding, setcrowdfunding] = useState([]);
  const [humanelement, sethumanelement] = useState([]);
  const [nofeecommision, setnofeecommision] = useState([]);
  const [nomiddlemen, setnomiddlemen] = useState([]);
  const [encourageaultruism, setencourageaultruism] = useState([]);
  const [comingsoon, setcomingsoon] = useState([]);
  const [privacypolicy, setprivacypolicy] = useState([]);
  const [langID, setlangID] = useState([]);
  const [option, setOption] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  // const hanldeClicked = (selectedRec) => {
  //   setSelectedData(selectedRec);
  //   setShow(true);
  // };

  // const hideModal = () => {
  //   setShow(false);
  // };


  const data = [
    {
      Paymentid: "pay_VADE0B248932",
      Razorpayid: "order_VADE0B248932",
      Amount: 200,
      Email: "Example@gmail.com",
      Contact: "9087654322",
      Created: "06 JUN 2022 04:22 PM",
      status: <button class="btn btn-success btn-round-1" style={{ borderRadius: "20px" }}>Success</button>,
    },
    {
      Paymentid: "pay_VADE0B248932",
      Razorpayid: "Mark",
      Amount: "Otto",
      Email: 34,
      Contact: "London",
      Created: "10 JAN 2022 04:52 PM",
      status: <button class="btn btn-danger btn-round-1" style={{ borderRadius: "20px" }}>Pending</button>,
    },

    {
      Paymentid: "pay_VADE0B248932",
      Razorpayid: "fff",
      Amount: "ffff",
      Email: 34,
      Contact: "London",
      Created: "10 FEB 2022 04:32 PM",
      status: <button class="btn btn-danger btn-round-1" style={{ borderRadius: "20px" }}>Pending</button>,
    },


  ];
  const baseURL = "https://newtestapi.paropakari.com/api/Authenticate/Get_FooterDetails";
  function reloadPage() {

    window.location.reload();

  }
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    //  loadData();
    // let Admin_status = JSON.parse(localStorage.getItem('Admin_status'));
    // alert("Hi",Admin_status);
    // 
    //   if(Admin_status == 1)
    //   {

    // alert("called");

    loadClaimData();
    //}
    // else{
    //   alert("Sorry, Unauthorized User1111");
    // }
  }, []);
  // const loadData= async()=>{
  //   let user = JSON.parse(localStorage.getItem('user'));
  //   const accessToken = user;
  //   await fetch('https://newtestapi.paropakari.com/api/GetProfile/GetAdminstatus',{ headers: {"Authorization" : `Bearer ${accessToken}`} })
  //   .then(response=>response.json())
  //   .then(receivedData=>setDatas(receivedData));
  //   
  //   localStorage.setItem('Admin_status', JSON.stringify(datas[0].admin_status));
  //   }



  const [claimdatas, setclaimDatas] = useState([]);

  const loadClaimData = async () => {
    // alert("Claim data is called");
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    await fetch('https://newtestapi.paropakari.com/api/Payout/GetClaimPayoutInfo', { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then(response => response.json())
      .then(receivedData => setclaimDatas(receivedData));
    
    //localStorage.setItem('Admin_status', JSON.stringify(datas[0].admin_status));
  }

  function handleChange(event) {
    setOption(event.target.value);

    

    
    localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    reloadPage();
    let MID = event.target.value;
    if (MID == "Kannada") {

      MID = "kn";
    }
    else
      if (MID == "Tamil") {
        MID = "ta";
      }
      else
        if (MID == "Telugu") {
          MID = "te";
        }
        else
          if (MID == "Hindi") {
            MID = "hi";
          }
          else
          if (MID == "Spanish") {
            MID = "es";
          }
          else
            if (MID == event.target.value) {
              MID = event.target.value;
            }

  }
  var MID = localStorage.getItem('menuid');
  useEffect(() => {


    var MID = localStorage.getItem('menuid', 'English');


    axios.get("https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~We are Indias only expenseless direct crowdfunding and support platform that connects the needy with the more fortunate directly without third party involvement or fees~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari&lang=" + MID).then((response) => {
      setOption(response.data);

      



      var items = response.data;
      var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
      var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
      var Blog = typeof items[2] !== "undefined" ? items[2] : null;
      var Login = typeof items[3] !== "undefined" ? items[3] : null;
      var Contactus = typeof items[8] !== "undefined" ? items[8] : null;
      var Termsandcondition = typeof items[9] !== "undefined" ? items[9] : null;
      var CopyRight = typeof items[10] !== "undefined" ? items[10] : null;

      var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
      var OppertunitytoBSmile = typeof items[7] !== "undefined" ? items[7] : null;
      var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

      var Whatwedodesc = typeof items[6] !== "undefined" ? items[6] : null;

      var LangID = MID;
      window.sessionStorage.setItem('translate', (LangID));
      setourstory(Ourstory);
      sethowitworks(Howitwork);
      setblog(Blog);
      setlogin(Login);
      setcontactus(Contactus);
      settermsandcondition(Termsandcondition);
      setcopyright(CopyRight);
      setbeaparopakari(Beaparopakari);
      setoppertunitybringsmile(OppertunitytoBSmile);

      setlangID(LangID)
    });


  }, []);
  useEffect(() => {


    var MID = localStorage.getItem('menuid');


    axios.get("https://newtestapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~EncourageAltruism~Crowd funding with:~A Human Element~No Fees/Commission~No Middlemen~Coming Soon~Privacy Policy&lang=" + MID).then((response) => {
      setOption(response.data);

      
      var items = response.data;

      var Causes = typeof items[0] !== "undefined" ? items[0] : null;
      var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
      var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
      var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

      var Crowdfunding = typeof items[5] !== "undefined" ? items[5] : null;
      var HumanElement = typeof items[6] !== "undefined" ? items[6] : null;
      var NoFeeCommision = typeof items[7] !== "undefined" ? items[7] : null;
      var NoMiddlemen = typeof items[8] !== "undefined" ? items[8] : null;
      var CominSoon = typeof items[9] !== "undefined" ? items[9] : null;
      var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
      var EncourageAltruism = typeof items[4] !== "undefined" ? items[4] : null;
      var LangID = MID;
      setprivacypolicy(PrivacyPolicy);
      setcomingsoon(CominSoon);


    });


  }, []);
  useEffect(() => {

    axios.get(baseURL).then((response) => {
      setFooter(response.data);

    });

    
  }, []);
  //     const Modal = ({ handleClose, details }) => {
  //       return (
  //         <div className="modal display-block">
  //           <section className="modal-main">
  //             <div className="App">


  //               	<div class="modal-header">
  //                 <a class="closes" href="#PayoutList"  onClick={handleClose}>×</a>
  // 					<h4 class="modal-title" id="myModalLabel2"><strong>Payment ID:</strong></h4>
  // 				</div>
  //         <div class="row" style={{marginTop:"20px"}}>
  //   <div class="col-md-6" style={{marginLeft:"30px"}} >
  //   Razorpay ID
  //   </div>

  //   <div class="col-md-6">
  //   {details?.Paymentid }

  //   </div>
  // </div>
  //             </div>

  //             {/* <button onclick="window.print()">Print</button> */}
  //           </section>
  //         </div>
  //       )}
  return (
    <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
      <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
        <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
        <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
        <link href="./assets/css/lightbox.css" rel="stylesheet" />
        <link href="./assets/css/animate.min.css" rel="stylesheet" />
        <link href="./assets/css/main.css" rel="stylesheet" />


        <link rel="shortcut icon" href="images/ico/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />


        <link rel="shortcut icon" href="images/ico/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
        <select className="form-control" value={option} onChange={handleChange} style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} >
          <option value="English">Languages</option>

          <option value="Kannada">Kannada</option>
          <option value="Tamil">Tamil</option>
          <option value="Hindi">Hindi</option>
          <option value="Telugu">Telugu</option>
           <option value="Spanish">Spanish</option>
          <option value="English">English</option>



        </select>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <div>
          <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
            <div class="navbar navbar-inverse" role="banner">
              <div class="container">
                <div class="navbar-header">
                  <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span class="sr-only">Toggle navigassstion</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                  <a class="navbar-brand" href="#/Index1">
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                    </h1>
                  </a>
                </div>
                <div class="collapse navbar-collapse">
                  <ul class="nav navbar-nav navbar-right">
                    {/* <li id="paro" className="active">
           {(() => {if (langID == 'Tamil' ) {return (
                      <a class="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'15px',marginLeft:'-195px',fontSize:10,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
 {beaparopakari.result}
</a>

);
}
else {return(

    <a class="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

    {beaparopakari.result}
</a>

);}

})()} 
</li>
<li>
                          {(() => {if (langID == 'Tamil' ) {return (
  <a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
                 else {return(

                  <a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 
                          
                            
                            </li> 
                            <li>
                      {(() => {if (langID == 'Tamil' ) {return (
  <a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}
                        
  </a>

);
}
                 else {return(

                  <a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}
                        
                        </a>

);}

})()} 
                        </li> 
                        <li className="active">
                      {(() => {if (langID == 'Tamil' ) {return (
    <a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{blog.result}</a>

);
}
                 else {return(

                  <a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{blog.result}</a>

);}

})()} 
                      
                        
                        </li>         
                                          */}
                    <li>
                      {(() => {
                        if (langID == 'Tamil') {
                          return (
                            <a href="#/AdmLognParopakari" style={{ fontFamily: 'cuyabra', fontWeight: 'bold', fontsize: 10 }}>Logout</a>

                          );
                        }
                        else {
                          return (

                            <a href="#/AdmLognParopakari" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Logout</a>

                          );
                        }

                      })()}



                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </header>
          {/* Grid table */}
          <section id="services" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>









            <div className="App" style={{ overflowX: "auto" }} >

              {/* <table class="table" style={{border:"1px solid black",width:"95%",marginLeft:"30px"}}>
  
  <thead style={{backgroundColor:"#3333FF",color:"white"}}>
  <tr>
      <th scope="col">Cause ID</th>
      <th scope="col">Account No</th>
      <th scope="col">IFSC</th>
      <th scope="col">Amount</th>
      <th scope="col">Email</th>
      <th scope="col">Contact</th>
      <th scope="col">Created</th>
      <th scope="col">Status</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {claimdatas.map((v) => (
      <tr  >
      
       
        <td style={{marginLeft:"100px"}}>{v.upload_id}</td>
        <td>{v.benef_AccountNumber}</td>
        <td>{v.benef_IFSC}</td>
        <td>{v.claim_amount}</td>
        <td>{v.benef_Email}</td>
        <td>{v.benef_MobileNumber}</td>
        <td>{v.claim_date}</td>
        <td>{v.payout_status}

        </td>
        <td><a href={"#/PayoutDetail/"+v.upload_id}>Edit</a>

</td>
      </tr>
    ))}
  </tbody>




</table> */}
              <table id="table" class=" table " style={{ width: "90%", marginLeft: "75px", }}>
                {/* <tr class="header-row">
            <th class="header-item items">CODE</th>
            <th class="header-item items">COMPANY NAME</th>
            <th class="header-item items">PRICE</th>
            <th class="header-item items">DISCOUNT</th>
            <th class="header-item items">FINAL PRICE</th>
        </tr> */}
                <thead style={{ background: "linear-gradient(to bottom, #33ccff 0%, #9900ff 100%)", color: "white" }}>
                  <tr class="header-row">
                    <th scope="col" class="header-item items">Cause ID</th>
                    <th scope="col" class="header-item items">Account No</th>
                    <th scope="col" class="header-item items">IFSC</th>
                    <th scope="col" class="header-item items">Amount<img src="https://batafestivepromo.bigcityexperience.com/assets/frontend/img/g_coin.png" class="coin"></img></th>
                    <th scope="col" class="header-item items">Email</th>
                    <th scope="col" class="header-item items">Contact</th>
                    <th scope="col" class="header-item items">Created</th>
                    <th scope="col" class="header-item items">Status</th>
                    <th scope="col" class="header-item items">Action</th>
                    <tr style={{ height: "1rem", border: "none", boxShadow: "none" }}></tr>
                  </tr>
                </thead>
                <tbody>
                  {claimdatas.map((v) => (
                    <tr class="table-rows"  >


                      <td class="items" style={{ marginLeft: "100px" }}>{v.upload_id}</td>
                      <td class="items">{v.benef_AccountNumber}</td>
                      <td class="items">{v.benef_IFSC}</td>
                      <td class="items">{v.claim_amount}</td>
                      <td class="items">{v.benef_Email}</td>
                      <td class="items">{v.benef_MobileNumber}</td>
                      <td class="items">{v.claim_date}</td>

                      <td class="items">
                        {(() => {
                          if (v.payout_status === 'processed') {
                            return (
                              <b style={{ color: '#6eff6e' }}>
                                {v.payout_status}
                              </b>

                            );
                          }
                          else {
                            return (
                              <b style={{ color: 'red' }}>
                                {v.payout_status}
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </b>


                            );
                          }

                        })()}




                      </td>
                      <td class="items"><a href={"#/PayoutDetail/" + v.upload_id}>Edit</a>

                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* <tr class="table-rows">
            <td class="items">XXA</td>
            <td class="items">AUSENCO</td>
            <td class="items icon">
                $145.29
               
            </td>
            <td class="items">7%</td>
            <td class="items">
                $135.12
                
            </td>
        </tr> */}

              </table>
              {/* 
{show && <Modal details={selectedData} handleClose={hideModal} />} */}
            </div>





          </section>
          <footer id="footer" style={{ color: 'white' }}>
            <br />
            <div className="container">
              <div className="copyright">
                <div className="row col-lg-12">
                  <div className="col-lg-9 mb-6">
                    <p style={{ marginLeft: '45px' }}>{copyright.result}&nbsp; |&nbsp;<a href="#/Contactuslogin" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicyLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandConditionLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="social-icons" style={{ color: 'white', marginLeft: '72px' }}>
                      <ul className="nav nav-pills">
                        <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>

                        <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>

  )
}



